import {BasePopup} from "./_index";


interface Props {
    onClick: any,
    image: any,
}

export default (props: Props) => {
    return (

        <BasePopup visible={props.visible} direction={props.direction}>
            <div className="fullScreenPopup" onClick={props.onClick}>
                <img src={props.image} alt=""/>
            </div>
        </BasePopup>

    )

}
