import React, {useEffect, useState} from "react";
import plum from "../../assets/sounds/effects/plum.mp3";
import {useTranslation} from "../../misc/Hooks";

interface Props {
    img: any,
    imgHover: any,
    elProps: any,
    onClick?: void,
    transformBox: ?any,

    textContainer_x: any,
    textContainer_y: any,
    text1_trans: any,
    text2_trans: any,
    text3_trans: any,
    text4_trans: any,
    text5_trans: any,

    classText: any,

}


export default (props: Props) => {
    const t = useTranslation();
    const [hovered, setHovered] = useState(false);
    const [showBox, setShowBox] = useState(false)


    return (


        <>
            {!showBox ? (
                <image
                    {...props.elProps}
                    xlinkHref={hovered ? props.img : props.imgHover}
                    // onClick={typeof props.onClick === 'function' ? props.onClick : undefined}
                    onClick={() => setShowBox(true)}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                />
            ) : (
                <>
                    <image
                        width="328"
                        height="318"
                        transform={props.transformBox}
                        xlinkHref={require('../../assets/img/popup/iWasHere/button-on.png').default}
                    />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 318 243`}
                         x={props.textContainer_x} y={props.textContainer_y} width="318" height="295">
                        <text x="51.5%" y="20%" className={`iWasHereTextBox ${props.classText}`}
                              dominantBaseline="middle" textAnchor="middle">
                            {props.text1_trans}
                        </text>
                        <text x="51.5%" y="32%" className={`iWasHereTextBox ${props.classText}`}
                              dominantBaseline="middle" textAnchor="middle">
                            {props.text2_trans}
                        </text>
                        <text x="51.5%" y="44%" className={`iWasHereTextBox ${props.classText}`}
                              dominantBaseline="middle" textAnchor="middle">
                            {props.text3_trans}
                        </text>
                        <text x="51.5%" y="56%" className={`iWasHereTextBox ${props.classText}`}
                              dominantBaseline="middle" textAnchor="middle">
                            {props.text4_trans}
                        </text>
                        <text x="51.5%" y="68%" className={`iWasHereTextBox ${props.classText}`}
                              dominantBaseline="middle" textAnchor="middle">
                            {props.text5_trans}
                        </text>
                        <text x="23%" y="82%" className="iWasHereTextBox -button" dominantBaseline="middle" textAnchor="middle" onClick={props.onClick}>
                            {t('see')}
                        </text>
                        <text x="23%" y="95%" className="iWasHereTextBox -button" dominantBaseline="bottom" textAnchor="middle" onClick={props.onClick}>
                            {t('video')}
                        </text>
                        <image
                            width="95"
                            height="103"
                            transform="matrix(1 0 0 1 110 175)"
                            xlinkHref={require('../../assets/img/popup/iWasHere/arrow.png').default}
                            onClick={props.onClick}
                        />
                        <image
                            width="38"
                            height="36"
                            transform="matrix(1 0 0 1 269 3)"
                            xlinkHref={require('../../assets/img/popup/iWasHere/x.png').default}
                            onClick={() => setShowBox(false)}

                        />
                    </svg>




                </>
            )}


        </>


    )
}
