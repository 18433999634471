

export default {
    NAME: 'ComicHousingEstateMonsun',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_03.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_04.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_05.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_06.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_07.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_08.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_09.jpg',
            fade: true,
            fadeOnlyEnd: true,
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_09a.png',
            fade: true,
            blockNext: true,
            nextAfterTime: 1000,
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_10.jpg',
            fade: true,
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_11.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_12.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_13.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_14.jpg',
            fade: true,
            fadeOnlyEnd: true,
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_14a.png',
            fade: true,
            blockNext: true,
            nextAfterTime: 1000,
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_15.jpg',
            fade: true,
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_16.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_17.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_18.jpg',
        },





    ],
    COMIC_SOUNDS_LIST: [

        {
            id: 1,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_WindyNight_02.mp3').default,
            from: 11,
            to: 12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_WindyNight_02.mp3').default,
            from: 12,
            to: 13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_WindyNight_02.mp3').default,
            from: 13,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_WindyNight_02.mp3').default,
            from: 14,
            to: 16,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_WindyNight_02.mp3').default,
            from: 16,
            to: 17,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_AbandonedHouse_01.mp3').default,
            from: 17,
            to: 20,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_Cicadas_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_DesertRuralDay_05.mp3').default,
            from: 10,
            to: 11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_Heartbeats_01.mp3').default,
            from: 16,
            to: 17,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_Heartbeats_02.mp3').default,
            from: 17,
            to: 18,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_Heartbeats_02.mp3').default,
            from: 18,
            to: 19,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_RainOut_01.mp3').default,
            from: 5,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_RainyDay_01.mp3').default,
            from: 0,
            to: 1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_RainyDay_03.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_RainyDay_03.mp3').default,
            from:7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_RainyDay_03.mp3').default,
            from:8,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_RainyDayinBarrack_01.mp3').default,
            from:3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_Rustle_01.mp3').default,
            from:10,
            to: 11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 19,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_WindDesert_01.mp3').default,
            from:12,
            to: 13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 20,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtB_Raindrops_01.mp3').default,
            from:0,
            to: 1,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 21,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtB_Raindrops_02.mp3').default,
            from:2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtB_RuralDay_03.mp3').default,
            from:1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtB_Waterstream_01.mp3').default,
            from:3,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 24,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtB_WaterStream_02.mp3').default,
            from:4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 25,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtB_WindyHome_01.mp3').default,
            from:5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 26,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtB_WindyHome_01.mp3').default,
            from:6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 27,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtB_WoodenCreaks_01.mp3').default,
            from:3,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 28,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtB_WoodenCreaks_01.mp3').default,
            from:5,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 29,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_Cards_01.mp3').default,
            from:4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 30,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_Drips_01.mp3').default,
            from: 3,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 31,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_Drips_02.mp3').default,
            from:4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 32,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_Drips_03.mp3').default,
            from:5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 33,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_Drips_04.mp3').default,
            from:6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 34,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_Footsteps_01.mp3').default,
            from:0,
            to: 1,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 35,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_Footsteps_02.mp3').default,
            from:2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 36,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_Footsteps_04.mp3').default,
            from:7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 37,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_Footsteps_05.mp3').default,
            from:8,
            to: 10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 38,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_Footsteps_06.mp3').default,
            from:10,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 39,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_GunImpact_01.mp3').default,
            from:13,
            to: 14,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 40,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_GunLoad_01.mp3').default,
            from:12,
            to: 13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 41,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_HospitalAmbience_01.mp3').default,
            from:17,
            to: 18,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 42,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_HospitalAmbience_02.mp3').default,
            from:18,
            to: 19,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 43,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_HospitalAmbience_03.mp3').default,
            from:19,
            to: 20,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
      /*  {
            id: 44,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_ScreamsPulse_01.mp3').default,
            from:12,
            to: 14,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
        {
            id: 45,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_FxA_Step_01.mp3').default,
            from:11,
            to: 12,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 46,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_Mx_01_ZlowieszczyAkcent.mp3').default,
            from:10,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 47,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_Mx_02_NarastajaceNapiecie.mp3').default,
            from:12,
            to: 13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 48,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_Mx_03_ItAllRoght.mp3').default,
            from:17,
            to: 20,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 49,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_VO_Breathing_01.mp3').default,
            from:2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 50,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_VO_Coughing_01.mp3').default,
            from:6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 51,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_06_Monsun_FxA_Wolves_01.mp3').default,
            from:10,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 52,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_02_Monsun_AtA_CardsAndDrips_01.mp3').default,
            from: 4,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 52,
            file: require('../assets/sounds/comics/housing-estate/monsun/TBB_Szlak_Monsun_FxA_HawkScream_01.mp3').default,
            from: 14,
            to: 15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
    ],
}
