import React, {useEffect, useMemo, useRef, useState} from "react";
import {Preloader, PrimaryVideo, SvgCircle} from "../misc/_index";
import {FullScreenPopup, PrimaryPopup, WebsiteMapPopup} from "../popup/_index";
import {useLocaleAsset, useNavigation, useTranslation} from "../../misc/Hooks";
import {useHistory, useRouteMatch} from "react-router-dom";
import Routes from "../../utils/Routes";
import {ComicBase} from "../comics/_index";
import ComicDeportationTrain from "../../utils/ComicDeportationTrain";
import {useDispatch, useSelector} from "react-redux";
import MapList from "../../utils/MapList";
import * as reducer from "../../store/reducer";
import plum from "../../assets/sounds/effects/plum.mp3"
import deportation from "../../assets/video/deportacja.mp4"
import deportationSound from "../../assets/sounds/maps/TBB_Mapy_01_Deportacja_01.mp3"
import balachadiSound from "../../assets/sounds/maps/TBB_Mapy_06_Balchadi_01.mp3";
import MuteButton from "../misc/MuteButton";

let imgs = null;
let svgsImages = null;

export const DeportationMap = (props) => {
    const history = useHistory();
    const t = useTranslation();
    const navigation = useNavigation();
    const localeAsset = useLocaleAsset();

    const dispatch = useDispatch();
    const store = useSelector(state=>({
        newestMapIndex: state.newestMapIndex,
        soundVolume: state.soundVolume,
        lastVisitedMap: state.lastVisitedMap
    }))


    const [allImages, setAllImages] = useState(0);
    const [isLoad, setIsLoad] = useState(0);

    const match = useRouteMatch();

    const [preloader, setPreloader] = useState(false);
    const [preloaderHide, setPreloaderHide] = useState(true);
    const [showWelcome, setShowWelcome] = useState(false);
    const [showArchive, setShowArchive] = useState(false);
    const [showPoland, setShowPoland] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [comicTrain, setComicTrain] = useState(false);
    const [starShow, setStarShow] = useState(false)

    const [showInstruction, setShowInstruction] = useState(true)
    const [showExitInstruction, setShowExitInstruction] = useState(false)
    const [showMap, setShowMap] = useState(false)
    const [showWebsiteMap, setShowWebsiteMap] = useState(false);
    const [showVideoDeportation, setShowVideoDeportation] = useState(true)

    const soundPlum = useRef(new Audio(plum))
    const soundMap = useRef( new Audio(deportationSound))

    useMemo(() => {
       soundPlum.current.volume = (store.soundVolume/2)
    }, []);
    useEffect(() => {
        const idx = MapList.findIndex(i => i.route === match.path);
        if(idx !== 0 && idx > store.newestMapIndex) {
            dispatch(reducer.setNewestMapIndex(idx));
        }
        return () => {
            if (soundMap.current) {
                soundMap.current.pause(); 
                soundMap.current.currentTime = 0;
            }
          };

    }, [])


    useEffect(()=> {
        soundMap.current.volume = store.soundVolume
       soundPlum.current.volume = (store.soundVolume / 2)
    }, [store.soundVolume])



    useEffect(() => {
        if (showWelcome) {
            setPreloaderHide(false)
            setPreloader(true)

            imgs = [...document.querySelectorAll('img')];
            svgsImages = [...document.querySelectorAll('image')];

            setAllImages(imgs.length + svgsImages.length - 2)


            imgs.map((img)=> {
                img.addEventListener('load', handleOnLoad )
            })
            svgsImages.map((svgImage)=> {
                svgImage.addEventListener('load', handleOnLoad )
            })

            // setTimeout(() => {
            //     setPreloaderHide(true)
            // }, 2200)
            // setTimeout(() => {
            //     setPreloader(false)
            // }, 2500)
        }
    }, [showWelcome])

    let helperIsLoad = 0;
    const handleOnLoad = () => {
        ++helperIsLoad;
        setIsLoad(helperIsLoad);
        if((imgs.length + svgsImages.length - 2) === helperIsLoad){
            setTimeout(()=> {
                setPreloaderHide(true)
            },2000)
            setTimeout(()=> {
                setPreloader(false)
            }, 2300)
        }

    }



    useEffect(() => {
        setShowPoland(false)
        setShowInfo(false)
    }, [showArchive])

    useEffect(() => {
        setShowArchive(false)
        setShowInfo(false)
    }, [showPoland])

    useEffect(() => {
        setShowArchive(false)
        setShowPoland(false)
    }, [showInfo])

    const HideWelcome = () => {
        setShowWelcome(false)

        setTimeout(() => {
           soundPlum.current.play()
            setStarShow(true)
        }, 800)
    }

    const endVideo = () => {
        setShowVideoDeportation(false)
        setShowWelcome(true);

        setTimeout(() => {
            setShowMap(true);
            musicMapStart()

        }, 300)

    }

    const handleNextMap = () => {
        setShowMap(false)
        musicMapStop();
        setTimeout(() => {
            history.push(navigation(Routes.MAP_BIERIEZNIAKI));
        }, 500)
    }

    const handleCloseInstruction = () => {
        setShowExitInstruction(true)
        setTimeout(() => {
            setShowInstruction(false)
        }, 500)
    }

    const musicMapStart = () => {
        soundMap.current.volume = 0;
        soundMap.current.play();
        soundMap.current.loop = true;
        setTimeout(() => {
            if(store.soundVolume >= .1){
                soundMap.current.volume = .1;
            }
        }, 200)
        setTimeout(() => {
            if(store.soundVolume >= .3){
                soundMap.current.volume = .3;
            }
        }, 400)
        setTimeout(() => {
            if(store.soundVolume >= .5){
                soundMap.current.volume = .5;
            }
        }, 600)
        setTimeout(() => {
            if(store.soundVolume >= .8){
                soundMap.current.volume = .8;
            }
        }, 800)
        setTimeout(() => {
            if(store.soundVolume >= 1){
                soundMap.current.volume = 1;
            }
        }, 1000)
    }

    const musicMapStop = () => {
        setTimeout(() => {
            if(store.soundVolume >= .8){
                soundMap.current.volume = .8;
            }
        }, 200)
        setTimeout(() => {
            if(store.soundVolume >= .6){
                soundMap.current.volume = .6;
            }
        }, 400)
        setTimeout(() => {
            if(store.soundVolume >= .4){
                soundMap.current.volume = .4;
            }
        }, 600)
        setTimeout(() => {
            if(store.soundVolume >= .2){
                soundMap.current.volume = .2;
            }
        }, 800)
        setTimeout(() => {
            if(store.soundVolume >= .1){
                soundMap.current.volume = .1;
            }
            soundMap.current.pause();
        }, 1000)
    }


    const handleOpenComic = () => {
        musicMapStop()
        setTimeout(() => {
            setComicTrain(true)
        }, 1000)
    }

    let mapClassName = ''
    if (showMap) {
        mapClassName += ' -active'
    }

    let instructionClassName = ''
    if (showExitInstruction) {
        instructionClassName += ' -exit'
    }

    return (

        <>
            {!!preloader && (
                <Preloader
                    hide={preloaderHide}
                    countAll={allImages}
                    countOnLoad={isLoad}
                />
            )}

            {!!showVideoDeportation ? (
                <PrimaryVideo
                    video={localeAsset('video/deportacja.mp4')}
                    onEnded={endVideo}
                    onSkip={endVideo}
                />
            ) : (


                <div className="content">

                    <div className={`mapWrapper ${mapClassName}`}>


                        <div className="mapImg">
                            <img src={localeAsset('img/maps/mapa_deportacja_stage1.jpg')} alt=""/>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" className="mapSvg">
                            <defs>
                            </defs>

                            <MuteButton
                                // handleOnSoundOn={musicMapStart}
                                elProps={{}}
                            />
                            <SvgCircle
                                elProps={{
                                    width: "66",
                                    height: "66",
                                    transform: "matrix(1 0 0 1 0 0)",
                                }}
                                img={require('../../assets/img/buttons/map_www_on.png').default}
                                imgHover={require('../../assets/img/buttons/map_www_off.png').default}
                                onClick={() => setShowWebsiteMap(true)}

                            />


                            <SvgCircle
                                elProps={{
                                    width: "126",
                                    height: "116",
                                    transform: "matrix(1 0 0 1 20 955)",

                                }}
                                img={require('../../assets/img/buttons/info_on.png').default}
                                imgHover={require('../../assets/img/buttons/info_off.png').default}
                                onClick={() => setShowInfo(true)}
                            />

                            {/*<SvgCircle*/}
                            {/*    elProps={{*/}
                            {/*        width: "142",*/}
                            {/*        height: "116",*/}
                            {/*        transform: "matrix(1 0 0 1 150 955)",*/}
                            {/*    }}*/}
                            {/*    img={require('../../assets/img/buttons/archiw_on.png').default}*/}
                            {/*    imgHover={require('../../assets/img/buttons/archiw_off.png').default}*/}
                            {/*    onClick={() => setShowArchive(true)}*/}
                            {/*/>*/}
                            <SvgCircle
                                elProps={{
                                    width: "136",
                                    height: "136",
                                    transform: "matrix(1 0 0 1 1780 0)",

                                }}
                                img={require('../../assets/img/buttons/forward_on.png').default}
                                imgHover={require('../../assets/img/buttons/forward_off.png').default}
                                onClick={() => handleNextMap()
                                }
                            />


                            <SvgCircle
                                elProps={{
                                    width: "90",
                                    height: "84",
                                    transform: "matrix(1 0 0 1 343 409.0323)",
                                }}
                                img={require('../../assets/img/buttons/star_yellow_info_on.png').default}
                                imgHover={require('../../assets/img/buttons/star_yellow_info_off.png').default}
                                onClick={() => setShowPoland(true)}
                            />


                            {!!starShow && (
                                <SvgCircle
                                    elProps={{
                                        width: "152",
                                        height: "134",
                                        className: `starDeportationOne ${!showWelcome ? '-active' : ''}`
                                    }}
                                    img={require('../../assets/img/buttons/star_1_off.png').default}
                                    imgHover={require('../../assets/img/buttons/star_1_on.png').default}
                                    imgVisited={require('../../assets/img/buttons/star_1_visited.png').default}
                                    nameComic={'ComicDeportationTrain'}
                                    onClick={handleOpenComic}

                                />
                            )}
                            {/*{!!showInstruction && (*/}
                            {/*    <>*/}
                            {/*        <SvgCircle*/}
                            {/*            elProps={{*/}
                            {/*                width: "1920",*/}
                            {/*                height: "1080",*/}
                            {/*                className: `instruction ${instructionClassName}`*/}

                            {/*            }}*/}
                            {/*            img={localeAsset('img/instruction/instruction.png')}*/}
                            {/*            imgHover={localeAsset('img/instruction/instruction.png')}*/}
                            {/*            onClick={handleCloseInstruction}*/}
                            {/*        />*/}

                            {/*        <SvgCircle*/}
                            {/*            elProps={{*/}
                            {/*                width: "271",*/}
                            {/*                height: "55",*/}
                            {/*                transform: "matrix(1 0 0 1 1630 140)",*/}
                            {/*                className: `instruction -button ${instructionClassName}`,*/}


                            {/*            }}*/}
                            {/*            img={localeAsset('img/buttons/exitInstruction.png')}*/}
                            {/*            imgHover={localeAsset('img/buttons/exitInstruction.png')}*/}
                            {/*            onClick={handleCloseInstruction}*/}


                            {/*        />*/}
                            {/*    </>*/}
                            {/*)}*/}
                        </svg>


                        <PrimaryPopup
                            onClick={HideWelcome}
                            visible={showWelcome}
                            className='-deportation'
                            img={localeAsset('img/popup/deportation/deportacja_wstep.png')}
                        >
                            {/*<p dangerouslySetInnerHTML={{__html: t('textWelcomeDeportation')}}/>*/}
                        </PrimaryPopup>

                        <PrimaryPopup
                            onClick={() => setShowInfo(false)}
                            visible={showInfo}
                            className='-deportation'
                            img={localeAsset('img/popup/deportation/deportacja_info.png')}
                        >
                            <p dangerouslySetInnerHTML={{__html: t('textInfoDeportation')}}/>
                        </PrimaryPopup>

                        <FullScreenPopup
                            image={require('../../assets/img/popup/deportation/deportacja_archiw.jpg').default}
                            onClick={() => setShowArchive(false)}
                            direction="left"
                            visible={showArchive}
                        />

                        <PrimaryPopup
                            img={localeAsset('img/popup/deportation/deportacja_ploska.png')}
                            onClick={() => setShowPoland(false)}
                            visible={showPoland}
                            direction="top"
                        />


                        {!!comicTrain && (
                            <ComicBase
                                visible={isVisible => setComicTrain(isVisible)}
                                file={ComicDeportationTrain}
                                musicStart={musicMapStart}
                                nextMap="true"
                                functionNextMap={handleNextMap}
                            />
                        )}


                        {/*<PrimaryPopup*/}
                        {/*    onClick={() => setShowWebsiteMap(false)}*/}
                        {/*    visible={showWebsiteMap}*/}
                        {/*    img={require('../../assets/img/mapWebsite/map.png').default}*/}
                        {/*    className="-mapWebsite"*/}
                        {/*/>*/}


                        <WebsiteMapPopup
                            onClick={() => setShowWebsiteMap(false)}
                            visible={showWebsiteMap}
                            className="-mapWebsite"
                            musicStop={musicMapStop}
                            onShowVideo={() => setShowVideoDeportation(true)}
                        />


                    </div>

                </div>
            )}
        </>


    )
}
