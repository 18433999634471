import React, {useEffect, useState} from "react";
import {useLocaleAsset} from "../../misc/Hooks";


interface Props {

    // hide: boolean;
    // countAll: any,
    // countOnLoad: any,

}



export default (props: Props) => {

const [hide, setHide] = useState(false);
const [exist, setExist] = useState(true);
    const localAssets = useLocaleAsset();

useEffect(()=> {
    handleHidePreloader();
}, [])

    const handleHidePreloader = () => {
        setTimeout(()=> {
            setHide(true)
        },8000)
         setTimeout(()=> {
             setExist(false)
         },8300)
    }


    return (
        <>
            {!!exist && (
                <div className={`preloader ${hide ? '-hide' : ''}`}>
                    {/*<img src={require('../../assets/img/other/preloader.png').default} alt="" className={`preloader__image`}/>*/}
                    <div className="preloader__wrapper">
                        <img src={localAssets('img/other/preloader.png')} alt="" className={`preloader__image`}/>


                            <div className="preloader__loadContainer">
                                <div className="preloader__load -video"/>
                            </div>


                    </div>
                    {/*<>*/}
                    {/*    <div className='helper-text'><span>Wszystkich: {Object.keys(props.countAll).length}</span></div>*/}
                    {/*    <div className='helper-text'><span>Załadowano: {props.countOnLoad}</span></div>*/}
                    {/*    <div className='helper-text'><span>Procent: {(props.countOnLoad/Object.keys(props.countAll).length) * 100} . </span></div>*/}
                    {/*</>*/}
                </div>
            )}



        </>

    )
}