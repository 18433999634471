

export default {
    NAME: 'ComicHousingEstateGwiazdka',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_03.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_04.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_05.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_06.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_07.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_08.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_09.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_10.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_11.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_12.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_13.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_14.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_15.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_16.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_17.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_18.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_19.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_20.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_21.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_04_Gwiazdka/szlak_2_04_Gwiazdka_22.jpg',
        },


    ],
    COMIC_SOUNDS_LIST: [
        {
            id: 1,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtA_Campfire_03.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtA_Crowd_01.mp3').default,
            from: 10,
            to: 11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtA_GustyInterior_01.mp3').default,
            from: 1,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtA_GustyInterior_01.mp3').default,
            from: 3,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtA_GustyInterior_01.mp3').default,
            from: 5,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtA_GustyInterior_01.mp3').default,
            from: 8,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtA_GustyInterior_01.mp3').default,
            from: 10,
            to: 12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtA_GustyInterior_01.mp3').default,
            from: 12,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtA_IndoorCrowd_01.mp3').default,
            from: 14,
            to: 15,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtA_Night_01.mp3').default,
            from: 0,
            to: 1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtA_NightAmbience_01.mp3').default,
            from: 15,
            to: 17,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtA_NightAmbience_02.mp3').default,
            from: 19,
            to: 22,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtA_OceanbyNight_01.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtB_Campfire_01.mp3').default,
            from: 13,
            to: 15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtB_Campfire_02.mp3').default,
            from: 9,
            to: 10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtB_Campfire_03.mp3').default,
            from: 10,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtB_Fire_01.mp3').default,
            from: 11,
            to: 13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtB_FoodBoiling_01.mp3').default,
            from: 14,
            to: 15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 19,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtB_HumanCrying_01.mp3').default,
            from: 12,
            to: 13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 20,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_AtB_Movement_01.mp3').default,
            from: 16,
            to: 17,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 21,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_FxA_Camel_01.mp3').default,
            from: 15,
            to: 16,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_FxA_Camel_02.mp3').default,
            from: 16,
            to: 18,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_FxA_Carrots_01_01.mp3').default,
            from: 3,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 24,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_FxA_Chest_01.mp3').default,
            from: 17,
            to: 18,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 25,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_FxA_CuttingCarrots_01.mp3').default,
            from: 1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 26,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_FxA_EggCrack_01_01.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 27,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_FxB_Fireworks_01.mp3').default,
            from: 18,
            to: 19,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 28,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_FxA_Whoosh_01.mp3').default,
            from: 0,
            to: 1,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 29,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_FxB_Crowd_01.mp3').default,
            from: 14,
            to: 15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 30,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_FxB_Fireworks_01.mp3').default,
            from: 18,
            to: 19,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 31,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_FxB_Footsteps_01.mp3').default,
            from: 17,
            to: 18,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 32,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_VO_ChildrenAmbience_Loop_01.mp3').default,
            from: 10,
            to: 11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 33,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_VO_ChildrenSadness_Loop_01.mp3').default,
            from: 12,
            to: 13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 34,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_VO_Uuuuu.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 35,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_Mx_GdySlicznaPanna.mp3').default,
            from: 7,
            to: 22,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 36,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_FxA_CuttingCarrots_02.mp3').default,
            from: 2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 37,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_FxA_FlourAndWaterPour_01_01.mp3').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 38,
            file: require('../assets/sounds/comics/housing-estate/gwiazdka/TBB_Szlak_02_Gwiazdka_FxA_Fireworks_01.mp3').default,
            from: 18,
            to: 20,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },

    ],
}
