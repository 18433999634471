import React, {useEffect, useState} from "react";
import {Preloader, PrimaryVideo} from "../misc/_index";
import koniecWojny from "../../assets/video/koniecWojny.mp4";

import {ComicBase} from "../comics/ComicBase";
import ComicHousingEstateAdoption from "../../utils/ComicHousingEstateAdoption";
import {useHistory, useRouteMatch} from "react-router-dom";
import Routes from "../../utils/Routes";
import {useDispatch, useSelector} from "react-redux";
import MapList from "../../utils/MapList";
import * as reducer from "../../store/reducer";
import {useLocaleAsset, useNavigation, useTranslation} from "../../misc/Hooks";
import {WebsiteMapPopup} from "../popup/WebsiteMapPopup";
import MuteButton from "../misc/MuteButton";

export default (props) => {
    const history = useHistory();
    const t = useTranslation();
    const navigation = useNavigation();
    const localeAssets = useLocaleAsset()

    const dispatch = useDispatch();
    const match = useRouteMatch();
    const store = useSelector(state=>({
        newestMapIndex: state.newestMapIndex
    }))


    const [preloader, setPreloader] = useState(true);
    const [preloaderHide, setPreloaderHide] = useState(false);
    const [showWebsiteMap, setShowWebsiteMap] = useState(false)

    // const [animationEndWar, setAnimationEndWar] = useState(true);
    const [comicAdoption, setComicAdoption] = useState(false);
    const [animationEndWar, setAnimationEndWar] = useState(true);


    useEffect(() => {

        const idx = MapList.findIndex(i => i.route === match.path);
        if(idx !== 0 && idx > store.newestMapIndex) {
            dispatch(reducer.setNewestMapIndex(idx));
        }
        setPreloader(true)
        // setTimeout(() => {
        //     setPreloaderHide(true)
        // }, 500)
        // setTimeout(() => {
        //     setPreloader(false)
        // }, 800)
        // dispatch(reducer.setLastVisitedMap(match.url))

    }, [])

    const nextMap = () => {
        if(!animationEndWar){
            history.push(navigation(Routes.LETTER))
        }
    }

    const emptyFunction = () => {

    }



    const endVideo = () => {


        setAnimationEndWar(false)
        setComicAdoption(true)


    }





    return (

       <>
           {!!animationEndWar ? (
               <PrimaryVideo
                   video={localeAssets('video/koniecWojny.mp4')}
                   onEnded={endVideo}
                   onSkip={endVideo}
               />
           ) : (
               <div className="helperBg">
                   {/*{!!preloader && (*/}
                   {/*    <Preloader hide={preloaderHide}/>*/}
                   {/*)}*/}
                   <WebsiteMapPopup
                       onClick={() => setShowWebsiteMap(false)}
                       visible={showWebsiteMap}
                       className="-mapWebsite"
                       musicStop={emptyFunction}
                       onShowVideo={() => setAnimationEndWar(true)}

                   />

                   {/*{!!animationEndWar && (*/}
                   {/*    <PrimaryVideo*/}
                   {/*        video={koniecWojny}*/}
                   {/*        onEnded={endVideo}*/}
                   {/*        onSkip={endVideo}*/}
                   {/*    />*/}
                   )}


                   {!!comicAdoption && (
                       <ComicBase
                           visible={isVisible => setComicAdoption(isVisible)}
                           file={ComicHousingEstateAdoption}
                           musicStart={nextMap}
                           exitButton={true}

                       />
                   )}

                   <button className="mapWebsiteAbsolute" onClick={()=> setShowWebsiteMap(true)}>
                       <img src={require('../../assets/img/buttons/map_www_on.png').default} alt="" className="off"/>
                       <img src={require('../../assets/img/buttons/map_www_off.png').default} alt="" className="on"/>
                   </button>

                   {/*<MuteButton*/}
                   {/*    // handleOnSoundOn={musicMapStart}*/}
                   {/*/>*/}
                   {/*{!!animationLetter && (*/}
                   {/*    <PrimaryVideo*/}
                   {/*        video={list}*/}
                   {/*        onEnded={endVideo2}*/}
                   {/*        onSkip={endVideo2}*/}
                   {/*    />*/}
                   {/*)}*/}
               </div>
           )}
       </>


    )
}
