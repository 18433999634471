import {Preloader, PrimaryVideo, SvgCircle} from "../misc/_index";
import {useLocaleAsset, useNavigation, useTranslation} from "../../misc/Hooks";
import React, {useEffect, useRef, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AudioPlayerFadePopup, FadePopup, PrimaryPopup, WebsiteMapPopup} from "../popup/_index";
import {ComicBase} from "../comics/ComicBase";
import ComicRoadGory from "../../utils/ComicRoadGory";
import ComicRoadZasadzka from "../../utils/ComicRoadZasadzka";
import ComicRoadJamnagar from "../../utils/ComicRoadJamnagar";

import Routes from "../../utils/Routes";
import {useDispatch, useSelector} from "react-redux";
import MapList from "../../utils/MapList";
import * as reducer from "../../store/reducer";
import AudioPlayerPopup from "../popup/AudioPlayerPopup";
import plum from "../../assets/sounds/effects/plum.mp3"
import soundPopup from "../../assets/sounds/popup/road/droga_hershad_.mp3"
import roadSound from "../../assets/sounds/maps/TBB_Mapy_04_Droga_01.mp3"
import nozyczkiSound from "../../assets/sounds/popup/road/TBB_PopUp_02_Nozyczki.mp3"
import krokodylSound from "../../assets/sounds/popup/road/TBB_PopUp_06_Aligator.mp3"
import soundKierowca from "../../assets/sounds/popup/road/TBB_PopUp_03_Kierowca.mp3"
import soundRzeka from "../../assets/sounds/popup/road/TBB_PopUp_05_Rzeka.mp3"
import soundMalpki from "../../assets/sounds/popup/road/TBB_PopUp_07_Malpki.mp3"
import amnestySound from "../../assets/sounds/maps/TBB_Mapy_03_Amnestia_01.mp3";
import MuteButton from "../misc/MuteButton";

let imgs = null;
let svgsImages = null;

export default (props) => {

    const history = useHistory();
    const t = useTranslation();
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const localAssets = useLocaleAsset();

    
    const store = useSelector(state => ({
        newestMapIndex: state.newestMapIndex,
        soundVolume: state.soundVolume,

    }))
    const match = useRouteMatch();

    const [preloader, setPreloader] = useState(false);
    const [preloaderHide, setPreloaderHide] = useState(false);
    const [showVideo, setShowVideo] = useState(true);
    const [showVideoTwo, setShowVideoTwo] = useState(false);
    const [showWelcome, setShowWelcome] = useState(false);
    const [showPopupJamnagar, setShowPopupJamnagar] = useState(false);

    const [showStarOne, setShowStarOne] = useState(false);
    const [showStarTwo, setShowStarTwo] = useState(false);
    const [showStarThree, setShowStarThree] = useState(false);
    const [showStarFour, setShowStarFour] = useState(false);
    const [showStarFive, setShowStarFive] = useState(false);
    const [showStarSix, setShowStarSix] = useState(false);
    const [showStarSeven, setShowStarSeven] = useState(false);
    const [showStarEight, setShowStarEight] = useState(false);
    const [showStarNine, setShowStarNine] = useState(false);

    const [showWebsiteMap, setShowWebsiteMap] = useState(false);

    const [showPopupKrokodyl, setShowPopupKrokodyl] = useState(false);
    const [showPopupMeshed, setShowPopupMeshed] = useState(false);
    const [showPopupQuetta, setShowPopupQuetta] = useState(false);
    const [showPopupTrain, setShowPopupTrain] = useState(false);
    const [showPopupWyscig, setShowPopupWyscig] = useState(false);

    const [allImages, setAllImages] = useState(0);
    const [isLoad, setIsLoad] = useState(0);

    const [comicJamnagar, setComicJamnagar] = useState(false);
    const [comicGory, setComicGory] = useState(false);
    const [comicZasadzka, setComicZasadzka] = useState(false);
    const [showMap, setShowMap] = useState(false)

    const soundPlum = useRef(new Audio(plum))
    const soundMap = useRef(new Audio(roadSound))
    
    useEffect(() => {
        soundPlum.current.volume = (store.soundVolume / 2)


        const idx = MapList.findIndex(i => i.route === match.path);
        if (idx !== 0 && idx > store.newestMapIndex) {
            dispatch(reducer.setNewestMapIndex(idx));
        }
        return () => {
            if (soundMap.current) {
                soundMap.current.pause();
                soundMap.current.currentTime = 0;
            }
          };
        // dispatch(reducer.setLastVisitedMap(match.url))

    }, [])


    useEffect(() => {
        if (showWelcome) {
            setPreloader(true)
            setPreloaderHide(false)

            imgs = [...document.querySelectorAll('img')];
            svgsImages = [...document.querySelectorAll('image')];

            setAllImages(imgs.length + svgsImages.length - 1)


            imgs.map((img) => {
                img.addEventListener('load', handleOnLoad)
            })
            svgsImages.map((svgImage) => {
                svgImage.addEventListener('load', handleOnLoad)
            })


        }

    }, [showWelcome])

    useEffect(() => {
        soundMap.current.volume = store.soundVolume
        soundPlum.current.volume = (store.soundVolume / 2)
    }, [store.soundVolume])


    let helperIsLoad = 0;

    const handleOnLoad = () => {
        ++helperIsLoad;
        setIsLoad(helperIsLoad);
        if((imgs.length + svgsImages.length - 1) === helperIsLoad){
            setTimeout(()=> {
                setPreloaderHide(true)
            },2000)
            setTimeout(()=> {
                setPreloader(false)
            }, 2300)
        }

    }

    const musicMapStart = () => {
        soundMap.current.volume = store.soundVolume;
        soundMap.current.play();
        soundMap.current.loop = true;
        setTimeout(() => {
            if (store.soundVolume >= .1) {
                soundMap.current.volume = .1;
            }
        }, 200)
        setTimeout(() => {
            if (store.soundVolume >= .3) {
                soundMap.current.volume = .3;
            }
        }, 400)
        setTimeout(() => {
            if (store.soundVolume >= .5) {
                soundMap.current.volume = .5;
            }
        }, 600)
        setTimeout(() => {
            if (store.soundVolume >= .8) {
                soundMap.current.volume = .8;
            }
        }, 800)
        setTimeout(() => {
            if (store.soundVolume >= 1) {
                soundMap.current.volume = 1;
            }
        }, 1000)
    }
    const musicMapStop = () => {
        setTimeout(() => {
            if (store.soundVolume >= .8) {
                soundMap.current.volume = .8;
            }
        }, 200)
        setTimeout(() => {
            if (store.soundVolume >= .6) {
                soundMap.current.volume = .6;
            }
        }, 400)
        setTimeout(() => {
            if (store.soundVolume >= .4) {
                soundMap.current.volume = .4;
            }
        }, 600)
        setTimeout(() => {
            if (store.soundVolume >= .2) {
                soundMap.current.volume = .2;
            }
        }, 800)
        setTimeout(() => {
            if (store.soundVolume >= .1) {
                soundMap.current.volume = .1;
            }
            soundMap.current.pause();
        }, 1000)
    }


    const handleNextMap = () => {
        setShowMap(false)
        musicMapStop()
        setTimeout(() => {
            history.push(navigation(Routes.VIDEO_HOUSING_ESTATE));
        }, 500)
    }

    const endVideo = () => {
        setShowVideo(false)
        setShowWelcome(true);
        musicMapStart();
        setTimeout(() => {
            setShowMap(true)
        }, 300)

    }

    const endVideoTwo = () => {

        history.push(navigation(Routes.MAP_HOUSING_ESTATE));

    }

    const HideWelcome = () => {
        setShowWelcome(false)

        setTimeout(() => {
            setShowStarOne(true)
            soundPlum.current.play()
        }, 400)
        setTimeout(() => {
            setShowStarTwo(true)
            soundPlum.current.play()

        }, 800)
        setTimeout(() => {
            setShowStarThree(true)
            soundPlum.current.play()
        }, 1200)
        setTimeout(() => {
            setShowStarFour(true)
            soundPlum.current.play()
        }, 1600)
        setTimeout(() => {
            setShowStarFive(true)
            soundPlum.current.play()
        }, 2000)
        setTimeout(() => {
            setShowStarSix(true)
            soundPlum.current.play()
        }, 2400)
        setTimeout(() => {
            setShowStarSeven(true)
            soundPlum.current.play()
        }, 2800)
        setTimeout(() => {
            setShowStarEight(true)
            soundPlum.current.play()
        }, 3200)
        setTimeout(() => {
            setShowStarNine(true)
            soundPlum.current.play()
        }, 3600)


    }

    const handleStartComicGory = () => {
        musicMapStop()
        setTimeout(() => {
            setComicGory(true)
        }, 1000)
    }

    const handleStartComicZasadzka = () => {
        musicMapStop()
        setTimeout(() => {
            setComicZasadzka(true)
        }, 1000)
    }
    const handleStartComicJamnagar = () => {
        musicMapStop()
        setTimeout(() => {
            setComicJamnagar(true)
        }, 1000)
    }

    const hidePopupJamnagar = (e) => {

            if(!(e.target.className === 'audio')){
                setShowPopupJamnagar(false)
            }

    }

    let mapClassName = ''
    if (showMap) {
        mapClassName += ' -active'
    }

    return (
        <>

            {!!preloader && (
                <Preloader
                    hide={preloaderHide}
                    countAll={allImages}
                    countOnLoad={isLoad}
                />
            )}

            {!showVideo ? (
                <>
                    {!showVideoTwo && (
                        <div className="content">

                            <div
                                className={`mapWrapper ${mapClassName}`}
                            >

                                <div className="mapImg">
                                    <img src={localAssets('img/maps/mapa_droga.jpg')} alt=""/>
                                </div>

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" className="mapSvg">
                                    <defs>
                                    </defs>

                                    <MuteButton
                                        elProps={{
                                            transform: "matrix(1 0 0 1 1855 1005)"
                                        }}
                                        // handleOnSoundOn={musicMapStart}
                                    />
                                    <SvgCircle
                                        elProps={{
                                            width: "66",
                                            height: "66",
                                            transform: "matrix(1 0 0 1 0 0)",
                                        }}
                                        img={require('../../assets/img/buttons/map_www_on.png').default}
                                        imgHover={require('../../assets/img/buttons/map_www_off.png').default}
                                        onClick={() => setShowWebsiteMap(true)}
                                    />

                                    <SvgCircle
                                        elProps={{
                                            width: "136",
                                            height: "136",
                                            transform: "matrix(1 0 0 1 1780 0)",

                                        }}
                                        img={require('../../assets/img/buttons/road_forward_on.png').default}
                                        imgHover={require('../../assets/img/buttons/road_forward_off.png').default}
                                        onClick={() => handleNextMap()
                                        }
                                    />


                                    <SvgCircle
                                        elProps={{
                                            width: "136",
                                            height: "131",
                                            className: `roadStarOne ${showStarOne ? '-active' : ''}`,
                                        }}
                                        img={require('../../assets/img/buttons/1on.png').default}
                                        imgHover={require('../../assets/img/buttons/1off.png').default}
                                        nameComic={'ComicRoadGory'}
                                        imgVisited={require('../../assets/img/buttons/road_1_visited.png').default}
                                        onClick={handleStartComicGory}
                                    />

                                    <SvgCircle
                                        elProps={{
                                            width: "136",
                                            height: "131",
                                            className: `roadStarTwo ${showStarTwo ? '-active' : ''}`,
                                        }}
                                        img={require('../../assets/img/buttons/2on.png').default}
                                        imgHover={require('../../assets/img/buttons/2off.png').default}
                                        imgVisited={require('../../assets/img/buttons/road_2_visited.png').default}
                                        nameComic={'Meshed'}
                                        onClick={() => setShowPopupMeshed(true)}
                                    />


                                    <SvgCircle
                                        elProps={{
                                            width: "136",
                                            height: "131",
                                            className: `roadStarThree ${showStarThree ? '-active' : ''}`,
                                        }}
                                        img={require('../../assets/img/buttons/3on.png').default}
                                        imgHover={require('../../assets/img/buttons/3off.png').default}
                                        imgVisited={require('../../assets/img/buttons/road_3_visited.png').default}
                                        nameComic={'Wyscig'}
                                        onClick={() => setShowPopupWyscig(true)}

                                    />
                                    <SvgCircle
                                        elProps={{
                                            width: "136",
                                            height: "131",
                                            className: `roadStarFour ${showStarFour ? '-active' : ''}`,
                                        }}
                                        img={require('../../assets/img/buttons/4on.png').default}
                                        imgHover={require('../../assets/img/buttons/4off.png').default}
                                        nameComic={'ComicRoadZasadzka'}
                                        imgVisited={require('../../assets/img/buttons/road_4_visited.png').default}
                                        onClick={handleStartComicZasadzka}


                                    />
                                    <SvgCircle
                                        elProps={{
                                            width: "136",
                                            height: "131",
                                            className: `roadStarFive ${showStarFive ? '-active' : ''}`,
                                        }}
                                        img={require('../../assets/img/buttons/5on.png').default}
                                        imgHover={require('../../assets/img/buttons/5off.png').default}
                                        imgVisited={require('../../assets/img/buttons/road_5_visited.png').default}
                                        nameComic={'Quetta'}
                                        onClick={() => setShowPopupQuetta(true)}

                                    />
                                    <SvgCircle
                                        elProps={{
                                            width: "136",
                                            height: "131",
                                            className: `roadStarSix ${showStarSix ? '-active' : ''}`,
                                        }}
                                        img={require('../../assets/img/buttons/6on.png').default}
                                        imgHover={require('../../assets/img/buttons/6off.png').default}
                                        imgVisited={require('../../assets/img/buttons/road_6_visited.png').default}
                                        nameComic={'Krokodyl'}
                                        onClick={() => setShowPopupKrokodyl(true)}

                                    />
                                    <SvgCircle
                                        elProps={{
                                            width: "136",
                                            height: "131",
                                            className: `roadStarSeven ${showStarSeven ? '-active' : ''}`,
                                        }}
                                        img={require('../../assets/img/buttons/7on.png').default}
                                        imgHover={require('../../assets/img/buttons/7off.png').default}
                                        imgVisited={require('../../assets/img/buttons/road_7_visited.png').default}
                                        nameComic={'Train'}
                                        onClick={() => setShowPopupTrain(true)}

                                    />
                                    <SvgCircle
                                        elProps={{
                                            width: "136",
                                            height: "131",
                                            className: `roadStarEight ${showStarEight ? '-active' : ''}`,
                                        }}
                                        img={require('../../assets/img/buttons/8on.png').default}
                                        imgHover={require('../../assets/img/buttons/8off.png').default}
                                        imgVisited={require('../../assets/img/buttons/road_8_visited.png').default}
                                        nameComic={'Jamnagar'}
                                        onClick={() => setShowPopupJamnagar(true)}
                                    />


                                    <SvgCircle
                                        elProps={{
                                            width: "136",
                                            height: "131",
                                            className: `roadStarNine ${showStarNine ? '-active' : ''}`,
                                        }}
                                        img={require('../../assets/img/buttons/9on.png').default}
                                        imgHover={require('../../assets/img/buttons/9off.png').default}
                                        nameComic={'ComicRoadJamnagar'}
                                        imgVisited={require('../../assets/img/buttons/road_9_visited.png').default}
                                        onClick={handleStartComicJamnagar}
                                    />


                                </svg>
                                <PrimaryPopup
                                    onClick={HideWelcome}
                                    visible={showWelcome}
                                    className='-amnestyWelcome'
                                    img={localAssets('img/popup/road/road_welcome.png')}
                                >
                                    <p dangerouslySetInnerHTML={{__html: t('textWelcomeRoad')}}/>
                                </PrimaryPopup>



                                    <WebsiteMapPopup
                                        onClick={() => setShowWebsiteMap(false)}
                                        visible={showWebsiteMap}
                                        className="-mapWebsite"
                                        musicStop={musicMapStop}
                                        onShowVideo={() => setShowVideo(true)}

                                    />

                                <AudioPlayerFadePopup
                                    onClick={() => setShowPopupMeshed(false)}
                                    visible={showPopupMeshed}
                                    img={localAssets('img/popup/road/meshed.png')}
                                    namePopup={'Meshed'}
                                    audio={nozyczkiSound}
                                />

                                <AudioPlayerFadePopup
                                        onClick={() => setShowPopupKrokodyl(false)}
                                        visible={showPopupKrokodyl}
                                        img={localAssets('img/popup/road/krokodyl.png')}
                                        namePopup={'Krokodyl'}
                                        audio={krokodylSound}
                                    />


                                    <AudioPlayerFadePopup
                                        onClick={() => setShowPopupQuetta(false)}
                                        visible={showPopupQuetta}
                                        img={localAssets('img/popup/road/quetta.png')}
                                        namePopup={'Quetta'}
                                        audio={soundRzeka}
                                    />

                                    <AudioPlayerFadePopup
                                        onClick={() => setShowPopupTrain(false)}
                                        visible={showPopupTrain}
                                        img={localAssets('img/popup/road/train.png')}
                                        namePopup={'Train'}
                                        audio={soundMalpki}

                                    />


                                <AudioPlayerPopup
                                    onClick={hidePopupJamnagar}
                                    visible={showPopupJamnagar}
                                    className='-roadJamnagar'
                                    img={localAssets('img/popup/road/jamnagar_bg.png')}
                                    imgPlayer={require('../../assets/img/popup/audioPlayer/player.png').default}
                                    audio={soundPopup}
                                    namePopup={'Jamnagar'}
                                />

                                    <AudioPlayerFadePopup
                                        onClick={() => setShowPopupWyscig(false)}
                                        visible={showPopupWyscig}
                                        img={localAssets('img/popup/road/wyscig.png')}
                                        namePopup={'Wyscig'}
                                        audio={soundKierowca}

                                    />

                                    {!!comicGory && (
                                        <ComicBase
                                            visible={isVisible => setComicGory(isVisible)}
                                            file={ComicRoadGory}
                                            musicStart={musicMapStart}
                                        />
                                    )}

                                    {!!comicZasadzka && (
                                        <ComicBase
                                            visible={isVisible => setComicZasadzka(isVisible)}
                                            file={ComicRoadZasadzka}
                                            musicStart={musicMapStart}
                                        />
                                    )}

                                    {!!comicJamnagar && (
                                        <ComicBase
                                            visible={isVisible => setComicJamnagar(isVisible)}
                                            file={ComicRoadJamnagar}
                                            musicStart={musicMapStart}
                                            functionNextMap={handleNextMap}
                                            nextMap={true}
                                        />
                                    )}


                            </div>


                        </div>
                        )}
                    {!!showVideoTwo && (
                        <></>
                        // <PrimaryVideo
                        // video={localAssets('video/osiedle.mp4')}
                        // onEnded={() => endVideoTwo()}
                        // class={showVideoTwo ? '' : ''}
                        // onSkip={() => endVideoTwo()}
                        // />
                        )}
                        </>

                        ) : (

                        <PrimaryVideo
                        video={localAssets('video/granica.mp4')}
                        onEnded={() => endVideo()}
                        class={showVideo ? '' : ''}
                        onSkip={() => endVideo()}
                        />

                        )}


                </>
            )
            };
