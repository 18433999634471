import React from "react";
import {SvgCircle} from "../misc/_index";
import {Route, useHistory} from "react-router-dom";

interface Props {
    onClickSun: void,
    onClickInfo: void,
    onClickArchive: void,
    onClickMapWww: void,
    onClickForward: void,
}

export default (props: Props) => {
    const history = useHistory();
    return (
        <>
            <SvgCircle
                elProps={{
                    width: "66",
                    height: "66",
                    transform: "matrix(1 0 0 1 0 0)",
                }}
                img={require('../../assets/img/buttons/map_www_on.png').default}
                imgHover={require('../../assets/img/buttons/map_www_off.png').default}
                onClick={props.onClickMapWww}

            />

            <SvgCircle
                elProps={{
                    width: "126",
                    height: "116",
                    transform: "matrix(1 0 0 1 25 935)",

                }}
                img={require('../../assets/img/buttons/info_2_on.png').default}
                imgHover={require('../../assets/img/buttons/info_2_off.png').default}
                onClick={props.onClickInfo}
            />

            {/*<SvgCircle*/}
            {/*    elProps={{*/}
            {/*        width: "142",*/}
            {/*        height: "116",*/}
            {/*        transform: "matrix(1 0 0 1 18 811)",*/}

            {/*    }}*/}
            {/*    img={require('../../assets/img/buttons/archiw_2_on.png').default}*/}
            {/*    imgHover={require('../../assets/img/buttons/archiw_2_off.png').default}*/}
            {/*    onClick={props.onClickArchive}*/}
            {/*/>*/}

            <SvgCircle
                elProps={{
                    width: "130",
                    height: "130",
                    transform: "matrix(1 0 0 1 27 800)",

                }}
                img={require('../../assets/img/buttons/camp_eye_on.png').default}
                imgHover={require('../../assets/img/buttons/camp_eye_off.png').default}
                onClick={props.onClickSun}
            />

            <SvgCircle
                elProps={{
                    width: "200",
                    height: "200",
                    transform: "matrix(1 0 0 1 1700 0)",

                }}
                img={require('../../assets/img/buttons/camp_forward_on.png').default}
                imgHover={require('../../assets/img/buttons/camp_forward_off.png').default}
                onClick={props.onClickForward}
            />


        </>
    )
}