

export default {
    NAME: 'ComicHousingEstateAdoption',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_03.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_04.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_05.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_06.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_07.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_08.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_09.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_10.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_11.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_12.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_13.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_14.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_15.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_16.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_17.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_18.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_19.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_19A.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_20.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_21.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_22.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_23.jpg',
        },
        /*{
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_24.jpg',
        },*/
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_25.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_26.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_27.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_28.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_5_Adopcja_11/szlak_5_Adopcja_29.jpg',
        },

    ],
    COMIC_SOUNDS_LIST: [
        {
            id: 1,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Applause_01.mp3').default,
            from: 27,
            to: 29,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Barrack_01.mp3').default,
            from: 2,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Blustery_01.mp3').default,
            from: 12,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Blustery_01.mp3').default,
            from: 14,
            to: 16,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Blustery_01.mp3').default,
            from: 16,
            to: 18,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Blustery_02.mp3').default,
            from: 18,
            to: 19,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Children_01.mp3').default,
            from: 25,
            to: 26,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Children_02.mp3').default,
            from: 26,
            to: 27,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Desert_01.mp3').default,
            from: 0,
            to: 1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Desert_01.mp3').default,
            from: 11,
            to: 12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_DesertRuralDay_01.mp3').default,
            from:1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_DesertRuralDay_01.mp3').default,
            from:8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_DesertRuralDay_02.mp3').default,
            from:25,
            to: 27,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_DesertRuralDay_02.mp3').default,
            from:27,
            to: 29,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Pallace_01.mp3').default,
            from:19,
            to: 20,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Pallace_01.mp3').default,
            from:24,
            to: 25,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Pallace_02.mp3').default,
            from:27,
            to: 29,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Pallace_03.mp3').default,
            from:9,
            to: 1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 19,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Pallace_03.mp3').default,
            from:20,
            to: 22,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 20,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Pallace_03.mp3').default,
            from:22,
            to: 24,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 21,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_FxA_Flag_01.mp3').default,
            from:25,
            to: 26,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_FxB_Applause_01.mp3').default,
            from:28,
            to: 29,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_Mx_01_Delegat.mp3').default,
            from: 0,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        /*{
            id: 24,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_Mx_02_ZaskoczenieGroza.mp3').default,
            from:8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
        {
            id: 25,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_Mx_03_Tajemnica.mp3').default,
            from:9,
            to: 10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 26,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_Mx_04_NaradaPrzySwiecach.mp3').default,
            from: 11,
            to: 19,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 27,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_07_AkcentRys9.mp3').default,
            from: 19,
            to: 20,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 28,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_Mx_05_Postanowienie.mp3').default,
            from:23,
            to: 24,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 29,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_Mx_07_Final.mp3').default,
            from:26,
            to: 29,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 29,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_07_AkcentRys9.mp3').default,
            from:8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 30,
            file: require('../assets/sounds/comics/end-war/adopcja/TBB_Szlak_05_Adopcja_AtA_Pallace_03.mp3').default,
            from: 10,
            to: 11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
    ],
}
