import {PopupVideo, Preloader, PrimaryVideo, SvgCircle} from "../../misc/_index";
import React, {useEffect, useState} from "react";
import {HousingEstatePopups, PrimaryPopup, WebsiteMapPopup} from "../../popup/_index";
import {HousingEstateNav} from "../../nav/_index";
import {Route, useHistory} from "react-router-dom";
import Routes from "../../../utils/Routes";
import deportation from "../../../assets/video/deportacja.mp4";
import archiveVideo from "../../../assets/video/granica.mp4"
import {ComicBase} from "../../comics/ComicBase";
import koniecWojny from "../../../assets/video/koniecWojny.mp4";

import ComicHousingEstateSzkola from "../../../utils/ComicHousingEstateSzkola";
import ComicHousingEstateJanka from "../../../utils/ComicHousingEstateJanka";
import ComicHousingEstateGwiazdka from "../../../utils/ComicHousingEstateGwiazdka";
import ComicHousingEstateMonsun from "../../../utils/ComicHousingEstateMonsun";
import ComicHousingEstateBerek from "../../../utils/ComicHousingEstateBerek";
import ComicHousingEstateManiak from "../../../utils/ComicHousingEstateManiak";
import plum from "../../../assets/sounds/effects/plum.mp3";
import {useSelector} from "react-redux";
import {useLocaleAsset, useNavigation, useTranslation} from "../../../misc/Hooks";
import SecondPopup from "../../popup/SecondPopup";
import MuteButton from "../../misc/MuteButton";

let soundPlum = null;


interface Props {
    onClickNavBraveBrunch: void,
    onClickNavLifeInTheEstate: void,
    onClickNight: void,
    onClickWonderfulYears: void,

    onClickSun: void,
    onClickArchive: void,
    onClickInfo: void,

    musicMapOn: void,
    musicMapOff: void,
}

export default (props: Props) => {

    const t = useTranslation();
    const history = useHistory()
    const navigation = useNavigation();
    const localePath = useLocaleAsset();

    const store = useSelector(state => ({
        soundVolume: state.soundVolume,
    }))
    const [preloader, setPreloader] = useState(false);
    const [preloaderHide, setPreloaderHide] = useState(false);

    const [showWelcome, setShowWelcome] = useState(false);
    const [showInfo, setShowInfo] = useState(false)
    const [showHideInfo, setShowHideInfo] = useState(false)

    const [showWebsiteMap, setShowWebsiteMap] = useState(false)
    const [showArchive, setShowArchive] = useState(false)


    const [showStarOne, setShowStarOne] = useState(false);
    const [showStarTwo, setShowStarTwo] = useState(false);
    const [showStarThree, setShowStarThree] = useState(false);
    const [showStarFour, setShowStarFour] = useState(false);
    const [showStarFive, setShowStarFive] = useState(false);
    const [showStarSix, setShowStarSix] = useState(false);

    const [comicSchool, setComicSchool] = useState(false);
    const [comicJanka, setComicJanka] = useState(false);
    const [comicStar, setComicStar] = useState(false);
    const [comicMonsun, setComicMonsun] = useState(false);
    const [comicManiak, setComicManiak] = useState(false);
    const [comicBerek, setComicBerek] = useState(false);

    const [showMap, setShowMap] = useState(false)
    const [animationEndWar, setAnimationEndWar] = useState(false)


    useEffect(() => {
        soundPlum = new Audio(plum)
        soundPlum.volume = (store.soundVolume / 2)
        if (!props.welcomeWasDisplayed) {
            setShowWelcome(true);
            setShowMap(true)

        } else {
            // setShowWelcome(true);
            setTimeout(() => {
                setShowMap(true)
            }, 500)

            setTimeout(() => {
                setShowStarOne(true)
                soundPlum.play()

            }, 1000)
            setTimeout(() => {
                setShowStarTwo(true)
                soundPlum.play()

            }, 1400)
            setTimeout(() => {
                setShowStarThree(true)
                soundPlum.play()

            }, 1800)
            setTimeout(() => {
                setShowStarFour(true)
                soundPlum.play()
            }, 2200)
            setTimeout(() => {
                setShowStarFive(true)
                soundPlum.play()
            }, 2600)
            setTimeout(() => {
                setShowStarSix(true)
                soundPlum.play()
            }, 3000)
        }
    }, [])

    useEffect(() => {
        soundPlum.volume = store.soundVolume
    }, [store.soundVolume])

    const handleNextMap = () => {
        setShowMap(false)
        props.musicMapOff();
        setTimeout(() => {
            history.push(navigation(Routes.END_WAR));
        }, 500)
    }



    const handleExtraMap = () => {
        setShowMap(false)
        props.musicMapOff();
        setTimeout(() => {
            history.push(navigation(Routes.MAP_BALACHADI));
        }, 500)
    }

    const handleShowInfo = () => {

        setShowInfo(true)

        setTimeout(() => {
            setShowHideInfo(true)
        }, 100)

    }

    const handleHideInfo = () => {
        setShowHideInfo(false)

        setTimeout(() => {
            setShowInfo(false)
        }, 400)
    }

    const HideWelcome = () => {
        setShowWelcome(false)

        setTimeout(() => {
            setShowStarOne(true)
            soundPlum.play()

        }, 500)
        setTimeout(() => {
            setShowStarTwo(true)
            soundPlum.play()
        }, 900)
        setTimeout(() => {
            setShowStarThree(true)
            soundPlum.play()
        }, 1300)
        setTimeout(() => {
            setShowStarFour(true)
            soundPlum.play()
        }, 1700)
        setTimeout(() => {
            setShowStarFive(true)
            soundPlum.play()
        }, 2100)
        setTimeout(() => {
            setShowStarSix(true)
            soundPlum.play()
        }, 2500)

    }

    const handleComicStartJanka = () => {
        props.musicMapOff()
        setTimeout(() => {
            setComicJanka(true)
        }, 1000)
    }
    const handleComicStartStar = () => {
        props.musicMapOff()
        setTimeout(() => {
            setComicStar(true)
        }, 1000)
    }
    const handleComicStartSchool = () => {
        props.musicMapOff()
        setTimeout(() => {
            setComicSchool(true)
        }, 1000)
    }
    const handleComicStartMonsun = () => {
        props.musicMapOff()
        setTimeout(() => {
            setComicMonsun(true)
        }, 1000)
    }
    const handleComicStartManiak = () => {
        props.musicMapOff()
        setTimeout(() => {
            setComicManiak(true)
        }, 1000)
    }
    const handleComicStartBerek = () => {
        props.musicMapOff()
        setTimeout(() => {
            setComicBerek(true)
        }, 1000)
    }

    let mapClassName = ''
    if (showMap) {
        mapClassName += ' -active'
    }

    let infoClassName = ''
    if (showHideInfo) {
        infoClassName += ' -active'
    }

    return (
        <>
            {!!preloader && (
                <Preloader hide={preloaderHide}/>
            )}

            <div className="content">

                <div
                    className={`mapWrapper ${mapClassName}`}
                >

                    <div className="mapImg">
                        <img src={localePath('img/maps/mapa_osiedle_dzien.jpg')} alt=""/>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" className="mapSvg">
                        <defs>
                        </defs>

                        <MuteButton
                            elProps={{}}
                            black
                        />
                        <SvgCircle
                            elProps={{
                                width: "216",
                                height: "50",
                                transform: "matrix(1 0 0 1 225 827)",
                            }}
                            img={localePath('img/buttons/housing-estate/camp_day_fajnaferajna_off.png')}
                            imgHover={localePath('img/buttons/housing-estate/camp_day_fajnaferajna_on.png')}
                            onClick={props.onClickNavBraveBrunch}
                        />

                        <SvgCircle
                            elProps={{
                                width: "216",
                                height: "56",
                                transform: "matrix(1 0 0 1 225 770)",
                            }}
                            img={localePath('img/buttons/housing-estate/camp_day_zyciewosiedlu_off.png')}
                            imgHover={localePath('img/buttons/housing-estate/camp_day_zyciewosiedlu_off.png')}
                            onClick={props.onClickNavLifeInTheEstate}
                        />

                        <SvgCircle
                            elProps={{
                                width: "216",
                                height: "50",
                                transform: "matrix(1 0 0 1 225 875)",
                            }}
                            img={localePath('img/buttons/housing-estate/camp_day_cudownelata_off.png')}
                            imgHover={localePath('img/buttons/housing-estate/camp_day_cudownelata_on.png')}
                            onClick={props.onClickWonderfulYears}
                        />

                        <SvgCircle
                            elProps={{
                                width: "216",
                                height: "56",
                                transform: "matrix(1 0 0 1 225 920)",
                            }}
                            img={localePath('img/buttons/housing-estate/camp_day_noc_off.png')}
                            imgHover={localePath('img/buttons/housing-estate/camp_day_noc_on.png')}
                            onClick={props.onClickNight}
                        />

                        <HousingEstateNav
                            onClickInfo={handleShowInfo}
                            onClickSun={handleExtraMap}
                            onClickMapWww={() => setShowWebsiteMap(true)}
                            onClickArchive={() => setShowArchive(true)}
                            onClickForward={handleNextMap}
                        />

                        <SvgCircle
                            elProps={{
                                width: "134",
                                height: "134",
                                className: `lifeInTheEstateStarOne ${showStarOne ? '-active' : ''}`,
                            }}
                            img={localePath('img/buttons/camp_1_on.png')}
                            imgHover={localePath('img/buttons/camp_1_off.png')}
                            nameComic={'ComicHousingEstateSzkola'}
                            imgVisited={require('../../../assets/img/buttons/camp_1_visited.png').default}
                            onClick={handleComicStartSchool}

                        />
                        <SvgCircle
                            elProps={{
                                width: "134",
                                height: "134",
                                className: `lifeInTheEstateStarTwo ${showStarTwo ? '-active' : ''}`,
                            }}
                            img={localePath('img/buttons/camp_2_on.png')}
                            imgHover={localePath('img/buttons/camp_2_off.png')}
                            nameComic={'ComicHousingEstateManiak'}
                            imgVisited={require('../../../assets/img/buttons/camp_2_visited.png').default}
                            onClick={handleComicStartManiak}

                        />

                        <SvgCircle
                            elProps={{
                                width: "134",
                                height: "134",
                                className: `lifeInTheEstateStarThree ${showStarThree ? '-active' : ''}`,
                            }}
                            img={localePath('img/buttons/camp_3_on.png')}
                            imgHover={localePath('img/buttons/camp_3_off.png')}
                            nameComic={'ComicHousingEstateJanka'}
                            imgVisited={require('../../../assets/img/buttons/camp_3_visited.png').default}
                            onClick={handleComicStartJanka}

                        />

                        <SvgCircle
                            elProps={{
                                width: "134",
                                height: "134",
                                className: `lifeInTheEstateStarFour ${showStarFour ? '-active' : ''}`,
                            }}
                            img={localePath('img/buttons/camp_4_on.png')}
                            imgHover={localePath('img/buttons/camp_4_off.png')}
                            nameComic={'ComicHousingEstateGwiazdka'}
                            imgVisited={require('../../../assets/img/buttons/camp_4_visited.png').default}
                            onClick={handleComicStartStar}

                        />
                        <SvgCircle
                            elProps={{
                                width: "134",
                                height: "134",
                                className: `lifeInTheEstateStarFive ${showStarFive ? '-active' : ''}`,
                            }}
                            img={localePath('img/buttons/camp_5_on.png')}
                            imgHover={localePath('img/buttons/camp_5_off.png')}
                            nameComic={'ComicHousingEstateBerek'}
                            imgVisited={require('../../../assets/img/buttons/camp_5_visited.png').default}
                            onClick={handleComicStartBerek}

                        />

                        <SvgCircle
                            elProps={{
                                width: "134",
                                height: "134",
                                className: `lifeInTheEstateStarSix ${showStarSix ? '-active' : ''}`,
                            }}
                            img={localePath('img/buttons/camp_6_on.png')}
                            imgHover={localePath('img/buttons/camp_6_off.png')}
                            nameComic={'ComicHousingEstateMonsun'}
                            imgVisited={require('../../../assets/img/buttons/camp_6_visited.png').default}
                            onClick={handleComicStartMonsun}
                        />


                        {!!showInfo && (
                            <SvgCircle
                                elProps={{
                                    width: "1920",
                                    height: "1080",
                                    className: `housingEstateInfo ${infoClassName}`

                                }}
                                img={localePath('img/popup/housing-estate/camp_map_legend.png')}
                                imgHover={localePath('img/popup/housing-estate/camp_map_legend.png')}
                                onClick={handleHideInfo}
                            />
                        )}


                    </svg>

                    <WebsiteMapPopup
                        onClick={() => setShowWebsiteMap(false)}
                        visible={showWebsiteMap}
                        className="-mapWebsite"
                        musicStop={props.musicMapOff}


                    />


                    <SecondPopup
                        onClick={HideWelcome}
                        visible={showWelcome}
                        className='-braveBunch'
                        img={localePath('img/popup/housing-estate/camp_welcome.png')}
                    >
                        <span dangerouslySetInnerHTML={{__html: t('textWelcomeBraveBunch_1')}}/>
                        <p dangerouslySetInnerHTML={{__html: t('textWelcomeBraveBunch_2')}}/>
                    </SecondPopup>

                    {!!showArchive && (
                        <PopupVideo
                            video={archiveVideo}
                            onEnded={() => setShowArchive(false)}
                            class={"-housingEstateArchiveVideo"}
                            onSkip={() => setShowArchive(false)}
                        />
                    )}
                    {!!comicSchool && (
                        <ComicBase
                            visible={isVisible => setComicSchool(isVisible)}
                            file={ComicHousingEstateSzkola}
                            musicStart={props.musicMapOn}
                        />
                    )}
                    {!!comicJanka && (
                        <ComicBase
                            visible={isVisible => setComicJanka(isVisible)}
                            file={ComicHousingEstateJanka}
                            musicStart={props.musicMapOn}
                        />
                    )}
                    {!!comicStar && (
                        <ComicBase
                            visible={isVisible => setComicStar(isVisible)}
                            file={ComicHousingEstateGwiazdka}
                            musicStart={props.musicMapOn}
                        />
                    )}
                    {!!comicMonsun && (
                        <ComicBase
                            visible={isVisible => setComicMonsun(isVisible)}
                            file={ComicHousingEstateMonsun}
                            musicStart={props.musicMapOn}
                        />
                    )}
                    {!!comicManiak && (
                        <ComicBase
                            visible={isVisible => setComicManiak(isVisible)}
                            file={ComicHousingEstateManiak}
                            musicStart={props.musicMapOn}
                        />
                    )}
                    {!!comicBerek && (
                        <ComicBase
                            visible={isVisible => setComicBerek(isVisible)}
                            file={ComicHousingEstateBerek}
                            musicStart={props.musicMapOn}
                        />
                    )}

                </div>

            </div>


        </>
    )
}