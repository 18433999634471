import React, {useEffect, useState} from "react";
import plum from "../../assets/sounds/effects/plum.mp3";
import clickSoundFile from "../../assets/sounds/effects/TBB_UI_Click_04.mp3"
import {useSelector} from "react-redux";

interface Props {
    img: any,
    imgHover: any,
    imgVisited: any,
    elProps: any,
    onClick?: void,
    nameComic: any,
}

let clickSound = null;

export default (props: Props) => {

    const store = useSelector(state => ({
        visitedComics: state.visitedComics,
    }))



    useEffect(() => {
        clickSound = new Audio(clickSoundFile)
        clickSound.volume = 1;
        clickSound.loop = false;
    }, [])

    const [hovered, setHovered] = useState(false);



    const handleOnClick = () => {
            // clickSound.play()

        props.onClick()
    }
    return (

        <>
            {!!props.nameComic ? (
                <>
                    {[...(store.visitedComics??[])].findIndex(i => i === props.nameComic) >= 0  ? (
                        <image
                            {...props.elProps}
                            xlinkHref={props.imgVisited}
                            onClick={typeof props.onClick === 'function' ? handleOnClick : undefined}
                            // onMouseEnter={()=>setHovered(true)}
                            // onMouseLeave={()=>setHovered(false)}
                        />
                    ) : (
                        <image
                            {...props.elProps}
                            xlinkHref={hovered ? props.img : props.imgHover}
                            onClick={typeof props.onClick === 'function' ? handleOnClick : undefined}
                            onMouseEnter={()=>setHovered(true)}
                            onMouseLeave={()=>setHovered(false)}
                        />

                    )}
                </>
            ) : (
                <image
                    {...props.elProps}
                    xlinkHref={hovered ? props.img : props.imgHover}
                    onClick={typeof props.onClick === 'function' ? handleOnClick : undefined}
                    onMouseEnter={()=>setHovered(true)}
                    onMouseLeave={()=>setHovered(false)}
                />
            )}
        </>
    )
}


