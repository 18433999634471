import React, {useEffect, useRef, useState} from "react";
import {PreloaderVideo} from "./_index";
import {useSelector} from "react-redux";
import Button from "./Button";
import MuteButton from "./MuteButton";

interface Props {
    onEnded: any,
    video: any,
    onSkip: any,
    class: any,
}



export default (props: Props) => {
    const store = useSelector(state => ({
        soundVolume: state.soundVolume,
    }))
    const [showSkip, setShowSkip] = useState(false)
    const [autoplayError, setAutoplayError] = useState(false)
    const el = useRef()

    useEffect(() => {
        el.current = document.querySelector('video');
    }, [])

    const [isSkip, setIsSkip] = useState(false)

    if (el.current && !showSkip) {
        el.current.pause()
        setTimeout(() => {
            handleStartVideo()

            setShowSkip(true)
        }, 8000)
    }

    useEffect(() => {
        if (el.current) {
            el.current.volume = store.soundVolume;
        }
    }, [store.soundVolume])

    const handleStartVideo = () => {
        setAutoplayError(false)
        el.current.play().catch((error) => {
            if (error.name === 'NotAllowedError') {
                setAutoplayError(true)
            } else {
                console.error("Error playing the sound:", error);
            }
        });
    }
    const handleSkip = () => {
        el.current.pause()
        setIsSkip(true)
        props.onSkip()
    }

    return (
        <>
            <PreloaderVideo/>
            <div className={`videoWrapper ${props.class}`}>
                <video
                    preload="auto"
                    height="100%"
                    onEnded={props.onEnded}
                    autoPlay
                    onDragExit={props.onEnded}
                >
                    <source
                        src={props.video}
                        type='video/mp4'
                    />
                </video>

                {autoplayError && (
                    <button className={"manualStartVideo"} onClick={handleStartVideo}>PLAY</button>
                )}

                <MuteButton
                    handleOnSoundOn={() => {

                    }}
                />
            </div>

            {!!showSkip && (
                <Button onClick={handleSkip}
                        className={"skipButton"}
                        img={'img/buttons/skip.png'}
                        imgHover={'img/buttons/skip_hover.png'}
                />
            )}
        </>
    )
}
