

export default {
    NAME: 'ComicHousingEstateJanka',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_2_03_Janka/szlak_2_03_Janka_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_03_Janka/szlak_2_03_Janka_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_03_Janka/szlak_2_03_Janka_03.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_03_Janka/szlak_2_03_Janka_04.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_03_Janka/szlak_2_03_Janka_05.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_03_Janka/szlak_2_03_Janka_06.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_03_Janka/szlak_2_03_Janka_07.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_03_Janka/szlak_2_03_Janka_08.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_03_Janka/szlak_2_03_Janka_09.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_03_Janka/szlak_2_03_Janka_10.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_03_Janka/szlak_2_03_Janka_11.jpg',
        },


    ],
    COMIC_SOUNDS_LIST: [
        {
            id: 1,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_AtA_CicadasAmbience_01.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_AtA_DesertDay_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_AtA_DesertDay_02.mp3').default,
            from: 5,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_AtA_DesertRuralDay_01.mp3').default,
            from: 3,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_AtA_DesertRuralDay_02.mp3').default,
            from: 10,
            to: 11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_AtA_Footsteps_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_AtA_Footsteps_02.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_AtA_Night_01.mp3').default,
            from: 0,
            to: 1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_AtA_NightCampfire_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_AtA_SunnyDay_01.mp3').default,
            from: 9,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_AtA_WindyNight_01.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_AtB_Fire_01.mp3').default,
            from: 2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_AtB_Footsteps_01.mp3').default,
            from: 1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_AtB_Footsteps_02.mp3').default,
            from: 1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_FxA_Clothes_01.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        // {
        //     id: 16,
        //     file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_FxA_Instrument_01.mp3').default,
        //     from: 6,
        //     to: 7,
        //     loop: false,
        //     delay: 0,
        //     volume: 1,
        //     onEndSound: false
        // },
        {
            id: 17,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_FxB_DogAttack_01.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_FxB_Flag_01.mp3').default,
            from: 10,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 19,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_FxB_Footsteps_02.mp3').default,
            from: 9,
            to: 10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        // {
        //     id: 20,
        //     file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_FxB_Trumpet_01.mp3').default,
        //     from: 10,
        //     to: 11,
        //     loop: false,
        //     delay: 0,
        //     volume: 1,
        //     onEndSound: false
        // },
        {
            id: 21,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_VO_AUU_01.mp3').default,
            from: 7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_Mx_01_IdzieNoc.mp3').default,
            from: 0,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_Mx_02_AkcentMusztorwy.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 24,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_Mx_03_AkcentMusztorwyHumorystyczny.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },

        {
            id: 25,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_Mx_04_AkcentWscieklyPies.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 26,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_Mx_05_OpiekunczaFaza.mp3').default,
            from: 9,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        /*{
            id: 27,
            file: require('../assets/sounds/comics/housing-estate/janka/TBB_Szlak_02_Janka_Mx_06_ZbiorkaHarcerska.mp3').default,
            from: 10,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/



    ],
}
