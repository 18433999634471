
export default {
    NAME: 'ComicBieriezniakiMama',
    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/bieriezniaki/mama/syberia_03_Mama_01.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/mama/syberia_03_Mama_02.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/mama/syberia_03_Mama_03.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/mama/syberia_03_Mama_04.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/mama/syberia_03_Mama_05.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/mama/syberia_03_Mama_06.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/mama/syberia_03_Mama_07.jpg',
        },
        // {
        //     img:  'img/comics/bieriezniaki/mama/syberia_03_Mama_08.jpg',
        // },
        {
            img:  'img/comics/bieriezniaki/mama/syberia_03_Mama_09.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/mama/syberia_03_Mama_10.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/mama/syberia_03_Mama_11.jpg',
        },

    ],
    COMIC_SOUNDS_LIST: [
        {
            id: 1,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_AtA_HandSaw_Loop_01.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_AtA_RoomToneHowling_Loop_01.mp3').default,
            from: 4,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_AtA_RoomToneQuiet_Loop_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_AtA_Sheepfold_Loop_01.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_AtA_TimberLogs_Loop_01.mp3').default,
            from: 1,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_AtB_ConiferousForest_Loop_01.mp3').default,
            from: 0,
            to: 1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_AtB_ConiferousForest_Loop_02.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_AtB_ConiferousForest_Loop_02.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_AtB_WindBlusteringInside_Loop_01.mp3').default,
            from: 9,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_AtB_WindyInside_Loop_01.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_AtB_WindHowlingInside_Loop_01.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_AtB_WinterWind_Loop_01.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_AtB_WinterWind_Loop_01.mp3').default,
            from: 9,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_FxB_SheepCrying_01.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_FxB_WoolvesHowls_01.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/bierezniaki/mama/TBB_Syberia_03_Matka_Mx_All_Short.mp3').default,
            from: 0,
            to: 10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
    ],
}
