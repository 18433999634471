import {initialState} from "./store";


const SET_NEWEST_MAP_INDEX = 'setNewestMapIndex';
const CLEAR_STORE = 'clearStore';
const SET_VOLUME_AUDIO = 'setVolumeAudio';
const SET_INFO_START = 'setInfoStart';
const SET_VISITED_COMICS = 'setVisitedComics';
const SET_LAST_VISITED_MAP = 'setLastVisitedMap';




const reducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_NEWEST_MAP_INDEX:
            return {
                ...state,
                newestMapIndex: action.newestMapIndex,
            }

        case CLEAR_STORE:
            return {...initialState}
        case SET_VOLUME_AUDIO:
            return {
                ...state, soundVolume: action.soundVolume,
            }
        case SET_INFO_START:
            return {
                ...state, infoStart: action.infoStart
            }
        case SET_VISITED_COMICS:
            return {
                ...state, visitedComics: action.visitedComics
            }
        case SET_LAST_VISITED_MAP:
            return  {
                ...state, lastVisitedMap: action.lastVisitedMap
            }

        default: return state;
    }
}


export const setNewestMapIndex = (newestMapIndex) => ({type: SET_NEWEST_MAP_INDEX, newestMapIndex})
export const clearStore = () => ({type: CLEAR_STORE})
export const setVolumeAudio = (soundVolume) => ({type: SET_VOLUME_AUDIO, soundVolume})
export const setInfoStart = (infoStart) => ({type: SET_INFO_START, infoStart})
export const setVisitedComics = (visitedComics) => ({type: SET_VISITED_COMICS, visitedComics})
export const setLastVisitedMap = (lastVisitedMap) => ({type: SET_LAST_VISITED_MAP, lastVisitedMap})


export default reducer;
