export default {
    NAME: 'ComicBieriezniakiPolak',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/bieriezniaki/polak/syberia_04_Polak_01.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/polak/syberia_04_Polak_02.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/polak/syberia_04_Polak_03.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/polak/syberia_04_Polak_04.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/polak/syberia_04_Polak_05.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/polak/syberia_04_Polak_06.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/polak/syberia_04_Polak_07.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/polak/syberia_04_Polak_08.jpg',
        },
        {
            img:  'img/comics/bieriezniaki/polak/syberia_04_Polak_09.jpg',
        },


    ],
    COMIC_SOUNDS_LIST: [
        {
            id: 1,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_AtA_DayAmbienceAndFoliage_Loop_01.mp3').default,
            from: 0,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_AtA_DayAmbienceAndFoliage_Loop_01.mp3').default,
            from: 4,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_AtA_WindMountainBreeze_Loop_01.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_AtB_ConiferousForest_Loop_01.mp3').default,
            from: 5,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_AtB_ConiferousForestActive_Loop_01.mp3').default,
            from: 0,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_FxA_DoorOpenSqueak_01.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_FxA_InsectFranticSwarm.mp3').default,
            from: 2,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_FxA_InsectFranticSwarm.mp3').default,
            from: 7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_FxA_WolverineSnarlAndFoliage_01.mp3').default,
            from: 3,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_FxB_InsectCrazy_Loop_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_FxB_InsectCrazy_Loop_02.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_FxB_InsectCrazy_Loop_03.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_FxB_InsectCrazy_Loop_04.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_FxB_InsectCrazy_Loop_05.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_FxB_InsectCrazy_Loop_07.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_Mx_Akcent_01.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/bierezniaki/polak/TBB_Syberia_04_Polak_Mx_Akcent_02.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },

    ],
}
