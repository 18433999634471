import {useState} from "react";
import {useLocaleAsset} from "../../misc/Hooks";

// img, imgHover, onClick, link, children, className, elementProps
export default function Button(props) {
    const localeAsset = useLocaleAsset();
    const [hovered, setHovered] = useState(false);

    const handleOnClick = () => {
        if (props.onClick && typeof props.onClick === 'function')
            props.onClick()
    }

    if (props.children) {
        // if will be used add distinction between link and button
        return (
            <button
                {...props}
                onClick={handleOnClick}
                type={'button'}
                className={props.className}
            >
                {props.children}
            </button>
        )
    }
    const imgProps = {
        ...props.elementProps,
        src: localeAsset(hovered ? props.imgHover : props.img),
        onMouseEnter: () => setHovered(true),
        onMouseLeave: () => setHovered(false),
        className: props.className,
    }

    if (props.link)
        return (
            <a href={props.link} target={'_blank'} rel={'noreferrer'}>
                <img
                    {...imgProps}
                />
            </a>
        )
    else
        return (
            <img
                {...imgProps}
                onClick={handleOnClick}
            />
        )
}