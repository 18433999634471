import React, {useEffect, useState} from "react";
import Preloader from "../misc/Preloader";
import {useLocaleAsset} from "../../misc/Hooks";


interface Props {
    image: any,
    fade: boolean,
    fadeOnlyEnd: boolean,
    currentIndex: any,
    idx: any,
    imageList: any,
    startNextSlide: boolean,
    onLoad: void
}


export const ComicItem = (props: Props) => {

    const [fadeOnlyEnd, setFadeOnlyEnd] = useState('')

    const localAssets = useLocaleAsset();
    useEffect(()=> {
        if((props.currentIndex === props.idx) && props.fadeOnlyEnd){
            setTimeout(()=> {
                setFadeOnlyEnd(' -fade')
            }, 300)
        } else  {
            setTimeout(()=> {
                setFadeOnlyEnd('')
            }, 300)
        }
    }, [props.currentIndex])

    let active = ''

    if(props.currentIndex === props.idx) {
        active = ' -active'
    }

    let classFade = ''

    if(props.fade && !props.fadeOnlyEnd){
        classFade += ' -fade'
    }


    return (
        <>


            <div className={`comicItem  ${active} ${classFade} ${fadeOnlyEnd}`}>
                <img src={localAssets(props.image)} alt="" className="comicItem__thumb" onLoad={props.onLoad}/>
            </div>

        </>

    )
}

