

export default {
    NAME: 'ComicHousingEstatePojedynek',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_03.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_04.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_05.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_06.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_07.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_08.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_09.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_10.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_11.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_12.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_13.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_14.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_15.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_16.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_17.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_18.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_02_Pojedynek/szlak_1_02_Pojedynek_19.jpg',
        },


    ],
    COMIC_SOUNDS_LIST: [
         {
             id: 1,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_01.mp3').default,
             from: 0,
             to: 2,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 2,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 3,
             to: 4,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 3,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Earthquake_01.mp3').default,
             from: 5,
             to: 6,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 4,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Earthquake_02.mp3').default,
             from: 6,
             to: 7,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 5,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Earthquake_03.mp3').default,
             from: 7,
             to: 8,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 6,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Earthquake_04.mp3').default,
             from: 8,
             to: 9,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 7,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Earthquake_05.mp3').default,
             from: 9,
             to: 10,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 8,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Earthquake_06.mp3').default,
             from: 10,
             to: 11,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 9,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Earthquake_07.mp3').default,
             from: 11,
             to: 12,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 10,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Earthquake_08.mp3').default,
             from: 12,
             to: 13,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 11,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Earthquake_09.mp3').default,
             from: 13,
             to: 14,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 12,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Earthquake_10.mp3').default,
             from: 14,
             to: 15,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 13,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Earthquake_11.mp3').default,
             from: 15,
             to: 16,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 14,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Earthquake_12.mp3').default,
             from: 16,
             to: 17,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 15,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Earthquake_13.mp3').default,
             from: 17,
             to: 18,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 16,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Earthquake_14.mp3').default,
             from: 18,
             to: 19,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 17,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Indoor_01.mp3').default,
             from: 2,
             to: 3,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 18,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Village_01.mp3').default,
             from: 3,
             to: 4,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 20,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 4,
             to: 5,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 21,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 5,
             to: 6,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 22,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 6,
             to: 7,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 23,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 7,
             to: 8,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 24,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 8,
             to: 9,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 25,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 9,
             to: 10,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 26,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 10,
             to: 11,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 27,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 11,
             to: 12,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 28,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 12,
             to: 13,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 29,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 13,
             to: 14,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 30,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 14,
             to: 15,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 31,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 15,
             to: 16,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 32,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 16,
             to: 17,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 33,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 17,
             to: 18,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 34,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_AtA_Desert_02.mp3').default,
             from: 18,
             to: 19,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 35,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxA_AngryAnimals_01.mp3').default,
             from: 8,
             to: 10,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 36,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxA_AngryAnimals_02.mp3').default,
             from: 11,
             to: 12,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 37,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxA_AngryBull_01.mp3').default,
             from: 6,
             to: 7,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 38,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxA_AngryBull_02.mp3').default,
             from: 12,
             to: 13,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 39,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxA_Attack_01.mp3').default,
             from: 13,
             to: 14,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 40,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxB_Attack_01.mp3').default,
             from: 9,
             to: 10,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 41,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxA_Attack_02.mp3').default,
             from: 14,
             to: 15,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 42,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxA_Attack_03.mp3').default,
             from: 15,
             to: 16,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 43,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxA_BearPain_01.mp3').default,
             from: 10,
             to: 11,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 44,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxA_Breathing_01.mp3').default,
             from: 5,
             to: 6,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 45,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxA_Breathing_02.mp3').default,
             from: 7,
             to: 9,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 46,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxA_BullRoar_01.mp3').default,
             from: 9,
             to: 10,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 47,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxA_Fighting_01.mp3').default,
             from: 0,
             to: 1,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 48,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxA_Footsteps_01.mp3').default,
             from: 3,
             to: 4,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 49,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxA_InjuredAnimals_01.mp3').default,
             from: 16,
             to: 17,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 50,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxA_Presence_01.mp3').default,
             from: 2,
             to: 3,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 51,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxB_Breathing_01.mp3').default,
             from: 14,
             to: 15,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 52,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxB_Growl_01.mp3').default,
             from: 8,
             to: 9,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 53,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxB_Growl_02.mp3').default,
             from: 12,
             to: 13,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 54,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxB_Impact_01.mp3').default,
             from: 6,
             to: 7,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 55,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxB_Impact_02.mp3').default,
             from: 10,
             to: 11,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 56,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_FxB_Impact_03.mp3').default,
             from: 16,
             to: 17,
             loop: false,
             delay: 200,
             volume: 1,
             onEndSound: false
         },
         {
             id: 57,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_Mx_01_Ver_2_Il Divo.mp3').default,
             from: 0,
             to: 8,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 58,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_Mx_02_Akcent_01.mp3').default,
             from: 4,
             to: 5,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 59,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_Mx_03_Akcent_02.mp3').default,
             from: 5,
             to: 6,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 60,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_Mx_04_AkcentVer_2 _2HitsMore.mp3').default,
             from: 6,
             to: 7,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 61,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_Mx_05_VER_02_Drums.mp3').default,
             from: 12,
             to: 16,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 62,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_Mx_06_End.mp3').default,
             from: 16,
             to: 19,
             loop: false,
             delay: 200,
             volume: 1,
             onEndSound: false
         },
         {
             id: 63,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_VO_Laugh_01.mp3').default,
             from: 2,
             to: 3,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 64,
             file: require('../assets/sounds/comics/housing-estate/pojedynek/TBB_Szlak1_02_Pojedynek_VO_Laugh_Loop_01.mp3').default,
             from: 2,
             to: 3,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
    ],
}
