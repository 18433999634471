import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import reportWebVitals from './reportWebVitals';

import ComicAmnestyCygaro from "./app/utils/ComicAmnestyCygaro";
import ComicAmnestyDetdom from "./app/utils/ComicAmnestyDetdom";
import ComicAmnestyDetdomGu from "./app/utils/ComicAmnestyDetdomGu";
import ComicAmnestyDetdomHi from "./app/utils/ComicAmnestyDetdomHi";
import ComicAmnestyKasansaj from "./app/utils/ComicAmnestyKasansaj";
import ComicAmnestyOficer from "./app/utils/ComicAmnestyOficer";
import ComicAmnestyWagony from "./app/utils/ComicAmnestyWagony";
import ComicBieriezniakiJuchim from "./app/utils/ComicBieriezniakiJuchim";
import ComicBieriezniakiMama from "./app/utils/ComicBieriezniakiMama";
import ComicBieriezniakiObcy from "./app/utils/ComicBieriezniakiObcy";
import ComicBieriezniakiPolak from "./app/utils/ComicBieriezniakiPolak";
import ComicDeportationTrain from "./app/utils/ComicDeportationTrain";
import ComicHousingEstateAdoption from "./app/utils/ComicHousingEstateAdoption";
import ComicHousingEstateBarak15 from "./app/utils/ComicHousingEstateBarak15";
import ComicHousingEstateBerek from "./app/utils/ComicHousingEstateBerek";
import ComicHousingEstateBoles from "./app/utils/ComicHousingEstateBoles";
import ComicHousingEstateCiemnia from "./app/utils/ComicHousingEstateCiemnia";
import ComicHousingEstateGwiazdka from "./app/utils/ComicHousingEstateGwiazdka";
import ComicHousingEstateJanka from "./app/utils/ComicHousingEstateJanka";
import ComicHousingEstateKapiele from "./app/utils/ComicHousingEstateKapiele";
import ComicHousingEstateMaharadza from "./app/utils/ComicHousingEstateMaharadza";
import ComicHousingEstateManiak from "./app/utils/ComicHousingEstateManiak";
import ComicHousingEstateMonsun from "./app/utils/ComicHousingEstateMonsun";
import ComicHousingEstatePojedynek from "./app/utils/ComicHousingEstatePojedynek";
import ComicHousingEstateRiki from "./app/utils/ComicHousingEstateRiki";
import ComicHousingEstateSzkola from "./app/utils/ComicHousingEstateSzkola";
import ComicHousingEstateTragedia from "./app/utils/ComicHousingEstateTragedia";
import ComicHousingEstateWyscig from "./app/utils/ComicHousingEstateWyscig";
import ComicHousingEstateZieloneMalpy from "./app/utils/ComicHousingEstateZieloneMalpy";
import ComicRoadGory from "./app/utils/ComicRoadGory";
import ComicRoadJamnagar from "./app/utils/ComicRoadJamnagar";
import ComicRoadZasadzka from "./app/utils/ComicRoadZasadzka";


if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js').then(function (registration) {
        console.log('Service Worker registered with scope:', registration.scope);

        registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                        console.log('New content is available; please refresh.');

                        const sounds = [
                            ...ComicRoadZasadzka.COMIC_SOUNDS_LIST,
                            ...ComicRoadJamnagar.COMIC_SOUNDS_LIST,
                            ...ComicRoadGory.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateZieloneMalpy.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateWyscig.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateTragedia.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateSzkola.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateRiki.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstatePojedynek.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateMonsun.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateManiak.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateMaharadza.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateKapiele.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateJanka.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateGwiazdka.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateCiemnia.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateBoles.COMIC_SOUNDS_LIST,
                            ...ComicAmnestyCygaro.COMIC_SOUNDS_LIST,
                            ...ComicAmnestyDetdom.COMIC_SOUNDS_LIST,
                            ...ComicAmnestyDetdomGu.COMIC_SOUNDS_LIST,
                            ...ComicAmnestyDetdomHi.COMIC_SOUNDS_LIST,
                            ...ComicAmnestyKasansaj.COMIC_SOUNDS_LIST,
                            ...ComicAmnestyOficer.COMIC_SOUNDS_LIST,
                            ...ComicAmnestyWagony.COMIC_SOUNDS_LIST,
                            ...ComicBieriezniakiJuchim.COMIC_SOUNDS_LIST,
                            ...ComicBieriezniakiMama.COMIC_SOUNDS_LIST,
                            ...ComicBieriezniakiObcy.COMIC_SOUNDS_LIST,
                            ...ComicBieriezniakiPolak.COMIC_SOUNDS_LIST,
                            ...ComicDeportationTrain.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateAdoption.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateBarak15.COMIC_SOUNDS_LIST,
                            ...ComicHousingEstateBerek.COMIC_SOUNDS_LIST,
                        ]

                        navigator.serviceWorker.controller.postMessage({
                            type: 'CACHE_URLS',
                            urls: [...new Set(sounds)],
                        });
                    } else {
                        console.log('Content is cached for offline use.');
                    }
                }
            };
        };
    }).catch(function (error) {
        console.error('Error during Service Worker registration:', error);
    });
}


ReactDOM.render(
    <App />,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
