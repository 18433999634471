import {useEffect, useState} from "react";
import {useTranslation} from "../misc/Hooks";
import {useRouteMatch} from "react-router-dom";

export default function RotateDevice(){
    const t = useTranslation()
    const [wrongOrientation, setWrongOrientation] = useState(false)

    useEffect(() => {
        const handleOrientationChange = () => {
          const isPortrait = window.matchMedia("(orientation: portrait)").matches;
          setWrongOrientation(isPortrait);
        };

        window.addEventListener("resize", handleOrientationChange);

        handleOrientationChange();

        return () => window.removeEventListener("resize", handleOrientationChange);
      }, []);

    if(!wrongOrientation) return null

    return (
        <div id="warningMessage">
            <div className="message">
                <img src={require('../assets/img/other/rotation.svg').default} alt=""/>
                <span>{t('rotate_device')}</span>
             </div>
        </div>
    )
}