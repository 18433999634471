import React, {useEffect, useState} from "react";
import {BasePopup} from "./_index";
import {useDispatch, useSelector} from "react-redux";
import * as reducer from "../../store/reducer";
interface Props {
    visible: boolean,
    audio: any,
    onClick: any,
    className?: any,
    img: any,
    imgPlayer: any,
    text1: any,
    text2: any,
    text3: any,
}
let audio = null;
export default (props: Props) => {
    const playImg = require('../../assets/img/popup/audioPlayer/amnestia.arrow.png').default
    const stopImg = require('../../assets/img/popup/audioPlayer/amnestia.png').default
    const [isPlayImg, setIsPlayImg] = useState(playImg)
    const [isPlay, setIsPlay] = useState(false)
    const store = useSelector(state => ({
        soundVolume: state.soundVolume,
        visitedComics: state.visitedComics,

    }))
    const dispatch = useDispatch()


    useEffect(()=> {
        audio = new Audio(props.audio)
        audio.onended = setIsPlay(false)
        audio.volume = store.soundVolume
    }, [])
    useEffect(()=> {
        audio.volume = store.soundVolume
    }, [store.soundVolume])
    useEffect(()=> {
        if(!props.visible){
            setIsPlay(false);
            audio.pause()
        }
    }, [props.visible])
    const handleAudio = () => {
        if (isPlay) {
            setIsPlayImg(playImg)
            setIsPlay(false);
            audio.pause();
        } else {
            setIsPlayImg(stopImg);
            setIsPlay(true);
            audio.play();
        }
    }

    if (props.namePopup && props.visible) {
        const arr = [...store.visitedComics]

        const idx = arr.findIndex(i => i === props.namePopup)
        if (idx === -1) {
            arr.push(props.namePopup)
            dispatch(reducer.setVisitedComics(arr))
        }
    }
    return (
        <BasePopup direction="top" visible={props.visible}>
            <div className={`secondPopup ${props.className}`} onClick={props.onClick}>
                <div className="secondPopup__text">
                    <img src={props.img} alt=""/>
                    <div className="secondPopup__textWrapper -video">
                        <p>{props.text1}</p>
                        <div className="player">
                            <img src={props.imgPlayer} alt="" className="audio" onClick={handleAudio}/>
                        </div>
                        <p>{props.text2}</p>
                        <span>{props.text3}</span>
                    </div>
                </div>
            </div>
        </BasePopup>
    )
}