export default {
    NAME: 'ComicBierezniakiJuchim',

    COMIC_IMAGES_LIST: [
        {
            img: 'img/comics/bieriezniaki/juchim/syberia_02_Juchim_01.jpg',
        },
        {
            img: 'img/comics/bieriezniaki/juchim/syberia_02_Juchim_02.jpg',

        },
        {
            img: 'img/comics/bieriezniaki/juchim/syberia_02_Juchim_03.jpg',

        },
        {
            img: 'img/comics/bieriezniaki/juchim/syberia_02_Juchim_04.jpg',

        },
        {
            img: 'img/comics/bieriezniaki/juchim/syberia_02_Juchim_05.jpg',

        },
        {
            img: 'img/comics/bieriezniaki/juchim/syberia_02_Juchim_06.jpg',
            fade: true

        },
        {
            img: 'img/comics/bieriezniaki/juchim/syberia_02_Juchim_07.jpg',
            fade: true

        },


    ],
    COMIC_SOUNDS_LIST: [

        {
            id: 1,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_AtA_Insects_Loop_01.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_AtA_PeopleEatingChewing_Loop_01.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_AtA_Rain_Loop_01.mp3').default,
            from: 0,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_AtA_RainIsideHut_Loop_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_AtA_RainIsideHut_Loop_01.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_AtA_RainIsideHut_Loop_02.mp3').default,
            from: 4,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_AtB_Wind_Loop_01.mp3').default,
            from: 0,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_AtB_WindAndFireplace_Loop_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_AtB_WindAndFireplace_Loop_02.mp3').default,
            from: 4,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_AtB_WindAndFireplace_Loop_03.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_AtB_WinterForestAndCrows_Loop_01.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_FxB_Blanket_01.mp3').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_FxB_DistantThunderClap_01.mp3').default,
            from: 1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_FxB_LampMetalSqueak_01.mp3').default,
            from: 2,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_FxB_WoodenSpoon_01.mp3').default,
            from: 3,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/bierezniaki/juchim/TBB_Syberia_02_Juchim_Mx_Akcent.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },

    ],
}
