import Routes from "./Routes";
import {useTranslation} from "../misc/Hooks";

export default [
    {
        name: 'deportation',
        route: Routes.MAP_DEPORTATION,
        img_on: require('../assets/img/mapWebsite/deportacja_on.png').default,
        img_off: require('../assets/img/mapWebsite/deportacja_off.png').default,
        transform_thumb: "matrix(1 0 0 1 110 410)",
        transform_arrow: "matrix(1 0 0 1 110 220)",
        transform_x: "110",

    },
    {
        name: 'bereznyaki',
        route: Routes.MAP_BIERIEZNIAKI,
        img_on: require('../assets/img/mapWebsite/bierezniaki_on.png').default,
        img_off: require('../assets/img/mapWebsite/bierezniaki_off.png').default,
        transform_thumb: "matrix(1 0 0 1 320 410)",
        transform_arrow: "matrix(1 0 0 1 320 220)",
        transform_x: "320",

    },
    {
        name: 'amnesty',
        route: Routes.MAP_AMNESTY,
        img_on: require('../assets/img/mapWebsite/amnestia_on.png').default,
        img_off: require('../assets/img/mapWebsite/amnestia_off.png').default,
        transform_thumb: "matrix(1 0 0 1 530 410)",
        transform_arrow: "matrix(1 0 0 1 530 220)",
        transform_x: "530",

    },
    {
        name: 'border',
        route: Routes.MAP_ROAD,
        img_on: require('../assets/img/mapWebsite/granica_on.png').default,
        img_off: require('../assets/img/mapWebsite/granica_off.png').default,
        transform_thumb: "matrix(1 0 0 1 740 410)",
        transform_arrow: "matrix(1 0 0 1 740 220)",
        transform_x: "740",


    },
    {
        name: 'polishChildrenCamp',
        name2: 'polishChildrenCamp2',
        route: Routes.VIDEO_HOUSING_ESTATE,
        alternative_route: Routes.MAP_HOUSING_ESTATE,
        img_on: require('../assets/img/mapWebsite/children_camp_on.png').default,
        img_off: require('../assets/img/mapWebsite/children_camp_off.png').default,
        transform_thumb: "matrix(1 0 0 1 950 410)",
        transform_arrow: "matrix(1 0 0 1 950 220)",
        transform_x: "950",


    },
    {

        name: 'endWar',
        route: Routes.END_WAR,
        img_on: require('../assets/img/mapWebsite/koniec_wojny_on.png').default,
        img_off: require('../assets/img/mapWebsite/koniec_wojny_off.png').default,
        transform_thumb: "matrix(1 0 0 1 1160 410)",
        transform_arrow: "matrix(1 0 0 1 1160 220)",
        transform_x: "1160",

    },
    {
        name: 'letter',
        route: Routes.LETTER,
        img_on: require('../assets/img/mapWebsite/list_on.png').default,
        img_off: require('../assets/img/mapWebsite/list_off.png').default,
        transform_thumb: "matrix(1 0 0 1 1370 410)",
        transform_arrow: "matrix(1 0 0 1 1370 220)",
        transform_x: "1370",


    },
    {
        name: 'iWasHere',
        route: Routes.I_WAS_HERE,
        img_on: require('../assets/img/mapWebsite/tu_bylem_on.png').default,
        img_off: require('../assets/img/mapWebsite/tu_bylem_off.png').default,
        transform_thumb: "matrix(1 0 0 1 1580 410)",
        transform_arrow: "matrix(1 0 0 1 1580 220)",
        transform_x: "1580",


    }
]
