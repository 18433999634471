import {Preloader, SvgCircle} from "../misc/_index";
import {useLocaleAsset, useNavigation, useTranslation} from "../../misc/Hooks";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {PagesPopup, WebsiteMapPopup} from "../popup/_index";
import SecondPopup from "../popup/SecondPopup";
import Routes from "../../utils/Routes";
import {useDispatch, useSelector} from "react-redux";
import balachadiSound from "../../assets/sounds/maps/TBB_Mapy_06_Balchadi_01.mp3"
import plum from "../../assets/sounds/effects/plum.mp3"
import * as reducer from "../../store/reducer";
import bieriezniakiSound from "../../assets/sounds/maps/TBB_Mapy_02_Bieriezniaki_01.mp3";
import MuteButton from "../misc/MuteButton";

let imgs = null;
let svgsImages = null;

export default (props) => {

    const t = useTranslation();
    const history = useHistory()
    const navigation = useNavigation();
    const localAssets = useLocaleAsset()
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const store = useSelector(state => ({
        soundVolume: state.soundVolume,
    }))

    const [preloader, setPreloader] = useState(true);
    const [preloaderHide, setPreloaderHide] = useState(false);


    const [allImages, setAllImages] = useState(0);
    const [isLoad, setIsLoad] = useState(0);


    const [showStarOne, setShowStarOne] = useState(false);
    const [showStarTwo, setShowStarTwo] = useState(false);
    const [showStarThree, setShowStarThree] = useState(false);
    const [showStarFour, setShowStarFour] = useState(false);
    const [showStarFive, setShowStarFive] = useState(false);

    const [showPopupInfo, setShowPopupInfo] = useState(false);
    const [showPopupArchive, setShowPopupArchive] = useState(false);
    const [showPopupStarOne, setShowPopupStarOne] = useState(false);
    const [showPopupStarTwo, setShowPopupStarTwo] = useState(false);
    const [showPopupStarThree, setShowPopupStarThree] = useState(false);
    const [showPopupStarFour, setShowPopupStarFour] = useState(false);
    const [showPopupStarFive, setShowPopupStarFive] = useState(false);

    const [showWebsiteMap, setShowWebsiteMap] = useState(false);
    const [showMap, setShowMap] = useState(true)
    const soundPlum = useRef(new Audio(plum))
    const soundMap = useRef( new Audio(balachadiSound))
    
    useMemo(() => {
         soundPlum.current.volume = (store.soundVolume/2)
    }, [])

    useEffect(() => {
        imgs = [...document.querySelectorAll('img')];
        svgsImages = [...document.querySelectorAll('image')];

        setAllImages(imgs.length + svgsImages.length - 1)

        imgs.map((img)=> {
            img.addEventListener('load', handleOnLoad )
        })
        svgsImages.map((svgImage)=> {
            svgImage.addEventListener('load', handleOnLoad )
        })
        musicMapStart();

        return () => {
            if (soundMap.current) {
                 soundMap.current.pause();
                 soundMap.current.currentTime = 0;
            }
          };

    }, [])
    useEffect(()=> {
         soundPlum.current.volume = (store.soundVolume / 2)
         soundMap.current.volume = store.soundVolume
    }, [store.soundVolume])


    let helperIsLoad = 0;
    const handleOnLoad = () => {
        ++helperIsLoad;
        setIsLoad(helperIsLoad);
        if((imgs.length + svgsImages.length - 1) === helperIsLoad){
            setPreloaderHide(true)
            setTimeout(() => {
                setPreloaderHide(true)
            }, 2000)
            setTimeout(() => {
                setPreloader(false)
            }, 2300)

            setTimeout(() => {
                setShowStarOne(true)
                 soundPlum.current.play()
            }, 600)
            setTimeout(() => {
                setShowStarTwo(true)
                 soundPlum.current.play()

            }, 1000)
            setTimeout(() => {
                setShowStarThree(true)
                 soundPlum.current.play()

            }, 1400)
            setTimeout(() => {
                setShowStarFour(true)
                 soundPlum.current.play()

            }, 1600)
            setTimeout(() => {
                setShowStarFive(true)
                 soundPlum.current.play()
            }, 2000)
        }

    }

    const musicMapStart = () => {
         soundMap.current.volume = store.soundVolume;
         soundMap.current.play();
         soundMap.current.loop = true;
        setTimeout(() => {
            if(store.soundVolume >= .1){
                 soundMap.current.volume = .1;
            }
        }, 200)
        setTimeout(() => {
            if(store.soundVolume >= .3){
                 soundMap.current.volume = .3;
            }
        }, 400)
        setTimeout(() => {
            if(store.soundVolume >= .5){
                 soundMap.current.volume = .5;
            }
        }, 600)
        setTimeout(() => {
            if(store.soundVolume >= .8){
                 soundMap.current.volume = .8;
            }
        }, 800)
        setTimeout(() => {
            if(store.soundVolume >= 1){
                 soundMap.current.volume = 1;
            }
        }, 1000)
    }
    const musicMapStop = () => {
        setTimeout(() => {
            if(store.soundVolume >= .8){
                 soundMap.current.volume = .8;
            }
        }, 200)
        setTimeout(() => {
            if(store.soundVolume >= .6){
                 soundMap.current.volume = .6;
            }
        }, 400)
        setTimeout(() => {
            if(store.soundVolume >= .4){
                 soundMap.current.volume = .4;
            }
        }, 600)
        setTimeout(() => {
            if(store.soundVolume >= .2){
                 soundMap.current.volume = .2;
            }
        }, 800)
        setTimeout(() => {
            if(store.soundVolume >= .1){
                 soundMap.current.volume = .1;
            }
             soundMap.current.pause();
        }, 1000)
    }
    const handleNextMap = () => {
        setShowMap(false)
        musicMapStop();
        setTimeout(() => {
            history.push(navigation(Routes.MAP_HOUSING_ESTATE))

        }, 500)
    }
    let mapClassName = ''
    if (showMap) {
        mapClassName += ' -active'
    }

    return (
        <>


            {!!preloader && (
                <Preloader
                    hide={preloaderHide}
                    countAll={allImages}
                    countOnLoad={isLoad}
                />
            )}


            <div className="content">

                <div className={`mapWrapper ${mapClassName}`}>

                    <div className="mapImg">
                        <img src={localAssets('img/maps/mapa_balachadi.jpg')} alt=""/>
                    </div>


                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" className="mapSvg">
                        <defs>
                        </defs>

                        <MuteButton
                            black
                            elProps={{}}
                        />

                        <SvgCircle
                            elProps={{
                                width: "66",
                                height: "66",
                                transform: "matrix(1 0 0 1 0 0)",
                            }}
                            img={require('../../assets/img/buttons/map_www_on.png').default}
                            imgHover={require('../../assets/img/buttons/map_www_off.png').default}
                            onClick={() => setShowWebsiteMap(true)}
                        />

                        <SvgCircle
                            elProps={{
                                width: "126",
                                height: "116",
                                transform: "matrix(1 0 0 1 20 955)",

                            }}
                            img={require('../../assets/img/buttons/info_2_on.png').default}
                            imgHover={require('../../assets/img/buttons/info_2_off.png').default}
                            onClick={() => setShowPopupInfo(true)}
                        />

                        <SvgCircle
                            elProps={{
                                width: "142",
                                height: "116",
                                transform: "matrix(1 0 0 1 150 955)",
                            }}
                            img={require('../../assets/img/buttons/archiw_2_on.png').default}
                            imgHover={require('../../assets/img/buttons/archiw_2_off.png').default}
                            onClick={() => setShowPopupArchive(true)}
                        />
                        <SvgCircle
                            elProps={{
                                width: "136",
                                height: "136",
                                transform: "matrix(1 0 0 1 1780 0)",

                            }}
                            img={require('../../assets/img/buttons/balachadi_eye_on.png').default}
                            imgHover={require('../../assets/img/buttons/balachadi_eye_off.png').default}
                            onClick={handleNextMap}
                        />


                        <SvgCircle
                            elProps={{
                                width: "164",
                                height: "172",
                                className: `balachadiStarOne ${showStarOne ? '-active' : ''}`,
                            }}
                            img={require('../../assets/img/buttons/balachadi_1_on.png').default}
                            imgHover={require('../../assets/img/buttons/balachadi_1_off.png').default}
                            nameComic={'BalachadiPopup1'}
                            imgVisited={require('../../assets/img/buttons/balachadi_1_visited.png').default}
                            onClick={() => setShowPopupStarOne(true)}
                        />

                        <SvgCircle
                            elProps={{
                                width: "164",
                                height: "172",
                                className: `balachadiStarTwo ${showStarTwo ? '-active' : ''}`,
                            }}
                            img={require('../../assets/img/buttons/balachadi_2_on.png').default}
                            imgHover={require('../../assets/img/buttons/balachadi_2_off.png').default}
                            nameComic={'BalachadiPopup2'}
                            imgVisited={require('../../assets/img/buttons/balachadi_2_visited.png').default}
                            onClick={() => setShowPopupStarTwo(true)}

                        />
                        <SvgCircle
                            elProps={{
                                width: "164",
                                height: "172",
                                className: `balachadiStarThree ${showStarThree ? '-active' : ''}`,
                            }}
                            img={require('../../assets/img/buttons/balachadi_3_on.png').default}
                            imgHover={require('../../assets/img/buttons/balachadi_3_off.png').default}
                            nameComic={'BalachadiPopup3'}
                            imgVisited={require('../../assets/img/buttons/balachadi_3_visited.png').default}
                            onClick={() => setShowPopupStarThree(true)}

                        />
                        <SvgCircle
                            elProps={{
                                width: "164",
                                height: "172",
                                className: `balachadiStarFour ${showStarFour ? '-active' : ''}`,
                            }}
                            img={require('../../assets/img/buttons/balachadi_4_on.png').default}
                            imgHover={require('../../assets/img/buttons/balachadi_4_off.png').default}
                            nameComic={'BalachadiPopup4'}
                            imgVisited={require('../../assets/img/buttons/balachadi_4_visited.png').default}
                            onClick={() => setShowPopupStarFour(true)}

                        />
                        <SvgCircle
                            elProps={{
                                width: "164",
                                height: "172",
                                className: `balachadiStarFive ${showStarFive ? '-active' : ''}`,
                            }}
                            img={require('../../assets/img/buttons/balachadi_5_on.png').default}
                            imgHover={require('../../assets/img/buttons/balachadi_5_off.png').default}
                            nameComic={'BalachadiPopup5'}
                            imgVisited={require('../../assets/img/buttons/balachadi_5_visited.png').default}
                            onClick={() => setShowPopupStarFive(true)}

                        />

                    </svg>

                    <WebsiteMapPopup
                        onClick={() => setShowWebsiteMap(false)}
                        visible={showWebsiteMap}
                        className="-mapWebsite"
                        musicStop={musicMapStop}

                    />

                    <SecondPopup
                        onClick={() => setShowPopupInfo(false)}
                        visible={showPopupInfo}
                        className='-balachadi'
                        img={localAssets('img/popup/balachadi/info.png')}
                    >
                    </SecondPopup>

                    <SecondPopup
                        onClick={() => setShowPopupArchive(false)}
                        visible={showPopupArchive}
                        className='-balachadi -archive'
                        img={localAssets('img/popup/balachadi/archiwum.png')}
                    >

                    </SecondPopup>

                    <SecondPopup
                        onClick={() => setShowPopupStarOne(false)}
                        visible={showPopupStarOne}
                        namePopup={'BalachadiPopup1'}
                        className='-balachadi'
                        img={localAssets('img/popup/balachadi/01.png')}
                    >

                    </SecondPopup>

                    <SecondPopup
                        onClick={() => setShowPopupStarTwo(false)}
                        visible={showPopupStarTwo}
                        className='-balachadi'
                        namePopup={'BalachadiPopup2'}
                        img={localAssets('img/popup/balachadi/02.png')}
                    >
                    </SecondPopup>

                    <SecondPopup
                        onClick={() => setShowPopupStarThree(false)}
                        visible={showPopupStarThree}
                        className='-balachadi'
                        namePopup={'BalachadiPopup3'}

                        img={localAssets('img/popup/balachadi/03.png')}
                    >

                    </SecondPopup>

                    <SecondPopup
                        onClick={() => setShowPopupStarFour(false)}
                        visible={showPopupStarFour}
                        className='-balachadi'
                        namePopup={'BalachadiPopup4'}
                        img={localAssets('img/popup/balachadi/04.png')}
                    >
                        <span dangerouslySetInnerHTML={{__html: t('textBalachadiStarFour_1')}}/>
                        <p dangerouslySetInnerHTML={{__html: t('textBalachadiStarFour_2')}}/>
                    </SecondPopup>

                    <PagesPopup
                        onClick={() => setShowPopupStarFive(false)}
                        visible={showPopupStarFive}
                        className='-balachadiPages'
                        namePopup={'BalachadiPopup5'}
                        imgOne={localAssets('img/popup/balachadi/balachadi_5_1.png')}
                        imgTwo={localAssets('img/popup/balachadi/balachadi_5_2.png')}

                    />




                </div>


            </div>


        </>
    )
};
