

export default {
    NAME: 'ComicAmnestyWagony',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/amnesty/3wagony/syberia_07_3wagony_01.jpg',
        },
        {
            img:  'img/comics/amnesty/3wagony/syberia_07_3wagony_02.jpg',
        },
        {
            img:  'img/comics/amnesty/3wagony/syberia_07_3wagony_03.jpg',
        },
        {
            img:  'img/comics/amnesty/3wagony/syberia_07_3wagony_04.jpg',
        },
        {
            img:  'img/comics/amnesty/3wagony/syberia_07_3wagony_05.jpg',
        },
        {
            img:  'img/comics/amnesty/3wagony/syberia_07_3wagony_black.jpg',
            blockNext: true,
        },
        {
            img:  'img/comics/amnesty/3wagony/syberia_07_3wagony_06.jpg',
        },
        {
            img:  'img/comics/amnesty/3wagony/syberia_07_3wagony_black.jpg',
            blockNext: true,
        },
        {
            img:  'img/comics/amnesty/3wagony/syberia_07_3wagony_07.jpg',
        },
        {
            img:  'img/comics/amnesty/3wagony/syberia_07_3wagony_08.jpg',
        },
        {
            img:  'img/comics/amnesty/3wagony/syberia_07_3wagony_09.jpg',
        },
        {
            img:  'img/comics/amnesty/3wagony/syberia_07_3wagony_10.jpg',
        },
        {
            img:  'img/comics/amnesty/3wagony/syberia_07_3wagony_11.jpg',
        },
        {
            img:  'img/comics/amnesty/3wagony/syberia_07_3wagony_12.jpg',
        },
        {
            img:  'img/comics/amnesty/3wagony/syberia_07_3wagony_13.jpg',
        },


    ],
    COMIC_SOUNDS_LIST: [

        {
            id: 1,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtA_MiddayInsects_Loop_01.mp3').default,
            from: 11,
            to: 12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtA_MiddayInsects_Loop_02.mp3').default,
            from: 12,
            to: 13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtA_MiddayInsects_Loop_02.mp3').default,
            from: 14,
            to: 15,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtA_MiddayInsects_Loop_03.mp3').default,
            from: 13,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtA_SteamTrainIdle_Loop_01.mp3').default,
            from: 6,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtA_SteamTrainIdle_Loop_01.mp3').default,
            from: 8,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtA_TrainFreightInside_Loop_01.mp3').default,
            from: 4,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtA_TrainPassBy_Loop_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtA_TrainStationCrowd_Loop_01.mp3').default,
            from: 2,
            to:3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtA_WindBlustering_Loop_01.mp3').default,
            from: 9,
            to:10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtA_WindBlustering_Loop_02.mp3').default,
            from: 10,
            to:11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtB_DesertDay_Loop_01.mp3').default,
            from: 4,
            to:5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtB_DesertDay_Loop_01.mp3').default,
            from: 9,
            to:10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtB_DesertDay_Loop_01.mp3').default,
            from: 10,
            to:11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtB_DesertDay_Loop_01.mp3').default,
            from: 11,
            to:12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtB_DesertDay_Loop_01.mp3').default,
            from: 12,
            to:13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtB_DesertDay_Loop_01.mp3').default,
            from: 13,
            to:14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtB_DesertDay_Loop_02.mp3').default,
            from: 6,
            to:7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },

        {
            id: 19,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtB_DesertWind_Loop_01.mp3').default,
            from: 0,
            to:1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 20,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtB_TrainStationInside_Loop_01.mp3').default,
            from: 2,
            to:3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 21,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtB_TrainStationOutside_Loop_01.mp3').default,
            from: 1,
            to:2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtB_TrainStationOutside_Loop_02.mp3').default,
            from: 3,
            to:4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_AtB_VillageDay_Loop_01.mp3').default,
            from: 14,
            to:15,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 24,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_FxA_CamelAndCow_01.mp3').default,
            from: 14,
            to:15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 25,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_FxA_CamelDistant_01.mp3').default,
            from: 13,
            to:14,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 26,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_FxA_Camels_01.mp3').default,
            from: 12,
            to:13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 27,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_FxA_FreightDoorOpen_01.mp3').default,
            from: 5,
            to:6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: true
        },
        {
            id: 28,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_FxA_FreightDoorOpen_02.mp3').default,
            from: 7,
            to:8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: true
        },
        {
            id: 29,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_FxA_TrainCoupling_01.mp3').default,
            from: 9,
            to:10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 30,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_FxB_ArabShouts_Loop_01.mp3').default,
            from: 12,
            to:13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 31,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_FxB_CrowdCoughs_Loop_01.mp3').default,
            from: 6,
            to:7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 32,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_FxB_CrowdCoughs_Loop_01.mp3').default,
            from: 11,
            to:12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 33,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_FxB_SteamTrainWhistle_01.mp3').default,
            from: 1,
            to:2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 34,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_FxB_ThunderStrikeDistantRoll_01.mp3').default,
            from: 0,
            to:1,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 35,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_Mx_01.mp3').default,
            from: 1,
            to: 15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        /*{
            id: 36,
            file: require('../assets/sounds/comics/amnesty/3wagony/TBB_Syberia_07_Wagony_Mx_02.mp3').default,
            from: 13,
            to:15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
    ],

}
