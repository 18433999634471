import {BasePopup} from "./_index";
import React, {Fragment} from "react";
import Routes from "../../utils/Routes";
import {useHistory, useRouteMatch} from "react-router-dom";
import MapList from "../../utils/MapList";
import {useSelector} from "react-redux";
import {SvgCircle} from "../misc/_index";
import {useLocaleAsset, useNavigation, useTranslation} from "../../misc/Hooks";

// import * as reducer from '../../store/reducer';


interface Props {
    onClick: any,
    className?: any,
    musicStop?: void,
    onShowVideo?: () => void;
}

export const WebsiteMapPopup = (props: Props) => {
    const navigation = useNavigation();

    const history = useHistory()
    const t = useTranslation();
    const match = useRouteMatch();
    const localAssets = useLocaleAsset();
    const store = useSelector(state => ({
        newestMapIndex: state.newestMapIndex,
    }))

    const helperGoTo = (route) => {

        if (props.musicStop) {
            props.musicStop();
        }
        history.push(navigation(route))
        props.onClick()

        if(match.path === route && props.onShowVideo) {
            props.onShowVideo()
        }
    }


    return (

        <BasePopup direction="top" visible={props.visible}>
            <div
                className={`primaryPopup ${props.className}`}
                // onClick={props.onClick}
            >
                <div className="content">
                    <div className={`mapWebsite -active`}>
                        <div className="mapImg">
                            <img src={require('../../assets/img/mapWebsite/background.png').default} alt=""/>
                        </div>
                        <button onClick={props.onClick} className="pagesExit">
                            <img src={require('../../assets/img/buttons/exit.png').default} alt=""/>
                            {t("websiteMap")}
                        </button>
                        <button onClick={() => helperGoTo(Routes.HOME)} className="backToMainPage">
                            {t("backToMainPage")}
                        </button>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" className="mapSvg">
                            <defs>
                            </defs>
                            {MapList.map((map, idx) => {
                                    const isActive = (match.path === map.route) || (match.path === map.alternative_route);
                                    return isActive ? (
                                        <Fragment key={`fragment${idx}`}>
                                            <SvgCircle
                                                elProps={{
                                                    width: "212",
                                                    height: "116",
                                                    transform: map.transform_thumb,
                                                    className: 'test',
                                                }}
                                                img={map.img_off}
                                                imgHover={map.img_on}
                                                onClick={() => helperGoTo(map.route)}
                                            />
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 265 200`}
                                                 x={map.transform_x} y="380" width="212" height="240">
                                                <text x="50%" y="80%" className="mapWebsite__mapName"
                                                      dominantBaseline="middle" textAnchor="middle">
                                                    <tspan x="50%" dy="0">{t(map.name)}</tspan>
                                                    {!!map.name2 && (
                                                        <tspan x="50%" dy="1.2em">{t(map.name2)}</tspan>
                                                    )}
                                                </text>

                                            </svg>
                                            <SvgCircle
                                                elProps={{
                                                    width: "212",
                                                    height: "200",
                                                    transform: map.transform_arrow,
                                                }}
                                                img={localAssets('img/mapWebsite/arrow.png')}
                                                imgHover={localAssets('img/mapWebsite/arrow.png')}
                                            />
                                        </Fragment>
                                    ) : (
                                        <Fragment key={`fragment${idx}`}>
                                        <SvgCircle
                                                elProps={{
                                                    width: "212",
                                                    height: "116",
                                                    transform: map.transform_thumb,
                                                }}
                                                img={map.img_on}
                                                       imgHover={map.img_off}
                                                       onClick={() => helperGoTo(map.route)}
                                            />
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 265 200`}
                                                 x={map.transform_x} y="380" width="212" height="240">
                                                <text x="50%" y="80%" className="mapWebsite__mapName"
                                                      dominantBaseline="middle" textAnchor="middle">
                                                    <tspan x="50%" dy="0">{t(map.name)}</tspan>
                                                    {!!map.name2 && (
                                                        <tspan x="50%" dy="1.2em">{t(map.name2)}</tspan>
                                                    )}
                                                </text>

                                            </svg>
                                        </Fragment>
                                    )
                                }
                            )}
                        </svg>

                    </div>

                </div>
            </div>
        </BasePopup>
    )

}

