

const languages = process.env.REACT_APP_LANGUAGES.split(' ').join('|');
const LANG_PARAM = `/:lang(${languages})?`;


export default {
    LANG_PARAM,

    HOME: LANG_PARAM,
    START: `${LANG_PARAM}/start`,
    INSTRUCTION: `${LANG_PARAM}/instrukcja-dzialania`,
    CREATORS: `${LANG_PARAM}/tworcy`,
    SPONSORS: `${LANG_PARAM}/sponsorzy`,
    FOR_TEACHERS: `${LANG_PARAM}/dla-nauczycieli`,
    RESEARCH: `${LANG_PARAM}/dzial-research`,
    CONTACT: `${LANG_PARAM}/kontakt`,

    MAP_DEPORTATION: `${LANG_PARAM}/deportacja`,
    MAP_BIERIEZNIAKI: `${LANG_PARAM}/bieriezniaki`,
    MAP_AMNESTY: `${LANG_PARAM}/amnestia`,
    MAP_ROAD: `${LANG_PARAM}/droga`,
    MAP_HOUSING_ESTATE: `${LANG_PARAM}/osiedle`,
    VIDEO_HOUSING_ESTATE: `${LANG_PARAM}/film-osiedle`,
    MAP_BALACHADI: `${LANG_PARAM}/balachadi`,
    END_WAR: `${LANG_PARAM}/koniec-wojny`,
    LETTER: `${LANG_PARAM}/list`,
    I_WAS_HERE: `${LANG_PARAM}/tu-bylem`,
    EPILEPSY_WARNING: `${LANG_PARAM}/ostrzezenie-przed-epilepsja`


}
