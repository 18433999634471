

export default {
    NAME: 'ComicRoadJamnagar',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/road/jamnagar/droga_03_Jamnagar_01.jpg',
        },
        {
            img:  'img/comics/road/jamnagar/droga_03_Jamnagar_02.jpg',
        },
        {
            img:  'img/comics/road/jamnagar/droga_03_Jamnagar_03.jpg',
        },
        {
            img:  'img/comics/road/jamnagar/droga_03_Jamnagar_04.jpg',
        },
        {
            img:  'img/comics/road/jamnagar/droga_03_Jamnagar_05.jpg',
        },
        {
            img:  'img/comics/road/jamnagar/droga_03_Jamnagar_06.jpg',
        },
        {
            img:  'img/comics/road/jamnagar/droga_03_Jamnagar_07.jpg',
        },

    ],
    COMIC_SOUNDS_LIST: [

        {
            id: 1,
            file:  require('../assets/sounds/comics/road/jamnagar/TBB_Droga_03_Jamnagar_AtA_RuralDay_Loop_01.mp3').default,
            from: 0,
            to: 0,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file:  require('../assets/sounds/comics/road/jamnagar/TBB_Droga_03_Jamnagar_AtB_WindQuietCrickets_Loop_01.mp3').default,
            from: 1,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file:  require('../assets/sounds/comics/road/jamnagar/TBB_Droga_03_Jamnagar_FxA_TrainApproach_01.mp3').default,
            from: 0,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file:  require('../assets/sounds/comics/road/jamnagar/TBB_Droga_03_Jamnagar_FxB_TrainArrival_01.mp3').default,
            from: 1,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file:  require('../assets/sounds/comics/road/jamnagar/TBB_Droga_04_Jamnagar_Mx_Jamnagar_01.mp3').default,
            from: 1,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
    ],
}
