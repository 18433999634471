import React, {useEffect, useMemo, useRef, useState} from "react";
import {Preloader, SvgCircle} from "../misc/_index";
import {PrimaryPopup, WebsiteMapPopup} from "../popup/_index";
import {ComicBase} from "../comics/_index";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useLocaleAsset, useNavigation, useTranslation} from "../../misc/Hooks";
import ComicBieriezniakiJuchim from "../../utils/ComicBieriezniakiJuchim";
import ComicBieriezniakiMama from "../../utils/ComicBieriezniakiMama";
import ComicBieriezniakiPolak from "../../utils/ComicBieriezniakiPolak";
import ComicBieriezniakiObcy from "../../utils/ComicBieriezniakiObcy";
import Routes from "../../utils/Routes";
import {useDispatch, useSelector} from "react-redux";
import plum from "../../assets/sounds/effects/plum.mp3"
import bieriezniakiSound from "../../assets/sounds/maps/TBB_Mapy_02_Bieriezniaki_01.mp3"
import * as reducer from "../../store/reducer";
import MuteButton from "../misc/MuteButton";




// let soundPlum = null;
// let soundMap = null;

let imgs = null;
let svgsImages = null;

export default (props) => {
    const history = useHistory();
    const t = useTranslation();
    const navigation = useNavigation();
    const localAssets = useLocaleAsset()
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const store = useSelector(state => ({
        soundVolume: state.soundVolume,
    }))

    const soundPlum = useRef(new Audio(plum))
    const soundMap = useRef( new Audio(bieriezniakiSound))


    const [preloader, setPreloader] = useState(true);
    const [preloaderHide, setPreloaderHide] = useState(false);

    const [allImages, setAllImages] = useState(0);
    const [isLoad, setIsLoad] = useState(0);

    const [showWelcome, setShowWelcome] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [starOne, showStarOne] = useState(false);
    const [starTwo, showStarTwo] = useState(false);
    const [starThree, showStarThree] = useState(false);
    const [starFour, showStarFour] = useState(false);

    const [comicJuchim, setComicJuchim] = useState(false);
    const [comicMama, setComicMama] = useState(false);
    const [comicPolak, setComicPolak] = useState(false);
    const [comicObcy, setComicObcy] = useState(false);

    const [showWebsiteMap, setShowWebsiteMap] = useState(false);
    const [showMap, setShowMap] = useState(false)

    useMemo(() => {
        soundPlum.current.volume = store.soundVolume
        soundMap.current.volume = store.soundVolume
    }, [])


    useEffect(() => {

        setShowWelcome(true);
        setShowMap(true)

        imgs = [...document.querySelectorAll('img')];
        svgsImages = [...document.querySelectorAll('image')];
        setAllImages(imgs.length + svgsImages.length - 1)


        imgs.map((img)=> {
            img.addEventListener('load', handleOnLoad )
        })
        svgsImages.map((svgImage)=> {
            svgImage.addEventListener('load', handleOnLoad )
        })
        musicMapStart();

        return () => {
            if (soundMap.current) {
                soundMap.current.pause()
                soundMap.current.currentTime = 0;
            }
          };

        // setTimeout(() => {
        //     setPreloaderHide(true)
        // }, 1800)
        // setTimeout(() => {
        //     setPreloader(false)
        // }, 2100)




    }, [])

    useEffect(()=> {
        soundMap.current.volume = store.soundVolume
        soundPlum.current.volume = (store.soundVolume / 2)
    }, [store.soundVolume])

    let helperIsLoad = 0;

    const handleOnLoad = () => {
        ++helperIsLoad;
        setIsLoad(helperIsLoad);
        if((imgs.length + svgsImages.length - 1) === helperIsLoad){
            setTimeout(()=> {
                setPreloaderHide(true)
            },2000)
            setTimeout(()=> {
                setPreloader(false)
            }, 2300)
        }

    }

    const musicMapStart = () => {
        soundMap.current.volume = store.soundVolume;
        soundMap.current.play();
        soundMap.current.loop = true;
        setTimeout(() => {
            if(store.soundVolume >= .1){
                soundMap.current.volume = .1;
            }
        }, 200)
        setTimeout(() => {
            if(store.soundVolume >= .3){
                soundMap.current.volume = .3;
            }
        }, 400)
        setTimeout(() => {
            if(store.soundVolume >= .5){
                soundMap.current.volume = .5;
            }
        }, 600)
        setTimeout(() => {
            if(store.soundVolume >= .8){
                soundMap.current.volume = .8;
            }
        }, 800)
        setTimeout(() => {
            if(store.soundVolume >= 1){
                soundMap.current.volume = 1;
            }
        }, 1000)
    }
    const musicMapStop = () => {
        setTimeout(() => {
            if(store.soundVolume >= .8){
                soundMap.current.volume = .8;
            }
        }, 200)
        setTimeout(() => {
            if(store.soundVolume >= .6){
                soundMap.current.volume = .6;
            }
        }, 400)
        setTimeout(() => {
            if(store.soundVolume >= .4){
                soundMap.current.volume = .4;
            }
        }, 600)
        setTimeout(() => {
            if(store.soundVolume >= .2){
                soundMap.current.volume = .2;
            }
        }, 800)
        setTimeout(() => {
            if(store.soundVolume >= .1){
                soundMap.current.volume = .1;
            }
            soundMap.current.pause();
        }, 1000)
    }

    const HideWelcome = () => {
        setShowWelcome(false)

        setTimeout(() => {
            showStarOne(true)
            soundPlum.current.play()
        }, 500)
        setTimeout(() => {
            showStarTwo(true)
            soundPlum.current.play()
        }, 900)
        setTimeout(() => {
            showStarThree(true)
            soundPlum.current.play()
        }, 1300)
        setTimeout(() => {
            showStarFour(true)
            soundPlum.current.play()
        }, 1700)
    }

    const handleNextMap = () => {
        setShowMap(false)
        musicMapStop();
        setTimeout(() => {
            history.push(navigation(Routes.MAP_AMNESTY));
        }, 500)
    }

    const handleStartComicJuchim = () => {
        musicMapStop()
            setTimeout(()=> {
                setComicJuchim(true)
            }, 1000)
    }
    const handleStartComicMama = () => {
        musicMapStop()
        setTimeout(()=> {
            setComicMama(true)
        }, 1000)
    }

    const handleStartComicPolak = () => {
        musicMapStop()
        setTimeout(()=> {
            setComicPolak(true)
        }, 1000)
    }

    const handleStartComicObcy = () => {
        musicMapStop()
        setTimeout(()=> {
            setComicObcy(true)
        }, 1000)
    }

    let mapClassName = ''
    if (showMap) {
        mapClassName += ' -active'
    }

    return (
        <>
            {!!preloader && (
                <Preloader
                    hide={preloaderHide}
                    countAll={allImages}
                    countOnLoad={isLoad}
                />
            )}

            <div className="content">

                <div className={`mapWrapper ${mapClassName}`}>

                    <div className="mapImg">
                        <img src={localAssets('img/maps/mapa_bieriezniaki.jpg')} alt=""/>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" className="mapSvg">
                        <defs>
                        </defs>

                        <MuteButton
                            elProps={{}}
                            // handleOnSoundOn={musicMapStart}
                        />
                        <SvgCircle
                            elProps={{
                                width: "66",
                                height: "66",
                                transform: "matrix(1 0 0 1 0 0)",
                            }}
                            img={require('../../assets/img/buttons/map_www_on.png').default}
                            imgHover={require('../../assets/img/buttons/map_www_off.png').default}
                            onClick={() => setShowWebsiteMap(true)}

                        />
                        <SvgCircle
                            elProps={{
                                width: "126",
                                height: "116",
                                transform: "matrix(1 0 0 1 20 955)",

                            }}
                            img={require('../../assets/img/buttons/info_on.png').default}
                            imgHover={require('../../assets/img/buttons/info_off.png').default}
                            onClick={() => setShowInfo(true)}
                        />

                        <SvgCircle
                            elProps={{
                                width: "136",
                                height: "136",
                                transform: "matrix(1 0 0 1 1780 0)",

                            }}
                            img={require('../../assets/img/buttons/forward_on.png').default}
                            imgHover={require('../../assets/img/buttons/forward_off.png').default}
                            onClick={() => handleNextMap()}
                        />


                        <SvgCircle
                            elProps={{
                                width: "152",
                                height: "134",
                                className: `bieriezniakiStarOne ${starOne ? '-active' : ''}`,
                            }}
                            img={require('../../assets/img/buttons/star_1_off.png').default}
                            imgHover={require('../../assets/img/buttons/star_1_on.png').default}
                            imgVisited={require('../../assets/img/buttons/star_1_visited.png').default}
                            nameComic={'ComicBierezniakiJuchim'}
                            onClick={handleStartComicJuchim}
                        />

                        <SvgCircle
                            elProps={{
                                width: "152",
                                height: "134",
                                className: `bieriezniakiStarTwo ${starTwo ? '-active' : ''}`,

                            }}
                            img={require('../../assets/img/buttons/star_2_off.png').default}
                            imgHover={require('../../assets/img/buttons/star_2_on.png').default}
                            imgVisited={require('../../assets/img/buttons/star_2_visited.png').default}
                            nameComic={'ComicBieriezniakiMama'}
                            onClick={handleStartComicMama}
                        />

                        <SvgCircle
                            elProps={{
                                width: "152",
                                height: "134",
                                className: `bieriezniakiStarThree ${starThree ? '-active' : ''}`,
                            }}
                            img={require('../../assets/img/buttons/star_3_off.png').default}
                            imgHover={require('../../assets/img/buttons/star_3_on.png').default}
                            imgVisited={require('../../assets/img/buttons/star_3_visited.png').default}
                            nameComic={'ComicBieriezniakiPolak'}
                            onClick={handleStartComicPolak}
                        />

                        <SvgCircle
                            elProps={{
                                width: "152",
                                height: "134",
                                className: `bieriezniakiStarFour ${starFour ? '-active' : ''}`,

                            }}
                            img={require('../../assets/img/buttons/star_4_off.png').default}
                            imgHover={require('../../assets/img/buttons/star_4_on.png').default}
                            imgVisited={require('../../assets/img/buttons/star_4_visited.png').default}
                            nameComic={'ComicBieriezniakiObcy'}
                            onClick={handleStartComicObcy}
                        />

                    </svg>


                    <PrimaryPopup
                        onClick={HideWelcome}
                        visible={showWelcome}
                        className='-bierieziaki'
                        img={localAssets('img/popup/bieriezniaki/bieriezniaki_wstep.png')}
                    >
                        <p dangerouslySetInnerHTML={{__html: t('textWelcomeBieriezniaki')}}/>
                    </PrimaryPopup>

                    <PrimaryPopup
                        onClick={() => setShowInfo(false)}
                        visible={showInfo}
                        className='-bierieziakiInfo'
                        img={localAssets('img/popup/bieriezniaki/bieriezniaki_info.png')}

                    >
                        <p dangerouslySetInnerHTML={{__html: t('textInfoBieriezniaki')}}/>
                    </PrimaryPopup>


                    {!!comicJuchim && (
                        <ComicBase
                            visible={isVisible => setComicJuchim(isVisible)}
                            file={ComicBieriezniakiJuchim}
                            musicStart={musicMapStart}
                        />
                    )}
                    {!!comicMama && (
                        <ComicBase
                            visible={isVisible => setComicMama(isVisible)}
                            file={ComicBieriezniakiMama}
                            musicStart={musicMapStart}
                        />
                    )}
                    {!!comicPolak && (
                        <ComicBase
                            visible={isVisible => setComicPolak(isVisible)}
                            file={ComicBieriezniakiPolak}
                            musicStart={musicMapStart}
                        />
                    )}
                    {!!comicObcy && (
                        <ComicBase
                            visible={isVisible => setComicObcy(isVisible)}
                            file={ComicBieriezniakiObcy}
                            musicStart={musicMapStart}
                            functionNextMap={handleNextMap}
                            nextMap="true"
                        />
                    )}

                    <WebsiteMapPopup
                        onClick={() => setShowWebsiteMap(false)}
                        visible={showWebsiteMap}
                        className="-mapWebsite"
                        musicStop={musicMapStop}

                    />


                </div>


            </div>
        </>

    )
}
