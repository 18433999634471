import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useActions, useLocaleAsset, useNavigation, useTranslation} from "../misc/Hooks";
import Routes from "../utils/Routes";
import {Preloader} from "./misc/_index";


export default (props) => {

    const t = useTranslation();
    const localAssets = useLocaleAsset();
    const [creators, setCreators] = useState([]);

    const actions = useActions()


    const navigation = useNavigation();
    const [preloader, setPreloader] = useState(true);
    const [preloaderHide, setPreloaderHide] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setPreloaderHide(true)
        }, 300)
        setTimeout(() => {
            setPreloader(false)
        }, 600)

        actions.getCreators().then(res => {
            setCreators(res);
        })
            .catch(error => {
                console.error(error)
            })
    }, [])

    return (
        <>
            {!!preloader && (
                <Preloader hide={preloaderHide}/>
            )}
            <div className="contact">

                <Link to={navigation(Routes.HOME)} className="pagesExit">
                    <img src={require('../assets/img/buttons/exit.png').default} alt=""/>
                    {t("creators")}
                </Link>

                <div className="container">

                    <div className="contact__items">

                        {creators.map((item, idx) => item.name ? (
                                <div className={`contact__item ${(!item.margin) ? '-noMargin' : ''}`} key={idx}>
                                    <span className="contact__left" dangerouslySetInnerHTML={{__html: item.name}}/>
                                    <span className="contact__right" dangerouslySetInnerHTML={{__html: item.value}}/>
                                </div>
                            ) : (
                            <div className={`contact__item ${(!item.margin) ? '-noMargin' : ''}`} key={idx}>
                                <span className="contact__text" dangerouslySetInnerHTML={{__html: item.text}}/>
                            </div>
                            )
                        )}

                    </div>


                </div>

            </div>

        </>

    )
}
