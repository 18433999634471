

export default {
    NAME: 'ComicAmnestyOficer',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/amnesty/oficer/syberia_06_Oficer_01.jpg',
        },
        {
            img:  'img/comics/amnesty/oficer/syberia_06_Oficer_02.jpg',
        },
        {
            img:  'img/comics/amnesty/oficer/syberia_06_Oficer_03.jpg',
        },
        {
            img:  'img/comics/amnesty/oficer/syberia_06_Oficer_04.jpg',
        },
        {
            img:  'img/comics/amnesty/oficer/syberia_06_Oficer_05.jpg',
        },
        {
            img:  'img/comics/amnesty/oficer/syberia_06_Oficer_06.jpg',
        },
        {
            img:  'img/comics/amnesty/oficer/syberia_06_Oficer_07.jpg',
        },
        {
            img:  'img/comics/amnesty/oficer/syberia_06_Oficer_08.jpg',
        },
        {
            img:  'img/comics/amnesty/oficer/syberia_06_Oficer_09.jpg',
        },
        {
            img:  'img/comics/amnesty/oficer/syberia_06_Oficer_10.jpg',
        },
        {
            img:  'img/comics/amnesty/oficer/syberia_06_Oficer_11.jpg',
        },
        {
            img:  'img/comics/amnesty/oficer/syberia_06_Oficer_12.jpg',
        },
        {
            img:  'img/comics/amnesty/oficer/syberia_06_Oficer_13.jpg',
        },
        {
            img:  'img/comics/amnesty/oficer/syberia_06_Oficer_14.jpg',
        },
        {
            img:  'img/comics/amnesty/oficer/syberia_06_Oficer_15.jpg',
        },


    ],
    COMIC_SOUNDS_LIST: [
        {
            id: 1,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtA_TrainBoxcarSteady_Loop_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtA_TrainBoxcarSteady_Loop_01.mp3').default,
            from: 4,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtA_TrainBoxcarSteady_Loop_01.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtA_TrainBoxcarSteady_Loop_01.mp3').default,
            from: 10,
            to: 11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtA_TrainBoxcarSteady_Loop_01.mp3').default,
            from: 13,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtA_TrainBoxcarSteady_Loop_02.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtA_TrainBoxcarSteady_Loop_02.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtA_TrainBoxcarSteady_Loop_02.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtA_TrainBoxcarSteady_Loop_02.mp3').default,
            from: 11,
            to: 12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtA_TrainBoxcarSteady_Loop_02.mp3').default,
            from: 14,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtA_TrainBoxcarSteady_Loop_03.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtA_TrainBoxcarSteady_Loop_03.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtA_TrainBoxcarSteady_Loop_03.mp3').default,
            from: 9,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtA_TrainBoxcarSteady_Loop_03.mp3').default,
            from: 12,
            to: 13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtB_StationWind_Loop_01.mp3').default,
            from: 14,
            to: 15,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtB_TrainCoastingInt_Loop_01.mp3').default,
            from: 0,
            to: 1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtB_TrainCoastingInt_Loop_02.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtB_TrainCoastingInt_Loop_02.mp3').default,
            from: 12,
            to: 13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 19,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtB_TrainCoastingInt_Loop_03.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 20,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtB_TrainCoastingInt_Loop_03.mp3').default,
            from: 13,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 21,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtB_TrainCoastingInt_Loop_04.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtB_TrainCoastingInt_Loop_04.mp3').default,
            from: 4,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtB_TrainCoastingInt_Loop_04.mp3').default,
            from: 10,
            to: 11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 24,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtB_TrainCoastingInt_Loop_04.mp3').default,
            from: 11,
            to: 12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 25,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtB_TrainCoastingInt_Loop_05.mp3').default,
            from: 5,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 26,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtB_TrainCoastingInt_Loop_05.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 27,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtB_TrainCoastingInt_Loop_06.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 28,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtB_TrainCoastingInt_Loop_06.mp3').default,
            from: 9,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 29,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_AtB_TrainCoastingInt_Loop_07.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 30,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_FxB_CarDoorOpen_01.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 31,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_FxB_TrainDepartStation_01.mp3').default,
            from: 14,
            to: 15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 32,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_Mx_Akcent_01.mp3').default,
            from: 3,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: true,
        },
        {
            id: 33,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_Mx_Akcent_02.mp3').default,
            from: 7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 34,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_Mx_Akcent_03.wav').default,
            from: 10,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 35,
            file: require('../assets/sounds/comics/amnesty/oficer/TBB_Syberia_06_Oficer_FxB_VO_Ciii_01.wav').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
    ],
}
