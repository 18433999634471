import React, {useEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import Routes from "../utils/Routes";
import {Preloader, SvgCircle} from "./misc/_index";
import {Navigation} from "./nav/_index";
import {useLocaleAsset, useNavigation, useTranslation} from "../misc/Hooks";
import Button from "./misc/Button";
import RotateDevice from "./RotateDevice";

let effect = null;
let imgs = null;
let svgsImages = null;

export default (props) => {
    const localeAsset = useLocaleAsset();
    const match = useRouteMatch();
    const lang = match.params.lang ?? process.env.REACT_APP_DEFAULT_LANG;

    const history = useHistory();
    const t = useTranslation();
    const navigation = useNavigation();
    const [hovered, setHovered] = useState(false);
    const [preloader, setPreloader] = useState(true);
    const [preloaderHide, setPreloaderHide] = useState(false);
    const [infoStartState, setInfoStartState] = useState(true)
    const [exitInfoStart, setExitInfoStart] = useState(false)
    const [allImages, setAllImages] = useState(0);
    const [isLoad, setIsLoad] = useState(0);

    useEffect(() => {
        imgs = [...document.querySelectorAll('img')];
        svgsImages = [...document.querySelectorAll('image')];
        setAllImages(imgs.length + svgsImages.length - 1)

        imgs.map((img) => {
            // img.onload = handleOnLoad
            img.addEventListener('load', handleOnLoad)
        })
        svgsImages.map((svgImage) => {
            svgImage.addEventListener('load', handleOnLoad)
        })

    }, [])

    function start() {

        // const elem = document.documentElement;
        //
        // if (elem.requestFullscreen) {
        //     elem.requestFullscreen();
        // } else if (elem.webkitRequestFullscreen) { /* Safari */
        //     elem.webkitRequestFullscreen();
        // } else if (elem.msRequestFullscreen) { /* IE11 */
        //     elem.msRequestFullscreen();
        // }
        history.push(navigation(Routes.EPILEPSY_WARNING))
    }

    let helperIsLoad = 0;
    const handleOnLoad = () => {
        // const imgs = [ ...document.querySelectorAll('img')];
        //
        // setIsLoad(prev => prev + 1);
        // imgs.forEach((img) => console.log(img.complete === undefined ? img : ''))
        // if (!imgs.some(x => x.complete === false)) {

        ++helperIsLoad;
        setIsLoad(helperIsLoad);
        if ((imgs.length + svgsImages.length - 1) === helperIsLoad) {
            setTimeout(() => {
                setPreloaderHide(true)
            }, 2000)
            setTimeout(() => {
                setPreloader(false)
            }, 2300)
        }
    }

    const langsTranslations = {
        pl: {
            pl: "Polski",
            en: "English",
            hi: "हिन्दी",
            gu: "ગુજરાતી",
        },
        en: {
            pl: "Polski",
            en: "English",
            hi: "हिन्दी",
            gu: "ગુજરાતી",
        },
        gu: {
            pl: "Polski",
            en: "English",
            hi: "હિન્દી",
            gu: "ગુજરાતી",
        },
        hi: {
            pl: "Polski",
            en: "English",
            hi: "हिन्दी",
            gu: "गुजराती",
        }
    }

    return (
        <>
            {!!preloader && (
                <Preloader
                    hide={preloaderHide}
                    countAll={allImages}
                    countOnLoad={isLoad}
                />
            )}

            <div className="content -blue">

                <div className={`mapWrapper -active`}>

                    <div className="mapImg">
                        <img src={localeAsset('img/start/bg_home.png')} alt=""/>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" className="mapSvg">
                        <defs>
                        </defs>

                        <SvgCircle
                            elProps={{
                                width: "150",
                                height: "75",
                                transform: "matrix(1 0 0 1 886 560)",
                            }}
                            img={localeAsset('img/buttons/button.png')}
                            imgHover={localeAsset('img/buttons/button.png')}
                            onClick={start}
                        />
                    </svg>

                    {process.env.REACT_APP_DEFAULT_LANG === 'pl' && (
                        <div className="navigationStart">
                            <div className="navigationStart__lang">
                                <a href="/">{langsTranslations[lang].pl}</a> / <a
                                href="/en">{langsTranslations[lang].en}</a> / <a
                                href="/hi">{langsTranslations[lang].hi}</a> / <a
                                href="/gu">{langsTranslations[lang].gu}</a>
                            </div>
                        </div>
                    )}

                    <Navigation/>
                    <div className="logoFixed">
                        <Button
                            img={'img/start/lions.png'}
                            imgHover={'img/start/lions_hover.png'}
                            link={t('linkNilDesperandum')}
                        />
                    </div>
                </div>

            </div>

        </>


    );
}

