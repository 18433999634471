

export default {
    NAME: 'ComicHousingEstateCiemnia',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_3_04_Ciemnia/szlak_3_04_Ciemnia_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_04_Ciemnia/szlak_3_04_Ciemnia_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_04_Ciemnia/szlak_3_04_Ciemnia_03.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_04_Ciemnia/szlak_3_04_Ciemnia_04.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_04_Ciemnia/szlak_3_04_Ciemnia_05.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_04_Ciemnia/szlak_3_04_Ciemnia_06.jpg',
        },



    ],
    COMIC_SOUNDS_LIST: [
      {
          id: 1,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_AtA_Desert_01.mp3').default,
          from: 0,
          to: 1,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 2,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_AtA_RuralDay_01.mp3').default,
          from: 1,
          to: 2,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 3,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_AtA_RuralDay_01.mp3').default,
          from: 2,
          to: 3,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 4,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_AtA_RuralDay_01.mp3').default,
          from: 3,
          to: 4,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 5,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_AtA_RuralDay_01.mp3').default,
          from: 4,
          to: 5,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 6,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_AtA_RuralDay_01.mp3').default,
          from: 5,
          to: 6,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 7,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_FxA_Water_01.mp3').default,
          from: 5,
          to: 6,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 8,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_FxB_Camera_01.mp3').default,
          from: 0,
          to: 1,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 9,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_FxB_Camera_02.mp3').default,
          from: 1,
          to: 2,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 10,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_FxB_Camera_03.mp3').default,
          from: 2,
          to: 3,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 11,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_Mx_01_Magiczny_Akcent.mp3').default,
          from: 5,
          to: 6,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 12,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_VO_Presence_Loop_01.mp3').default,
          from: 1,
          to: 2,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 13,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_VO_Presence_Loop_02.mp3').default,
          from: 2,
          to: 3,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 14,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_VO_Presence_Loop_03.mp3').default,
          from: 3,
          to: 4,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 15,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_FxB_Camera_04.mp3').default,
          from: 3,
          to: 4,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 16,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_FxB_Camera_05.mp3').default,
          from: 4,
          to: 5,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 17,
          file: require('../assets/sounds/comics/housing-estate/ciemnia/TBB_Szlak3_04_Ciemnia_FxB_Camera_06.mp3').default,
          from: 5,
          to: 6,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
    ],
}
