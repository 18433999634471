import React from "react";


interface Props {
    visible: boolean,
    direction: "top" | "right" | "bottom" | "left",
}


export default (props : Props) => {
   if (!props.direction)
       props.direction = "top";
return (

    <div className={`popup ${props.visible ? '-active' : ''} -${props.direction}`}>
        {props.children}
    </div>

)

}
