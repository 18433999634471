

export default {
    NAME: 'ComicHousingEstateTragedia',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_03.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_04.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_05.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_06.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_07.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_08.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_09.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_10.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_11.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_12.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_13.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_14.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_15.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_16.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_17.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_18.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_19.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_20.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_21.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_22.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_23.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_24.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_03_Tragedia/szlak_1_03_Tragedia_25.jpg',
        },



    ],
    COMIC_SOUNDS_LIST: [
         {
             id: 1,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtA_Clock_01.mp3').default,
             from: 23,
             to: 24,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 2,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtA_Crowd_01.mp3').default,
             from: 2,
             to: 3,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 3,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtA_Crowd_02.mp3').default,
             from: 13,
             to: 14,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 4,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtA_Crowd_03.mp3').default,
             from: 14,
             to: 15,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 5,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtA_Crowd_04.mp3').default,
             from: 16,
             to: 17,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 6,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtA_Dancing_01.mp3').default,
             from: 7,
             to: 8,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 7,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtA_Heartbeats_04.mp3').default,
             from: 15,
             to: 16,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 8,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtA_RuralDay_01.mp3').default,
             from: 3,
             to: 4,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 9,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtA_RuralDay_02.mp3').default,
             from: 5,
             to: 6,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 10,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtA_RuralDay_03.mp3').default,
             from: 22,
             to: 23,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 11,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtA_RuralDay_04.mp3').default,
             from: 24,
             to: 25,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 12,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtA_Windy_01.mp3').default,
             from: 0,
             to: 1,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 13,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_01.mp3').default,
             from: 1,
             to: 3,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 15,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_02.mp3').default,
             from: 4,
             to: 5,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 16,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_03.mp3').default,
             from: 6,
             to: 7,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 17,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_03.mp3').default,
             from: 7,
             to: 8,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 18,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_03.mp3').default,
             from: 8,
             to: 9,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 19,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_03.mp3').default,
             from: 9,
             to: 10,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 20,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_03.mp3').default,
             from: 10,
             to: 11,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 21,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_03.mp3').default,
             from: 11,
             to: 12,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 22,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_04.mp3').default,
             from: 13,
             to: 14,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 23,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_04.mp3').default,
             from: 14,
             to: 15,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 24,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_04.mp3').default,
             from: 15,
             to: 16,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 25,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_04.mp3').default,
             from: 16,
             to: 17,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 26,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_04.mp3').default,
             from: 17,
             to: 18,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 27,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_04.mp3').default,
             from: 18,
             to: 19,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 28,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_04.mp3').default,
             from: 19,
             to: 20,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 29,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Desert_05.mp3').default,
             from: 21,
             to: 23,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 30,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_AtB_Insects_01.mp3').default,
             from: 23,
             to: 24,
             loop: true,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 31,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_FxA_Chalkboard_01.mp3').default,
             from: 13,
             to: 14,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 32,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_FxA_Children_01.mp3').default,
             from: 7,
             to: 8,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 33,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_FxA_Children_02.mp3').default,
             from: 8,
             to: 9,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 34,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_FxA_Flag_01.mp3').default,
             from: 3,
             to: 4,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 35,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_FxA_Vinyl_01.mp3').default,
             from: 6,
             to: 7,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 36,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_FxA_Wolves_01.mp3').default,
             from: 24,
             to: 25,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 37,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_FxA_Wood_01.mp3').default,
             from: 2,
             to: 3,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 38,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_FxA_Wood_02.mp3').default,
             from: 0,
             to: 1,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 39,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_FxB_Clothes_01.mp3').default,
             from: 4,
             to: 5,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 40,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_FxB_Coughs_02.mp3').default,
             from: 18,
             to: 19,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 41,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_FxB_Coughs_03.mp3').default,
             from: 19,
             to: 20,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 42,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_FxB_Coughs_04.mp3').default,
             from: 17,
             to: 18,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 43,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_FxB_Door_01.mp3').default,
             from: 1,
             to: 2,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 44,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_FxB_Footsteps_01.mp3').default,
             from: 16,
             to: 17,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 45,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_FxB_Peacock_01.mp3').default,
             from: 3,
             to: 4,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 46,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_Mx_01_Tragedia_01.mp3').default,
             from: 6,
             to: 12,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 47,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_Mx_02_Akcent_01_List.mp3').default,
             from: 12,
             to: 13,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 48,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_Mx_03_Akcent_02_Kartka_W_Zeszycie.mp3').default,
             from: 14,
             to: 15,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 49,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_Mx_04_Tragedia_02.mp3').default,
             from: 16,
             to: 21,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 50,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_Mx_05_Akcent_03.mp3').default,
             from: 20,
             to: 21,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 51,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_VO_Cough_01.mp3').default,
             from: 18,
             to: 19,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 52,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_VO_Laugh_Loop_01.mp3').default,
             from: 8,
             to: 9,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 53,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_VO_Presence_Loop_01.mp3').default,
             from: 1,
             to: 3,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 54,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_VO_Presence_Loop_01.mp3').default,
             from: 13,
             to: 15,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 55,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_VO_Presence_Loop_01.mp3').default,
             from: 15,
             to: 17,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 56,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_VO_Wolves_01.mp3').default,
             from: 0,
             to: 1,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
         {
             id: 57,
             file: require('../assets/sounds/comics/housing-estate/tragedia/TBB_Szlak1_03_Tragedia_VO_Wolves_02.mp3').default,
             from: 24,
             to: 25,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
    ],
}
