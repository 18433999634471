

export default {
    NAME: 'ComicHousingEstateWyscig',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_03.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_04.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_05.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_06.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_07.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_08.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_09.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_10.jpg',
            blockNext: true,
            nextAfterTime: 1500,
        },
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_11.jpg',
            blockNext: true,
            nextAfterTime: 3000,
        },
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_12.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_13.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_14.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_15.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_05_Wyscig/szlak_3_05_Wyscig_16.jpg',
        },

    ],
    COMIC_SOUNDS_LIST: [
      {
          id: 1,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_Bicycle_01.mp3').default,
          from: 5,
          to: 6,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 2,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_01.mp3').default,
          from: 0,
          to: 1,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 3,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_02.mp3').default,
          from: 1,
          to: 2,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 4,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_03.mp3').default,
          from: 2,
          to: 3,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 5,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_04.mp3').default,
          from: 3,
          to: 4,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 6,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_05.mp3').default,
          from: 4,
          to: 5,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 7,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_06.mp3').default,
          from: 5,
          to: 6,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 8,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_06.mp3').default,
          from: 6,
          to: 7,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 9,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_06.mp3').default,
          from: 7,
          to: 8,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 10,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_06.mp3').default,
          from: 8,
          to: 9,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 11,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_06.mp3').default,
          from: 9,
          to: 10,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 12,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_06.mp3').default,
          from: 10,
          to: 11,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 13,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_06.mp3').default,
          from: 11,
          to: 12,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 14,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_06.mp3').default,
          from: 12,
          to: 13,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 15,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_06.mp3').default,
          from: 13,
          to: 14,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 16,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_06.mp3').default,
          from: 14,
          to: 15,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 17,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_06.mp3').default,
          from: 15,
          to: 16,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 18,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_07.mp3').default,
          from: 12,
          to: 14,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 19,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtA_City_07.mp3').default,
          from: 14,
          to: 16,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 20,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtB_Children_01.mp3').default,
          from: 5,
          to: 6,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 21,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtB_City_01.mp3').default,
          from: 1,
          to: 2,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 22,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtB_City_02.mp3').default,
          from: 2,
          to: 4,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 23,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtB_City_03.mp3').default,
          from: 3,
          to: 4,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 24,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_AtB_City_04.mp3').default,
          from: 5,
          to: 7,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 25,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_FxA_Bicycle_01.mp3').default,
          from: 1,
          to: 3,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 26,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_FxA_Bicycle_02.mp3').default,
          from: 4,
          to: 5,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 27,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_FxA_Bicycle_03.mp3').default,
          from: 6,
          to: 7,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 28,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_FxA_Bicycle_04.mp3').default,
          from: 7,
          to: 8,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 29,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_FxA_Bicycle_05.mp3').default,
          from: 8,
          to: 9,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 30,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_FxA_Gravel_01.mp3').default,
          from: 9,
          to: 11,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 31,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_FxA_Gravel_02.mp3').default,
          from: 11,
          to: 12,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 32,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_FxA_Horns_01.mp3').default,
          from: 5,
          to: 6,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 33,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_FxA_Volvo_01.mp3').default,
          from: 2,
          to: 5,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 34,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_FxB_Animals_01.mp3').default,
          from: 2,
          to: 3,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 35,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_FxB_Animals_02.mp3').default,
          from: 4,
          to: 5,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 36,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_FxB_City_01.mp3').default,
          from: 1,
          to: 2,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 37,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_FxB_Impact_01.mp3').default,
          from: 11,
          to: 12,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 38,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_Mx_01_Indyjska_Ulica.mp3').default,
          from: 0,
          to: 5,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 39,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_Mx_02_Wyscig_02.mp3').default,
          from: 5,
          to: 10,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 40,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_Mx_03_Zgrzyt_Akcent_Muzyczny.mp3').default,
          from: 9,
          to: 11,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 41,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_Mx_04_Smutek_Melancholia.mp3').default,
          from: 12,
          to: 13,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 42,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_Mx_05_Akcent_Groza.mp3').default,
          from: 13,
          to: 14,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 43,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_Mx_06_Smutek_Melancholia_01_i_02.mp3').default,
          from: 14,
          to: 16,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 44,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_VO_Breathing_Loop_01.mp3').default,
          from: 0,
          to: 1,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 45,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_VO_Breathing_Loop_02.mp3').default,
          from: 5,
          to: 6,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 46,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_VO_Breathing_Loop_03.mp3').default,
          from: 7,
          to: 8,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 47,
          file: require('../assets/sounds/comics/housing-estate/wyscig/TBB_Szlak3_05_Wyscig_VO_Scream_01.mp3').default,
          from: 8,
          to: 9,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
    ],
}
