

export default {
    NAME: 'ComicHousingEstateRiki',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_3_02_Riki/szlak_3_02_Riki_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_02_Riki/szlak_3_02_Riki_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_02_Riki/szlak_3_02_Riki_03.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_02_Riki/szlak_3_02_Riki_04.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_02_Riki/szlak_3_02_Riki_05.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_02_Riki/szlak_3_02_Riki_06.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_02_Riki/szlak_3_02_Riki_07.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_02_Riki/szlak_3_02_Riki_08.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_02_Riki/szlak_3_02_Riki_09.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_02_Riki/szlak_3_02_Riki_10.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_02_Riki/szlak_3_02_Riki_11.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_02_Riki/szlak_3_02_Riki_12.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_02_Riki/szlak_3_02_Riki_13.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_02_Riki/szlak_3_02_Riki_14.jpg',
        },



    ],
    COMIC_SOUNDS_LIST: [
        {
            id: 1,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_VO_Children_03.mp3').default,
            from: 13,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtA_Birds_01.mp3').default,
            from: 8,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtA_Desert_01.mp3').default,
            from: 0,
            to: 1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtA_Leaves_01.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtA_RuralDay_01.mp3').default,
            from: 1,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtA_RuralDay_02.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtA_RuralDay_02.mp3').default,
            from: 4,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtA_RuralDay_02.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtA_RuralDay_02.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtA_RuralDay_02.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtA_RuralDay_02.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtA_RuralDay_02.mp3').default,
            from: 9,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtA_RuralDay_02.mp3').default,
            from: 10,
            to: 11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtA_RuralDay_02.mp3').default,
            from: 11,
            to: 12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtA_RuralDay_02.mp3').default,
            from: 12,
            to: 13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtA_RuralDay_02.mp3').default,
            from: 13,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtB_Cicadas_01.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtB_Cicadas_01.mp3').default,
            from: 4,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 19,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtB_Cicadas_01.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 20,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtB_Cicadas_01.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 21,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtB_Cicadas_01.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtB_Cicadas_01.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtB_Cicadas_01.mp3').default,
            from: 9,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 24,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtB_Cicadas_01.mp3').default,
            from: 10,
            to: 11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 25,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtB_Cicadas_01.mp3').default,
            from: 11,
            to: 12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 26,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtB_Cicadas_01.mp3').default,
            from: 12,
            to: 13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 27,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_AtB_Cicadas_01.mp3').default,
            from: 13,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 28,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_FxA_Clothes_01.mp3').default,
            from: 3,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 29,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_FxA_Mud_01.mp3').default,
            from: 1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 30,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_FxA_Mud_02.mp3').default,
            from: 2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 31,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_FxB_Squirrel_01.mp3').default,
            from: 10,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 32,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_FxB_Bush_01.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 33,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_FxB_Bush_02.mp3').default,
            from: 7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 34,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_FxB_Bush_03.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 35,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_FxB_Door_01.mp3').default,
            from: 2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 36,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_FxB_Door_02.mp3').default,
            from: 13,
            to: 14,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 37,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_FxB_Gun_01.mp3').default,
            from: 1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 38,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_FxB_Gun_02.mp3').default,
            from: 9,
            to: 10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 39,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_FxB_Rustle_01.mp3').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 40,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_FxA_Squirrel_01.mp3').default,
            from: 0,
            to: 1,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 41,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_FxB_Squirrel_02.mp3').default,
            from: 12,
            to: 13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 42,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_FxB_Squirrel_03.mp3').default,
            from: 17,
            to: 18,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 43,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_Mx_01_Akcent_01.mp3').default,
            from: 1,
            to: 2,
            loop: false,
            delay: 150,
            volume: 1,
            onEndSound: false
        },
        {
            id: 44,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_Mx_02_Riki_03.mp3').default,
            from: 3,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 45,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_Mx_03_Riki_04_Walka.mp3').default,
            from: 7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 46,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_Mx_04_Riki_06_Akcent_Niepokoj.mp3').default,
            from: 9,
            to: 10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 47,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_Mx_05_Riki_07_Akcent_Zwycieski.mp3').default,
            from: 10,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 48,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_Mx_06_Riki_07_Brawo_Riki.mp3').default,
            from: 11,
            to: 14,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 49,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_VO_Children_01.mp3').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 50,
            file: require('../assets/sounds/comics/housing-estate/riki/TBB_Szlak3_02_Riki_VO_Children_02.mp3').default,
            from: 11,
            to: 12,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
    ],
}
