import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import reducer from "./reducer";


export const initialState = {
    newestMapIndex: 0,
    soundVolume: 1,
    infoStart: {
        value:1,
        expirationDate: 0
    },
    visitedComics: [],
    lastVisitedMap: ''
}


const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2
}


const persistedReducer = persistReducer(persistConfig, reducer)

export const store = createStore(persistedReducer, applyMiddleware(thunk))
export const persistor = persistStore(store)
