import {Router} from 'react-router-dom';
// import Navigation from "./misc/Navigation";
import Modal from 'react-modal';
import {store, persistor} from './store/store';
import '../app/assets/style/style.css';
import React, {useEffect} from "react";
import {PersistGate} from "redux-persist/integration/react";
import {Provider, useDispatch, useSelector} from "react-redux";
import {CustomNavigation} from "./misc/_index";
import * as reducer from "./store/reducer";




Modal.setAppElement('#root')


function App() {

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then((registrations) => {
                for (const registration of registrations) {
                    registration.update();
                }
            });
        }
    }, []);


    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>


                <CustomNavigation/>



            </PersistGate>
        </Provider>
    );
}

export default App;
