import soundOffIcon from "../../assets/img/buttons/volume-mute.svg";
import soundOnIcon from "../../assets/img/buttons/volume-on.svg";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setVolumeAudio} from "../../store/reducer";

export default function MuteButton({handleOnSoundOn, black = false, elProps = null}){
    const {soundVolume} = useSelector(state => ({
        soundVolume: state.soundVolume,
    }))
    const dispatch = useDispatch()
    const [lastSoundVolume, setLastSoundVolume] = useState(soundVolume)

    const handleMuting = () => {
        if(!(Math.abs(soundVolume) < Number.EPSILON)) {
            setLastSoundVolume(soundVolume)
            dispatch(setVolumeAudio(0.0))
        } else {
            if(!(Math.abs(lastSoundVolume) < Number.EPSILON)){
                dispatch(setVolumeAudio(lastSoundVolume))
            } else {
                dispatch(setVolumeAudio(0.5))
            }

            if(typeof handleOnSoundOn === "function"){
                handleOnSoundOn()
            }
        }
    }

    if(!!elProps) {
        return (
            <image
                xlinkHref={!(Math.abs(soundVolume) < Number.EPSILON) ? soundOnIcon : soundOffIcon}
                width={60}
                height={60}
                style={black ? {} : {filter: 'brightness(9)'}}
                onClick={handleMuting}
                transform="matrix(1 0 0 1 1840 1000)"
                {...elProps}
            />
        )
    }

    return (
        <button className="muteButton">
            <img
                src={!(Math.abs(soundVolume) < Number.EPSILON) ? soundOnIcon : soundOffIcon}
                alt="mute"
                width={35}
                height={35}
                style={black ? {} : {filter: 'brightness(9)'}}
                onClick={handleMuting}
            />
        </button>
    )
}

