

export default {
    NAME: 'ComicHousingEstatePrezent',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_3_03_Prezent/szlak_3_03_Prezent_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_03_Prezent/szlak_3_03_Prezent_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_03_Prezent/szlak_3_03_Prezent_03.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_03_Prezent/szlak_3_03_Prezent_04.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_03_Prezent/szlak_3_03_Prezent_05.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_03_Prezent/szlak_3_03_Prezent_06.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_03_Prezent/szlak_3_03_Prezent_07.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_03_Prezent/szlak_3_03_Prezent_08.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_03_Prezent/szlak_3_03_Prezent_09.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_03_Prezent/szlak_3_03_Prezent_10.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_03_Prezent/szlak_3_03_Prezent_11.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_03_Prezent/szlak_3_03_Prezent_12.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_03_Prezent/szlak_3_03_Prezent_13.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_03_Prezent/szlak_3_03_Prezent_14.jpg',
        },




    ],
    COMIC_SOUNDS_LIST: [
        {
            id: 1,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_AtA_Cicadas_01.mp3').default,
            from: 9,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_AtA_DesertArea_01.mp3').default,
            from: 0,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_AtA_DesertRurualDay_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_AtA_DesertRurualDay_01.mp3').default,
            from: 3,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_AtA_DesertRurualDaywithKids_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_AtA_DistantOceanandCicadas_01.mp3').default,
            from: 10,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_AtA_SunnyDayNeartheOcean_01.mp3').default,
            from: 9,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_FxA_DoorOpening_01.mp3').default,
            from: 1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_FxA_OpeningBox_01.mp3').default,
            from: 11,
            to: 12,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_FxA_Shining_01.mp3').default,
            from: 2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_FxA_Shining_02.mp3').default,
            from: 3,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_FxA_Shining_03.mp3').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_FxA_Shining_04.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_FxA_Shining_05.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_FxA_Shovel_01.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_FxA_Steps_01.mp3').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_FxA_Steps_02.mp3').default,
            from: 7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_VO_PlayingChildren_Loop_01.mp3').default,
            from: 2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },

        {
            id: 19,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_Mx_01_Bukowski.mp3').default,
            from: 0,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 20,
            file: require('../assets/sounds/comics/housing-estate/prezent/TBB_Szlak_03_Prezent_Mx_02_Emocje.mp3').default,
            from: 10,
            to: 14,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },





    ],
}
