

export default {
    NAME: 'ComicHousingEstateBoles',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_4_01_Boles/szlak_4_01_Boles_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_4_01_Boles/szlak_4_01_Boles_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_4_01_Boles/szlak_4_01_Boles_03.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_4_01_Boles/szlak_4_01_Boles_04.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_4_01_Boles/szlak_4_01_Boles_05.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_4_01_Boles/szlak_4_01_Boles_06.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_4_01_Boles/szlak_4_01_Boles_07.jpg',
        },




    ],
    COMIC_SOUNDS_LIST: [

        {
            id: 1,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_AtA_BarrackAtNight_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_AtA_BarrackAtNight_02.mp3').default,
            from: 3,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_AtA_CloseToTheLake_01.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_AtA_DesertDay_01.mp3').default,
            from: 0,
            to: 1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_AtA_DesertRuralDay_01.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_AtA_WindyNight_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_AtB_Cicadas_01.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_AtB_GustyWind_01.mp3').default,
            from: 2,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_FxA_ForestNight_01.mp3').default,
            from: 2,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_FxA_Rock_01.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_FxA_ScaryEffects_01.mp3').default,
            from: 2,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_FxA_Wolves_01.mp3').default,
            from: 1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_FxA_Wolves_02.mp3').default,
            from: 2,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_FxA_Wolves_03.mp3').default,
            from: 4,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_FxB_Wood_01.mp3').default,
            from: 2,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_FxB_Wood_02.mp3').default,
            from: 3,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_VO_ChildrenCrying_Loop_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_VO_ChildrenCrying_Loop_02.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 19,
            file: require('../assets/sounds/comics/housing-estate/boles/TBB_Szlak_04_Boles_Mx_01_Boles.mp3').default,
            from: 0,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
    ],
}
