

export default {
    NAME: 'ComicAmnestyDetdom',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/amnesty/detdom/syberia_10_Detdom_01.jpg',
        },
        {
            img:  'img/comics/amnesty/detdom/syberia_10_Detdom_02.jpg',
        },
        {
            img:  'img/comics/amnesty/detdom/syberia_10_Detdom_03.jpg',
        },
        {
            img:  'img/comics/amnesty/detdom/syberia_10_Detdom_04.jpg',
        },
        {
            img:  'img/comics/amnesty/detdom/syberia_10_Detdom_05.jpg',
        },
        {
            img:  'img/comics/amnesty/detdom/syberia_10_Detdom_06.jpg',
        },
        {
            img:  'img/comics/amnesty/detdom/syberia_10_Detdom_07.jpg',
        },
        {
            img:  'img/comics/amnesty/detdom/syberia_10_Detdom_08.jpg',
        },
        {
            img:  'img/comics/amnesty/detdom/syberia_10_Detdom_09.jpg',
        },
        {
            img:  'img/comics/amnesty/detdom/syberia_10_Detdom_10.jpg',
        },
        {
            img:  'img/comics/amnesty/detdom/syberia_10_Detdom_11.jpg',
        },
        {
            img:  'img/comics/amnesty/detdom/syberia_10_Detdom_12.jpg',
        },
        {
            img:  'img/comics/amnesty/detdom/syberia_10_Detdom_13.jpg',
        },
        {
            img:  'img/comics/amnesty/detdom/syberia_10_Detdom_14.jpg',
        },


    ],
    COMIC_SOUNDS_LIST: [

        {
            id: 1,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_AtA_WindCornfield_Loop_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_AtA_WindCornfield_Loop_02.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_AtA_WindCornfield_Loop_02.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_AtA_WindErraticHowling_Loop_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_AtB_DesertTemperate_Loop_01.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_AtB_DesertTemperate_Loop_01.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_AtB_DesertTemperate_Loop_01.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_AtB_DesertTemperate_Loop_01.mp3').default,
            from: 12,
            to: 13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_AtB_DesertTemperate_Loop_02.mp3').default,
            from: 13,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_AtB_WindAbandonedHouse_Loop_01.mp3').default,
            from: 4,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_AtB_WindAbandonedHouse_Loop_02.mp3').default,
            from: 2,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_AtB_WindBlustery_Loop_01.mp3').default,
            from: 0,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_FxA_ChildrenCoughs_02.mp3').default,
            from: 9,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_FxA_FootstepsDirt_01.mp3').default,
            from: 7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_FxA_HorseCarriage_01.mp3').default,
            from: 12,
            to: 13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_FxA_Rats_01.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_FxA_VO_Breaths_01.mp3').default,
            from: 10,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_FxB_BarrackWoodCreaks_Loop_01.mp3').default,
            from: 2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 19,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_FxB_BarrackWoodCreaks_Loop_01.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 20,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_FxB_BarrackWoodCreaks_Loop_01.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 21,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_FxB_BarrackWoodCreaks_Loop_01.mp3').default,
            from: 10,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_FxB_Camel_01.mp3').default,
            from: 12,
            to: 13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_FxB_CamelCarriage_01.mp3').default,
            from: 13,
            to: 14,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 24,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_FxB_InsectsFlySwarm_01.mp3').default,
            from: 7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 25,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_FxB_InsectsFlySwarm_01.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 26,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_FxB_SoupPour_01.mp3').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 27,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_Mx_Akcent_01.mp3').default,
            from: 2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 28,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_Mx_Akcent_02.mp3').default,
            from: 3,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 29,
            file: require('../assets/sounds/comics/amnesty/detdom/TBB_Syberia_07_DietDom_VO_Wiesio_01.mp3').default,
            from: 11,
            to: 12,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
    ],
}
