import {PopupVideo, Preloader, PrimaryVideo, SvgCircle} from "../../misc/_index";
import React, {useEffect, useState} from "react";
import {PrimaryPopup, WebsiteMapPopup} from "../../popup/_index";
import {HousingEstateNav} from "../../nav/_index";
import {Route, useHistory} from "react-router-dom";
import Routes from "../../../utils/Routes";
import archiveVideo from "../../../assets/video/granica.mp4"
import {useLocaleAsset, useNavigation, useTranslation} from "../../../misc/Hooks";
import SecondPopup from "../../popup/SecondPopup";
import {ComicBase} from "../../comics/ComicBase";
import ComicHousingEstateZieloneMalpy from "../../../utils/ComicHousingEstateZieloneMalpy";
import ComicHousingEstateBarak15 from "../../../utils/ComicHousingEstateBarak15";
import ComicHousingEstatePojedynek from "../../../utils/ComicHousingEstatePojedynek";
import ComicHousingEstateTragedia from "../../../utils/ComicHousingEstateTragedia";
import koniecWojny from "../../../assets/video/koniecWojny.mp4";
import plum from "../../../assets/sounds/effects/plum.mp3";
import {useSelector} from "react-redux";
import MuteButton from "../../misc/MuteButton";
let soundPlum = null;


interface Props {
    onClickNavBraveBrunch: void,
    onClickNavLifeInTheEstate: void,
    onClickNight: void,
    onClickWonderfulYears: void,

    onClickSun: void,
    onClickArchive: void,
    onClickInfo: void,

    welcomeWasDisplayed: boolean,

    musicMapOn: void,
    musicMapOff: void,
}

export default (props: Props) => {

    const history = useHistory();
    const t = useTranslation();
    const navigation = useNavigation();
    const localePath = useLocaleAsset();

    const store = useSelector(state=>({
        soundVolume: state.soundVolume,
    }))

    const [preloader, setPreloader] = useState(false);
    const [preloaderHide, setPreloaderHide] = useState(false);

    const [showInfo, setShowInfo] = useState(false)
    const [showHideInfo, setShowHideInfo] = useState(false)
    const [showWebsiteMap, setShowWebsiteMap] = useState(false)
    const [showArchive, setShowArchive] = useState(false)

    const [starOne, showStarOne] = useState(false);
    const [starTwo, showStarTwo] = useState(false);
    const [starThree, showStarThree] = useState(false);
    const [starFour, showStarFour] = useState(false);

    const [comicZieloneMalpy, setComicZieloneMalpy] = useState(false);
    const [comicBarak, setComicBarak] = useState(false);
    const [comicPojedynek, setComicPojedynek] = useState(false);
    const [comicTragedia, setComicTragedia] = useState(false);
    const [showMap, setShowMap] = useState(false)

    const [animationEndWar, setAnimationEndWar] = useState(false)


    useEffect(() => {
        soundPlum = new Audio(plum)
        soundPlum.volume = (store.soundVolume/2)

            setTimeout(() => {
                showStarOne(true)
                soundPlum.play()
            }, 1000)
            setTimeout(() => {
                showStarTwo(true)
                soundPlum.play()
            }, 1400)
            setTimeout(() => {
                showStarThree(true)
                soundPlum.play()
            }, 1800)
            setTimeout(() => {
                showStarFour(true)
                soundPlum.play()
            }, 2200)
        setTimeout(() => {
            setShowMap(true)
        }, 500)
    }, [])

    useEffect(()=> {
        soundPlum.volume = store.soundVolume
    }, [store.soundVolume])

    const handleNextMap = () => {
        setShowMap(false)
        props.musicMapOff();
        setTimeout(() => {
            history.push(navigation(Routes.END_WAR));

        }, 500)
    }

    const handleExtraMap = () => {
        setShowMap(false)
        props.musicMapOff();
        setTimeout(() => {
            history.push(navigation(Routes.MAP_BALACHADI));
        }, 500)
    }

    const handleShowInfo = () => {

        setShowInfo(true)

        setTimeout(() => {
            setShowHideInfo(true)
        }, 100)

    }

    const handleHideInfo = () => {

        setShowHideInfo(false)

        setTimeout(() => {
            setShowInfo(false)
        }, 400)

    }

    const handleStartComicZieloneMalpy = () => {
        props.musicMapOff()
        setTimeout(()=> {
            setComicZieloneMalpy(true)
        }, 1000)
    }
    const handleStartComicPojedynek = () => {
        props.musicMapOff()
        setTimeout(()=> {
            setComicPojedynek(true)
        }, 1000)
    }
    const handleStartComicTragedia = () => {
        props.musicMapOff()
        setTimeout(()=> {
            setComicTragedia(true)
        }, 1000)
    }
    const handleStartComicBarak = () => {
        props.musicMapOff()
        setTimeout(()=> {
            setComicBarak(true)
        }, 1000)
    }

    // const handleShowWebsiteMap = () => {
    //     setShowWebsiteMap(true)
    // }

    let mapClassName = ''
    if (showMap) {
        mapClassName += ' -active'
    }

    let infoClassName = ''
    if (showHideInfo) {
        infoClassName += ' -active'
    }




    return (
        <>
            {!!preloader && (
                <Preloader hide={preloaderHide}/>
            )}

                <div className="content">

                    <div
                        className={`mapWrapper ${mapClassName}`}
                    >

                        <div className="mapImg">
                            <img src={localePath('img/maps/mapa_osiedle_dzien.jpg')} alt=""/>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" className="mapSvg">
                            <defs>
                            </defs>

                            <MuteButton
                               elProps={{}}
                               black
                            />
                            <SvgCircle
                                elProps={{
                                    width: "216",
                                    height: "50",
                                    transform: "matrix(1 0 0 1 225 827)",
                                }}
                                img={localePath('img/buttons/housing-estate/camp_day_fajnaferajna_off.png')}
                                imgHover={localePath('img/buttons/housing-estate/camp_day_fajnaferajna_off.png')}
                                onClick={props.onClickNavBraveBrunch}
                            />

                            <SvgCircle
                                elProps={{
                                    width: "216",
                                    height: "56",
                                    transform: "matrix(1 0 0 1 225 770)",
                                }}
                                img={localePath('img/buttons/housing-estate/camp_day_zyciewosiedlu_off.png')}
                                imgHover={localePath('img/buttons/housing-estate/camp_day_zyciewosiedlu_on.png')}
                                onClick={props.onClickNavLifeInTheEstate}
                            />

                            <SvgCircle
                                elProps={{
                                    width: "216",
                                    height: "50",
                                    transform: "matrix(1 0 0 1 225 875)",
                                }}
                                img={localePath('img/buttons/housing-estate/camp_day_cudownelata_off.png')}
                                imgHover={localePath('img/buttons/housing-estate/camp_day_cudownelata_on.png')}
                                onClick={props.onClickWonderfulYears}
                            />

                            <SvgCircle
                                elProps={{
                                    width: "216",
                                    height: "56",
                                    transform: "matrix(1 0 0 1 225 920)",
                                }}
                                img={localePath('img/buttons/housing-estate/camp_day_noc_off.png')}
                                imgHover={localePath('img/buttons/housing-estate/camp_day_noc_on.png')}
                                onClick={props.onClickNight}
                            />

                            <HousingEstateNav
                                onClickInfo={handleShowInfo}
                                onClickSun={handleExtraMap}
                                // onClickMapWww={handleShowWebsiteMap}
                                onClickMapWww={() => setShowWebsiteMap(true)}
                                onClickArchive={() => setShowArchive(true)}
                                onClickForward={handleNextMap}
                            />

                            {!!showInfo && (
                                <SvgCircle
                                    elProps={{
                                        width: "1920",
                                        height: "1080",
                                        className: `housingEstateInfo ${infoClassName}`
                                    }}
                                    img={localePath('img/popup/housing-estate/camp_map_legend.png')}
                                    imgHover={localePath('img/popup/housing-estate/camp_map_legend.png')}
                                    onClick={handleHideInfo}
                                />
                            )}

                            <SvgCircle
                                elProps={{
                                    width: "134",
                                    height: "134",
                                    className: `braveBunchStarOne ${starOne ? '-active' : ''}`,
                                }}
                                img={require('../../../assets/img/buttons/camp_1_on.png').default}
                                imgHover={require('../../../assets/img/buttons/camp_1_off.png').default}
                                nameComic={'ComicHousingEstateBarak'}
                                imgVisited={require('../../../assets/img/buttons/camp_1_visited.png').default}
                                onClick={handleStartComicBarak}
                            />
                            <SvgCircle
                                elProps={{
                                    width: "134",
                                    height: "134",
                                    className: `braveBunchStarTwo ${starTwo ? '-active' : ''}`,
                                }}
                                img={require('../../../assets/img/buttons/camp_2_on.png').default}
                                imgHover={require('../../../assets/img/buttons/camp_2_off.png').default}
                                nameComic={'ComicHousingEstatePojedynek'}
                                imgVisited={require('../../../assets/img/buttons/camp_2_visited.png').default}
                                onClick={handleStartComicPojedynek}
                            />
                            <SvgCircle
                                elProps={{
                                    width: "134",
                                    height: "134",
                                    className: `braveBunchStarThree ${starThree ? '-active' : ''}`,
                                }}
                                img={require('../../../assets/img/buttons/camp_3_on.png').default}
                                imgHover={require('../../../assets/img/buttons/camp_3_off.png').default}
                                nameComic={'ComicHousingEstateTragedia'}
                                imgVisited={require('../../../assets/img/buttons/camp_3_visited.png').default}
                                onClick={handleStartComicTragedia}
                            />
                            <SvgCircle
                                elProps={{
                                    width: "134",
                                    height: "134",
                                    className: `braveBunchStarFour ${starFour ? '-active' : ''}`,
                                }}
                                img={require('../../../assets/img/buttons/camp_4_on.png').default}
                                imgHover={require('../../../assets/img/buttons/camp_4_off.png').default}
                                nameComic={'ComicHousingEstateZieloneMalpy'}
                                imgVisited={require('../../../assets/img/buttons/camp_4_visited.png').default}
                                onClick={handleStartComicZieloneMalpy}
                            />

                        </svg>


                        <WebsiteMapPopup
                            onClick={() => setShowWebsiteMap(false)}
                            visible={showWebsiteMap}
                            className="-mapWebsite"
                            musicStop={props.musicMapOff}
                        />

                        {!!showArchive && (
                            <PopupVideo
                                video={archiveVideo}
                                onEnded={() => setShowArchive(false)}
                                class={"-housingEstateArchiveVideo"}
                                onSkip={() => setShowArchive(false)}
                            />
                        )}

                        {!!comicZieloneMalpy && (
                            <ComicBase
                                visible={isVisible => setComicZieloneMalpy(isVisible)}
                                file={ComicHousingEstateZieloneMalpy}
                                musicStart={props.musicMapOn}
                            />
                        )}
                        {!!comicBarak && (
                            <ComicBase
                                visible={isVisible => setComicBarak(isVisible)}
                                file={ComicHousingEstateBarak15}
                                musicStart={props.musicMapOn}
                            />
                        )}
                        {!!comicPojedynek && (
                            <ComicBase
                                visible={isVisible => setComicPojedynek(isVisible)}
                                file={ComicHousingEstatePojedynek}
                                musicStart={props.musicMapOn}
                            />
                        )}
                        {!!comicTragedia && (
                            <ComicBase
                                visible={isVisible => setComicTragedia(isVisible)}
                                file={ComicHousingEstateTragedia}
                                musicStart={props.musicMapOn}
                            />
                        )}
                    </div>

                </div>


        </>

    )
}