

export default {
    NAME: 'ComicHousingEstateManiak',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_2_02_Maniak/szlak_2_02_Maniak_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_02_Maniak/szlak_2_02_Maniak_02.jpg',
        },

        {
            img:  'img/comics/housing-estate/szlak_2_02_Maniak/szlak_2_02_Maniak_03.jpg',
        },

        {
            img:  'img/comics/housing-estate/szlak_2_02_Maniak/szlak_2_02_Maniak_04.jpg',
        },

        {
            img:  'img/comics/housing-estate/szlak_2_02_Maniak/szlak_2_02_Maniak_05.jpg',
        },

        {
            img:  'img/comics/housing-estate/szlak_2_02_Maniak/szlak_2_02_Maniak_06.jpg',
        },



    ],
    COMIC_SOUNDS_LIST: [
      {
          id: 1,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak_02_Maniak_Mx_01_Pojedynek_01.mp3').default,
          from: 0,
          to: 6,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 2,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak2_02_Maniak_AtA_Cicadas_01.mp3').default,
          from: 3,
          to: 4,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 3,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak2_02_Maniak_AtA_Desert_01.mp3').default,
          from: 0,
          to: 1,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 4,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak2_02_Maniak_AtA_Froggy Day_01.mp3').default,
          from: 4,
          to: 5,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 5,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak2_02_Maniak_AtA_RuralDay_01.mp3').default,
          from: 1,
          to: 2,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 6,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak2_02_Maniak_AtA_RuralDay_01.mp3').default,
          from: 2,
          to: 3,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 7,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak2_02_Maniak_AtA_RuralDay_01.mp3').default,
          from: 3,
          to: 4,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 8,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak2_02_Maniak_AtA_RuralDay_01.mp3').default,
          from: 5,
          to: 6,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 9,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak2_02_Maniak_FxA_Applause_01.mp3').default,
          from: 5,
          to: 6,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 10,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak2_02_Maniak_FxA_Football_01.mp3').default,
          from: 0,
          to: 1,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 11,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak2_02_Maniak_FxA_Movement_01.mp3').default,
          from: 3,
          to: 4,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 12,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak2_02_Maniak_FxA_Whistle_01.mp3').default,
          from: 1,
          to: 2,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 13,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak2_02_Maniak_FxA_Whistle_02.mp3').default,
          from: 2,
          to: 3,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 14,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak2_02_Maniak_FxA_Whoosh_01.mp3').default,
          from: 4,
          to: 5,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 15,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak2_02_Maniak_VO_Effort_01.mp3').default,
          from: 3,
          to: 4,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 16,
          file: require('../assets/sounds/comics/housing-estate/maniak/TBB_Szlak2_02_Maniak_VO_Effort_Loop_01.mp3').default,
          from: 3,
          to: 4,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
    ],
}
