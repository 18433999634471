import React, {useEffect, useState} from "react";
import {useNavigation, useTranslation} from "../misc/Hooks";
import Button from "./misc/Button";
import Routes from "../utils/Routes";
import {useHistory} from "react-router-dom";
import {Preloader} from "./misc/_index";

export default function EpilepsyWarning() {
    const history = useHistory();
    const t = useTranslation();
    const navigation = useNavigation();

    const [imgIsLoad, setImgIsLoad] = useState(true);
    const [preloader, setPreloader] = useState(true);
    const [preloaderHide, setPreloaderHide] = useState(false);

    const start = () => {
        const elem = document.documentElement;

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }

        history.push(navigation(Routes.START))
    }

    const handleOnLoad = () => {
        setImgIsLoad(true)
        setTimeout(() => {
            setPreloaderHide(true);
        }, 2000);
        setTimeout(() => {
            console.log('delete')
            setPreloader(false);
        }, 2300);
    };


    return (

        <>
            {preloader && (
                <Preloader
                    hide={preloaderHide}
                    countAll={1}
                    countOnLoad={imgIsLoad ? 1 : 0}
                />
            )}

            <div className="content">
                <div className={`mapWrapper -active -epilepsy`}>

                    <div className="mapImg">
                        <img src={require('../assets/img/other/epilepsy.png').default} alt="" onLoad={handleOnLoad}/>
                        <div className="epilepsy">
                            <h1 className="epilepsy__title"
                                dangerouslySetInnerHTML={{__html: t('epilepsy_warning_title')}}/>
                            <p className="epilepsy__text"
                               dangerouslySetInnerHTML={{__html: t('epilepsy_warning_desc')}}/>

                            <button className="epilepsyNextButton" onClick={start}>
                                {t('epilepsy_next')}
                            </button>


                        </div>
                        <div className="epilepsy__exit">
                            <Button
                                img={"img/buttons/close-icon.png"}
                                imgHover={"img/buttons/close-icon-hover.png"}
                                onClick={() => history.push(navigation(Routes.HOME))}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}