import React, {Fragment, useEffect, useRef, useState} from "react";
import {ComicItem} from "./ComicItem";
import {ComicSound} from "./ComicSound";
import {PreloaderComic} from "../misc/_index";
import {useDispatch, useSelector} from "react-redux";
import * as reducer from "../../store/reducer";
import MuteButton from "../misc/MuteButton";


let nextMap = null;
let showButton = null;
let images = null;

interface Props {
    file: any,
}


// let COMIC_IMAGES_LIST = null;
// let COMIC_SOUNDS_LIST = null;


export const ComicWrapper = (props: Props) => {

    const dispatch = useDispatch()
    const store = useSelector(state => ({
        visitedComics: state.visitedComics,
    }))


    const nextRef = useRef(null);
    const prevRef = useRef(null);
    const [imageIndex, setImageIndex] = useState(0);
    const [sounds, setSounds] = useState(getSoundsByIndex(0));
    const [end, setEnd] = useState(false);
    const [startNextSlide, setStartNextSlide] = useState(false);
    const [isLoad, setIsLoad] = useState(0);
    const [lastClickButton, setLastClickButton] = useState('right')

    const sendFilesToCache = () => {
        if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
            const filesToCache = [
                ...props.file.COMIC_SOUNDS_LIST.map(item => item.file)
            ];

            return new Promise((resolve) => {
                navigator.serviceWorker.controller.postMessage({
                    type: 'CACHE_URLS',
                    urls: [...new Set(filesToCache)],
                });

                navigator.serviceWorker.addEventListener('message', (event) => {
                    if (event.data.type === 'CACHE_COMPLETE') {
                        resolve();
                    }
                });
            });
        }
    };

    const loadAllSounds = async () => {
        const soundList = props.file.COMIC_SOUNDS_LIST;
        for (const sound of soundList) {
            await loadSound(sound);
        }
    };

    const loadSound = (sound) => {
        return new Promise(async (resolve) => {
            const cachedAudio = await caches.match(sound.file);
            let audio;
            if (cachedAudio) {
                // console.log('loadSound - hasCache: ', sound.file)
                const response = await cachedAudio.blob();
                audio = new Audio(URL.createObjectURL(response));
            } else {
                // console.warn('loadSound - noCache: ', sound.file)

                audio = new Audio(sound.file);
            }

            audio.muted = true;
            audio.preload = 'auto';
            audio.load();

            audio.oncanplaythrough = () => {
                handleOnLoad();
                audio.muted = false;
                resolve();
            };

            audio.onerror = () => {
                console.error(`Error loading sound: ${sound.file}`);
                handleOnLoad();
                resolve();
            };
        });
    };



    useEffect(() => {
        const loadSoundsAndSetupListeners = async () => {
            sendFilesToCache();

            await new Promise(resolve => setTimeout(resolve, 100)); // krótka przerwa

            await loadAllSounds();

            document.addEventListener('keydown', handleListenKeyDown);
            images = [...document.querySelector('.comicWrapper').querySelectorAll('img')];
        };

        loadSoundsAndSetupListeners().then(r => {});

        return () => {
            document.removeEventListener('keydown', handleListenKeyDown);
        };
    }, []);

    useEffect(() => {
        if(lastClickButton === 'right'){
            if (props.file.COMIC_IMAGES_LIST[imageIndex].nextAfterTime){
                setTimeout(()=> {
                    handleNextAuto()
                },props.file.COMIC_IMAGES_LIST[imageIndex].nextAfterTime )
            }
        }
    }, [imageIndex])


    function getSoundsByIndex(idx) {
        return props.file.COMIC_SOUNDS_LIST.filter((s) => ((idx >= s.from) && (idx < s.to)));
    }


    const handleListenKeyDown = (e) => {

        switch (e.key) {
            case "ArrowRight": {
                nextRef?.current?.click()
                break;
            }
            case "ArrowLeft": {
                prevRef?.current?.click()
                break;
            }
        }
    }


    const handleNextAuto = () => {

        if (props.file.COMIC_IMAGES_LIST[imageIndex]) {
            if(props.file.COMIC_IMAGES_LIST[imageIndex].delayNextSlide){
                setTimeout(()=> {
                    handleChangeSlide(imageIndex + 1)
                },props.file.COMIC_IMAGES_LIST[imageIndex].delayNextSlide )
            }
            else {
                handleChangeSlide(imageIndex + 1)
            }
        } else {
            handleEnd()
        }
    }
    const handleNext = () => {
        setLastClickButton('right')
        if (props.file.COMIC_IMAGES_LIST[imageIndex + 1]) {
            if ((props.file.COMIC_IMAGES_LIST[imageIndex].blockNext === undefined) || (props.file.COMIC_IMAGES_LIST[imageIndex].blockNext === false)) {
                if ((props.file.COMIC_IMAGES_LIST[imageIndex].fadeOut) && (props.file.COMIC_IMAGES_LIST[imageIndex].fadeOut !== undefined)) {
                    setStartNextSlide(true)
                    setTimeout(() => {
                        handleChangeSlide(imageIndex + 1)
                        setStartNextSlide(false)

                    }, 500)
                } else {
                    handleChangeSlide(imageIndex + 1, true)
                }
            }
        } else {
            if (props.afterEnd){
                props.afterEnd()
            }
            handleEnd()
        }

    }

    const handleOnlyClose = () => {
        setEnd(true)
        setTimeout(() => {
            props.visible(false)
            props.musicStart();
        }, 550)
    }

    const handlePrev = () => {
        setLastClickButton('left')
        if (props.file.COMIC_IMAGES_LIST[imageIndex - 1]) {
            handleChangeSlide(imageIndex - 1)
        }
    }

    if (typeof (props.nextMap) == 'undefined') {
        nextMap = false;
    } else {
        nextMap = props.nextMap;
    }
    if ((typeof (props.exitButton) == 'undefined') || (props.exitButton === true)) {
        showButton = true;
    } else {
        showButton = false;
    }


    const handleEnd = () => {

        const arr = [...(store.visitedComics??[])]

        const idx = arr.findIndex(i => i === props.file.NAME)
        if (idx === -1){

            arr.push(props.file.NAME)
            dispatch(reducer.setVisitedComics(arr))
        }

        if (!nextMap) {
            setEnd(true)
            setTimeout(() => {
                props.visible(false)
                props.musicStart();
            }, 550)
        } else {
            setEnd(true)
            setTimeout(() => {
                props.functionNextMap();
            })
        }
    }

    const handleChangeSlide = (idx, next = false) => {
        setImageIndex(idx)
        const arr = [...getSoundsByIndex(idx)];
        setSounds(arr)
    }

    const handleOnLoad = () => {
        setIsLoad((prev) => {
            return prev + 1
        })
    }

    return (

        <>
            <PreloaderComic
                countAll={
                    props.file.COMIC_IMAGES_LIST.concat(props.file.COMIC_SOUNDS_LIST)
                }

                countOnLoad={isLoad}
            />
            {/*<div className={`comicWrapper ${end ? '-end' : ''}`} {...handlers}>*/}
            <div className={`comicWrapper ${end ? '-end' : ''}`}>

                <div>
                    {/*<button ref={nextRef} onClick={handleNext} hidden/>*/}
                    <button className="comicButton -prev" ref={prevRef} onClick={handlePrev}>PREV</button>
                    <button className="comicButton -next" ref={nextRef} onClick={handleNext}>NEXT</button>
                </div>
                {!!showButton && (
                    <img src={require('../../assets/img/buttons/exit.png').default} alt=""
                         className="comicWrapper__exit" onClick={handleOnlyClose}/>
                )}


                {/*<div>*/}
                {/*    step: {imageIndex}*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    img: {COMIC_IMAGES_LIST[imageIndex].img}*/}



                {props.file.COMIC_IMAGES_LIST.map((itemImage, key) => {
                    return (
                        <ComicItem
                            image={itemImage.img}
                            fade={itemImage.fade}
                            fadeOnlyEnd={itemImage.fadeOnlyEnd}
                            currentIndex={imageIndex}
                            imageList={props.file.COMIC_IMAGES_LIST}
                            startNextSlide={startNextSlide}
                            idx={key}
                            onLoad = {handleOnLoad}
                            key={key}
                        />
                    )
                })}

                {props.file.COMIC_IMAGES_LIST.concat(props.file.COMIC_SOUNDS_LIST).length <= (isLoad + 1) && (
                    <Fragment>
                        {sounds.map((s, idx) => (
                            <ComicSound
                                idx={idx}
                                item={s}
                                nextSlide={() => handleNextAuto()}
                                key={s.id}
                            />
                        ))}
                    </Fragment>
                )}


            </div>
            <MuteButton
                // handleOnSoundOn={musicMapStart}
            />
        </>
    )
}

export class Comic {
}
