import React, {useEffect, useMemo, useRef, useState} from "react";
import {Preloader, PrimaryVideo, SvgCircle, SvgCircleRelative} from "../misc/_index";
import {PrimaryPopup, SecondPopup, WebsiteMapPopup} from "../popup/_index";
import {useLocaleAsset, useNavigation, useTranslation} from "../../misc/Hooks";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import MapList from "../../utils/MapList";
import * as reducer from "../../store/reducer";

import banian from "../../assets/video/tuBylem/banian.mp4"
import kozy from "../../assets/video/tuBylem/kozy.mp4"
import krakowiak from "../../assets/video/tuBylem/krakowiak.mp4"
import mecz from "../../assets/video/tuBylem/mecz.mp4"
import morze from "../../assets/video/tuBylem/morze.mp4"
import ognisko from "../../assets/video/tuBylem/ognisko.mp4"
import palac from "../../assets/video/tuBylem/palac.mp4"
import pawiepiora from "../../assets/video/tuBylem/pawiepiora.mp4"
import wyscig from "../../assets/video/tuBylem/wyscig.mp4"
import ziemia from "../../assets/video/tuBylem/ziemia.mp4"
import Routes from "../../utils/Routes";
import plum from "../../assets/sounds/effects/plum.mp3";
import MuteButton from "../misc/MuteButton";


let imgs = null;
let svgsImages = null;

export default (props) => {
    const history = useHistory();
    const t = useTranslation();
    const dispatch = useDispatch();
    const localAssets = useLocaleAsset()
    const store = useSelector(state=>({
        newestMapIndex: state.newestMapIndex,
        soundVolume: state.soundVolume,

    }))
    const match = useRouteMatch();
    const navigation = useNavigation();

    const [preloader, setPreloader] = useState(true);
    const [preloaderHide, setPreloaderHide] = useState(false);

    const [showWelcome, setShowWelcome] = useState(false);
    const [showMap, setShowMap] = useState(false)
    const [showWebsiteMap, setShowWebsiteMap] = useState(false);

    const [showButtonPlayOne, setShowButtonPlayOne] = useState(false);
    const [showButtonPlayTwo, setShowButtonPlayTwo] = useState(false);
    const [showButtonPlayThree, setShowButtonPlayThree] = useState(false);
    const [showButtonPlayFour, setShowButtonPlayFour] = useState(false);
    const [showButtonPlayFive, setShowButtonPlayFive] = useState(false);
    const [showButtonPlaySix, setShowButtonPlaySix] = useState(false);
    const [showButtonPlaySeven, setShowButtonPlaySeven] = useState(false);
    const [showButtonPlayEight, setShowButtonPlayEight] = useState(false);
    const [showButtonPlayNine, setShowButtonPlayNine] = useState(false);
    const [showButtonPlayTen, setShowButtonPlayTen] = useState(false);


    const [showAnimationBanian, setShowAnimationBanian] = useState(false);
    const [showAnimationKozy, setShowAnimationKozy] = useState(false);
    const [showAnimationMecz, setShowAnimationMecz] = useState(false);
    const [showAnimationZiemia, setShowAnimationZiemia] = useState(false);
    const [showAnimationMorze, setShowAnimationMorze] = useState(false);
    const [showAnimationKrakowiak, setShowAnimationKrakowiak] = useState(false);
    const [showAnimationPawiePiora, setShowAnimationPawiePiora] = useState(false);
    const [showAnimationWyscig, setShowAnimationWyscig] = useState(false);
    const [showAnimationPalac, setShowAnimationPalac] = useState(false);
    const [showAnimationOgnisko, setShowAnimationOgnisko] = useState(false);
    const [allImages, setAllImages] = useState(0);
    const [isLoad, setIsLoad] = useState(0);
    const handleNextMap = () => {
        setShowMap(false)
        setTimeout(() => {
            history.push(navigation(Routes.HOME));
        }, 500)
    }

    const soundPlum = useRef(new Audio(plum))
    useMemo(() => {
        soundPlum.current.volume = store.soundVolume
    }, []);
    useEffect(() => {
        const idx = MapList.findIndex(i => i.route === match.path);
        if(idx !== 0 && idx > store.newestMapIndex) {
            dispatch(reducer.setNewestMapIndex(idx));
        }

        setShowWelcome(true)
        imgs = [...document.querySelectorAll('img')];
        svgsImages = [...document.querySelectorAll('image')];

        setAllImages(imgs.length + svgsImages.length)



        imgs.map((img)=> {
            img.addEventListener('load', handleOnLoad )
        })
        svgsImages.map((svgImage)=> {
            svgImage.addEventListener('load', handleOnLoad )
        })
        setShowMap(true)

        // dispatch(reducer.setLastVisitedMap(match.url))

    }, [])

    useEffect(()=> {
        soundPlum.current.volume = (store.soundVolume / 2)
    }, [store.soundVolume])


    const HideWelcome = () => {
        setShowWelcome(false)

        setTimeout(() => {
            setShowButtonPlayOne(true)
            soundPlum.current.play()
        }, 800)
        setTimeout(() => {
            setShowButtonPlayTwo(true)
            soundPlum.current.play()

        }, 1200)
        setTimeout(() => {
            setShowButtonPlayThree(true)
            soundPlum.current.play()

        }, 1600)
        setTimeout(() => {
            setShowButtonPlayFour(true)
            soundPlum.current.play()

        }, 2000)
        setTimeout(() => {
            setShowButtonPlayFive(true)
            soundPlum.current.play()

        }, 2400)
        setTimeout(() => {
            setShowButtonPlaySix(true)
            soundPlum.current.play()

        }, 2800)
        setTimeout(() => {
            setShowButtonPlaySeven(true)
            soundPlum.current.play()

        }, 3200)
        setTimeout(() => {
            setShowButtonPlayEight(true)
            soundPlum.current.play()

        }, 3600)
        setTimeout(() => {
            setShowButtonPlayNine(true)
            soundPlum.current.play()

        }, 4000)
        setTimeout(() => {
            setShowButtonPlayTen(true)
            soundPlum.current.play()

        }, 4400)
    }

    const endVideo = ()=> {
        setPreloaderHide(false)
        setPreloader(true)

        setShowAnimationBanian(false)
        setShowAnimationKozy(false)
        setShowAnimationMecz(false)
        setShowAnimationZiemia(false)
        setShowAnimationMorze(false)
        setShowAnimationKrakowiak(false)
        setShowAnimationPawiePiora(false)
        setShowAnimationWyscig(false)
        setShowAnimationPalac(false)
        setShowAnimationOgnisko(false)
        setTimeout(() => {
            setPreloaderHide(true)
        },500)
        setTimeout(() => {
            setPreloader(false)
        }, 800)
    }

    let helperIsLoad = 1;
    const handleOnLoad = () => {
        ++helperIsLoad;
        setIsLoad(helperIsLoad);
        if((imgs.length + svgsImages.length) === helperIsLoad){
            setTimeout(()=> {
                setPreloaderHide(true)
            },2000)
            setTimeout(()=> {
                setPreloader(false)
            }, 2300)
        }

    }
    const handleStartAnimation = (key) => {



            switch (key) {
                case 1:
                    setShowAnimationBanian(true)
                    break;
                case 2:
                    setShowAnimationKozy(true)
                    break;
                case 3:
                    setShowAnimationMecz(true)

                    break;
                case 4:
                    setShowAnimationZiemia(true)

                    break;
                case 5:
                    setShowAnimationMorze(true)

                    break;
                case 6:
                    setShowAnimationKrakowiak(true)

                    break;
                case 7:
                    setShowAnimationPawiePiora(true)

                    break;
                case 8:
                    setShowAnimationWyscig(true)

                    break;
                case 9:
                    setShowAnimationPalac(true)

                    break;
                case 10:
                    setShowAnimationOgnisko(true)

                    break;
            }

    }

    let mapClassName = ''
    if (showMap) {
        mapClassName += ' -active'
    }



    return (

        <>
            {!!preloader && (
                <Preloader
                    hide={preloaderHide}
                    countAll={allImages}
                    countOnLoad={isLoad}
                />
            )}

                <div className="content">

                    <div className={`mapWrapper ${mapClassName}`}>
                        <div className="mapImg">
                            <img src={localAssets('img/maps/mapa_tu_bylem.jpg')} alt=""/>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" className="mapSvg">
                            <defs>
                            </defs>

                            <MuteButton
                                elProps={{}}
                                black
                            />
                            <SvgCircle
                                elProps={{
                                    width: "66",
                                    height: "66",
                                    transform: "matrix(1 0 0 1 0 0)",
                                }}
                                img={require('../../assets/img/buttons/map_www_on.png').default}
                                imgHover={require('../../assets/img/buttons/map_www_off.png').default}
                                onClick={() => setShowWebsiteMap(true)}
                            />

                            <SvgCircle
                                elProps={{
                                    width: "236",
                                    height: "236",
                                    transform: "matrix(1 0 0 1 1680 0)",

                                }}
                                img={localAssets('img/buttons/koniec_on.png')}
                                imgHover={localAssets('img/buttons/koniec_off.png')}
                                onClick={handleNextMap}

                            />

                            <SvgCircleRelative
                                elProps={{
                                    width: "89",
                                    height: "86",
                                    className: `iWasHereButtonOne ${showButtonPlayOne ? '-active' : ''}`,
                                }}
                                img={require('../../assets/img/buttons/i_was_here_button.png').default}
                                imgHover={require('../../assets/img/buttons/i_was_here_button.png').default}
                                onClick={()=>handleStartAnimation(1)}
                                transformBox="matrix(1 0 0 1 440 0)"
                                textContainer_x="440"
                                textContainer_y="0"
                                text2_trans={t("textIWasHere1_1")}
                                text3_trans={t("textIWasHere1_2")}
                                text4_trans={t("textIWasHere1_3")}
                            />




                            <SvgCircleRelative
                                elProps={{
                                    width: "89",
                                    height: "86",
                                    className: `iWasHereButtonTwo ${showButtonPlayTwo ? '-active' : ''}`,
                                }}
                                img={require('../../assets/img/buttons/i_was_here_button.png').default}
                                imgHover={require('../../assets/img/buttons/i_was_here_button.png').default}
                                onClick={()=>handleStartAnimation(2)}
                                transformBox="matrix(1 0 0 1 540 90)"
                                textContainer_x="540"
                                textContainer_y="90"
                                text2_trans={t("textIWasHere2_1")}
                                text3_trans={t("textIWasHere2_2")}
                            />
                            <SvgCircleRelative
                                elProps={{
                                    width: "89",
                                    height: "86",
                                    className: `iWasHereButtonThree ${showButtonPlayThree ? '-active' : ''}`,
                                }}
                                img={require('../../assets/img/buttons/i_was_here_button.png').default}
                                imgHover={require('../../assets/img/buttons/i_was_here_button.png').default}
                                onClick={()=>handleStartAnimation(3)}

                                transformBox="matrix(1 0 0 1 960 0)"
                                textContainer_x="960"
                                textContainer_y="0"
                                text1_trans={t("textIWasHere3_1")}
                                text2_trans={t("textIWasHere3_2")}
                                text3_trans={t("textIWasHere3_3")}
                                text4_trans={t("textIWasHere3_4")}

                            />
                            <SvgCircleRelative
                                elProps={{
                                    width: "89",
                                    height: "86",
                                    className: `iWasHereButtonFour ${showButtonPlayFour ? '-active' : ''}`,
                                }}
                                img={require('../../assets/img/buttons/i_was_here_button.png').default}
                                imgHover={require('../../assets/img/buttons/i_was_here_button.png').default}
                                onClick={()=>handleStartAnimation(4)}

                                transformBox="matrix(1 0 0 1 1200 0)"
                                textContainer_x="1200"
                                textContainer_y="0"
                                text2_trans={t("textIWasHere4_1")}
                                text3_trans={t("textIWasHere4_2")}
                                text4_trans={t("textIWasHere4_3")}

                            />
                            <SvgCircleRelative
                                elProps={{
                                    width: "89",
                                    height: "86",
                                    className: `iWasHereButtonFive ${showButtonPlayFive ? '-active' : ''}`,
                                }}
                                img={require('../../assets/img/buttons/i_was_here_button.png').default}
                                imgHover={require('../../assets/img/buttons/i_was_here_button.png').default}
                                onClick={()=>handleStartAnimation(5)}
                                transformBox="matrix(1 0 0 1 380 670)"
                                textContainer_x="380"
                                textContainer_y="670"
                                text1_trans={t("textIWasHere5_1")}
                                text2_trans={t("textIWasHere5_2")}
                                text3_trans={t("textIWasHere5_3")}
                                text4_trans={t("textIWasHere5_4")}
                                text5_trans={t("textIWasHere5_5")}


                            />
                            <SvgCircleRelative
                                elProps={{
                                    width: "89",
                                    height: "86",
                                    className: `iWasHereButtonSix ${showButtonPlaySix ? '-active' : ''}`,
                                }}
                                img={require('../../assets/img/buttons/i_was_here_button.png').default}
                                imgHover={require('../../assets/img/buttons/i_was_here_button.png').default}
                                onClick={()=>handleStartAnimation(6)}

                                transformBox="matrix(1 0 0 1 600 600)"
                                textContainer_x="600"
                                textContainer_y="600"
                                text1_trans={t("textIWasHere6_1")}
                                text2_trans={t("textIWasHere6_2")}
                                text3_trans={t("textIWasHere6_3")}
                                text4_trans={t("textIWasHere6_4")}

                            />
                            <SvgCircleRelative
                                elProps={{
                                    width: "89",
                                    height: "86",
                                    className: `iWasHereButtonSeven ${showButtonPlaySeven ? '-active' : ''}`,
                                }}
                                img={require('../../assets/img/buttons/i_was_here_button.png').default}
                                imgHover={require('../../assets/img/buttons/i_was_here_button.png').default}
                                onClick={()=>handleStartAnimation(7)}


                                transformBox="matrix(1 0 0 1 900 425)"
                                textContainer_x="900"
                                textContainer_y="425"
                                text2_trans={t("textIWasHere7_1")}
                                text3_trans={t("textIWasHere7_2")}
                                text4_trans={t("textIWasHere7_3")}
                                buttonContainer_y="640"
                                buttonLine1_x="940"
                                buttonLine2_x="945"
                            />
                            <SvgCircleRelative
                                elProps={{
                                    width: "89",
                                    height: "86",
                                    className: `iWasHereButtonEight ${showButtonPlayEight ? '-active' : ''}`,
                                }}
                                img={require('../../assets/img/buttons/i_was_here_button.png').default}
                                imgHover={require('../../assets/img/buttons/i_was_here_button.png').default}
                                onClick={()=>handleStartAnimation(8)}
                                transformBox="matrix(1 0 0 1 1190 325)"
                                textContainer_x="1190"
                                textContainer_y="325"
                                text1_trans={t("textIWasHere8_1")}
                                text2_trans={t("textIWasHere8_2")}
                                text3_trans={t("textIWasHere8_3")}
                                text4_trans={t("textIWasHere8_4")}
                                text5_trans={t("textIWasHere8_5")}

                            />
                            <SvgCircleRelative
                                elProps={{
                                    width: "89",
                                    height: "86",
                                    className: `iWasHereButtonNine ${showButtonPlayNine ? '-active' : ''}`,
                                }}
                                img={require('../../assets/img/buttons/i_was_here_button.png').default}
                                imgHover={require('../../assets/img/buttons/i_was_here_button.png').default}
                                onClick={()=>handleStartAnimation(9)}
                                transformBox="matrix(1 0 0 1 1500 325)"
                                textContainer_x="1500"
                                textContainer_y="325"
                                text2_trans={t("textIWasHere9_1")}
                                text3_trans={t("textIWasHere9_2")}
                            />
                            <SvgCircleRelative
                                elProps={{
                                    width: "89",
                                    height: "86",
                                    className: `iWasHereButtonTen ${showButtonPlayTen ? '-active' : ''}`,
                                }}
                                img={require('../../assets/img/buttons/i_was_here_button.png').default}
                                imgHover={require('../../assets/img/buttons/i_was_here_button.png').default}
                                onClick={()=>handleStartAnimation(10)}
                                transformBox="matrix(1 0 0 1 1590 580)"
                                textContainer_x="1590"
                                textContainer_y="580"
                                text2_trans={t("textIWasHere10_1")}
                                text3_trans={t("textIWasHere10_2")}
                                text4_trans={t("textIWasHere10_3")}
                            />
                        </svg>

                        <SecondPopup
                            onClick={HideWelcome}
                            visible={showWelcome}
                            className='-iWasHere'
                            img={localAssets('img/popup/iWasHere/welcome.png')}
                        >
                            <span dangerouslySetInnerHTML={{__html: t('textWelcomeIWasHere_1')}}/>
                            <p dangerouslySetInnerHTML={{__html: t('textWelcomeIWasHere_2')}}/>
                        </SecondPopup>

                        <WebsiteMapPopup
                            onClick={() => setShowWebsiteMap(false)}
                            visible={showWebsiteMap}
                            className="-mapWebsite"

                        />

                        {!!showAnimationBanian && (
                            <PrimaryVideo
                                video={localAssets('video/tuBylem/banian.mp4')}
                                onEnded={endVideo}
                                onSkip={endVideo}
                            />
                        )}
                        {!!showAnimationKozy && (
                            <PrimaryVideo
                                video={localAssets('video/tuBylem/kozy.mp4')}
                                onEnded={endVideo}
                                onSkip={endVideo}
                            />
                        )}
                        {!!showAnimationMecz && (
                            <PrimaryVideo
                                video={localAssets('video/tuBylem/mecz.mp4')}
                                onEnded={endVideo}
                                onSkip={endVideo}
                            />
                        )}
                        {!!showAnimationZiemia && (
                            <PrimaryVideo
                                video={localAssets('video/tuBylem/ziemia.mp4')}
                                onEnded={endVideo}
                                onSkip={endVideo}
                            />
                        )}
                        {!!showAnimationMorze && (
                            <PrimaryVideo
                                video={localAssets('video/tuBylem/morze.mp4')}
                                onEnded={endVideo}
                                onSkip={endVideo}
                            />
                        )}
                        {!!showAnimationKrakowiak && (
                            <PrimaryVideo
                                video={localAssets('video/tuBylem/krakowiak.mp4')}
                                onEnded={endVideo}
                                onSkip={endVideo}
                            />
                        )}
                        {!!showAnimationPawiePiora && (
                            <PrimaryVideo
                                video={localAssets('video/tuBylem/pawiepiora.mp4')}
                                onEnded={endVideo}
                                onSkip={endVideo}
                            />
                        )}
                        {!!showAnimationWyscig && (
                            <PrimaryVideo
                                video={localAssets('video/tuBylem/wyscig.mp4')}
                                onEnded={endVideo}
                                onSkip={endVideo}
                            />
                        )}
                        {!!showAnimationPalac && (
                            <PrimaryVideo
                                video={localAssets('video/tuBylem/palac.mp4')}
                                onEnded={endVideo}
                                onSkip={endVideo}
                            />
                        )}
                        {!!showAnimationOgnisko && (
                            <PrimaryVideo
                                video={localAssets('video/tuBylem/ognisko.mp4')}
                                onEnded={endVideo}
                                onSkip={endVideo}
                            />
                        )}



                    </div>

                </div>

        </>

    )

}
