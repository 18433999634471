import {useLocaleAsset} from "../../misc/Hooks";

interface Props {
    hide: boolean;
    countAll: any,
    countOnLoad: any,
}

export default (props: Props) => {
    const localeAsset = useLocaleAsset();

    return (
        <div className={`preloader ${props.hide ? '-hide' : ''}`}>
            <div className="preloader__wrapper">
                <img src={localeAsset('img/other/preloader.png')} alt=""
                     className={`preloader__image`}/>
                {!!props.countAll ? (
                    <div className="preloader__loadContainer">
                        <div className="preloader__load"
                             style={{width: (props.countOnLoad / props.countAll) * 100 + '%'}}/>
                    </div>
                ) : ''}
            </div>
        </div>
    )
}