import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

interface Props {
    item: any,
    nextSlide: void,
}


export const ComicSound = (props: Props) => {

    const store = useSelector(state=>({
        soundVolume: state.soundVolume,
    }))

    const [audioCache, setCacheAudio] = useState(null)

    const changeVolume = (volume) => {
        if (!audioCache) return;
        audioCache.volume = Number(volume);
    }

    const pause = () => {
        if (!audioCache) return;
        audioCache.pause();
    }

    const play = () => {
        if (!audioCache) return;
        audioCache.play();
    }

    const setLoop = (loop) => {
        if (!audioCache) return;
        audioCache.loop = loop;
    }
    const setOnEnd = (boolOnEnd, nextSlide, delayNextSlide = 0) => {
        if (boolOnEnd) {
            audioCache.onended = nextSlide;
        }
    }

    const loadAudioFromCache = async () => {
        const cachedAudio = await caches.match(props.item.file);
        let audioObject;

        if (cachedAudio) {
            const response = await cachedAudio.blob();
            audioObject = new Audio(URL.createObjectURL(response));

            console.log('Cached sound used', `ID: ${props.item.id}`)
        } else {
            audioObject = new Audio(props.item.file);
            console.log('No cached sound used', `ID: ${props.item.file}`)
        }

        audioObject.volume = store.soundVolume;
        return audioObject;
    };

    useEffect(()=> {
        changeVolume(store.soundVolume)
    }, [store.soundVolume])

    useEffect(() => {
        loadAudioFromCache().then((audio) => {
            setCacheAudio(audio)
        })
    }, [props.item.file]);

    useEffect(() => {
        if(!audioCache) return

        const timeout = setTimeout(() => {
            setLoop(props.item.loop);
            setOnEnd(props.item.onEndSound, props.nextSlide, props.item.delayNextSlide);
            play();
        }, props.item.delay);

        return () => {
            clearTimeout(timeout);
            pause();
        };

    }, [audioCache]);

    return (

        <div>
            {props.item.id}: {props.item.file}
        </div>

    );
}

