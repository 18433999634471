

export default {
    NAME: 'ComicAmnestyCygaro',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_01.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_02.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_03.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_04.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_05.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_06.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_07.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_08.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_09.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_10.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_11.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_12.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_13.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_14.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_15.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_16.jpg',
        },
        {
            img:  'img/comics/amnesty/cygaro/syberia_06_Cygaro_17.jpg',
        },
    ],
    COMIC_SOUNDS_LIST: [
        {
            id: 1,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_AtA_CommandRoomBusyAmbience_Loop_01.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_AtA_Crickets_Loop_01.mp3').default,
            from: 13,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_AtA_DistantExplosionsBlasts_Loop_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_AtA_DistantExplosionsBlasts_Loop_02.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_AtB_BombsExplosionDistant_Loop_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_AtB_BombsFallingWhistle_Loop_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_AtB_BunkerAmbience_Loop_01.mp3').default,
            from: 6,
            to: 12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_AtB_ControlRoomTelemetry_Loop_01.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_AtB_DesertWindBlustery_Loop_01.mp3').default,
            from: 12,
            to: 13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_AtB_DesertWindBlustery_Loop_01.mp3').default,
            from: 13,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_AtB_IndiaVillageMorning_Loop_01.mp3').default,
            from: 14,
            to: 17,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_FxA_AirplaneSquadron_01.mp3').default,
            from: 1,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_FxA_AirplaneSquadron_01.mp3').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_FxB_AirRaidSiren_01.mp3').default,
            from: 0,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_FxB_AirRaidSiren_Loop_02.mp3').default,
            from: 1,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_FxB_Cranes_Loop_01.mp3').default,
            from: 13,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_FxB_Match_01.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_FxB_PhonePickUp_01.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        // {
        //     id: 19,
        //     file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_FxB_StukaBombing_01.mp3').default,
        //     from: 3,
        //     to: 4,
        //     loop: false,
        //     delay: 0,
        //     volume: 1,
        //     onEndSound: false
        // },
        {
            id: 20,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_Mx_01A_Military.mp3').default,
            from: 4,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 21,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_Mx_01B_Kuzyni.mp3').default,
            from: 12,
            to: 17,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_FxB_StukaBombing_01.mp3').default,
            from: 3,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file: require('../assets/sounds/comics/amnesty/cygaro/TBB_Syberia_06_Cygaro_Mx_01C_Rozmowa.mp3').default,
            from: 8,
            to: 12,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },

    ],
}
