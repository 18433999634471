import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import Routes from "../utils/Routes";
import { Preloader } from "./misc/_index";
import { Navigation } from "./nav/_index";
import { useLocaleAsset, useNavigation, useTranslation } from "../misc/Hooks";
import doorSound from "../assets/sounds/effects/door.mp3";
import doorSoundHi from "../assets/sounds/effects/door_hi.mp3";
import doorSoundGu from "../assets/sounds/effects/door_gu.mp3";
import { useDispatch, useSelector } from "react-redux";
import closeIcon from "../assets/img/buttons/exit.png";
import soundOnIcon from "../assets/img/buttons/volume-on.png";
import soundOffIcon from "../assets/img/buttons/volume-mute.png";
import * as reducer from "../store/reducer";
import RotateDevice from "./RotateDevice";
import Button from "./misc/Button";
import MuteButton from "./misc/MuteButton";
import {setVolumeAudio} from "../store/reducer";

let effect = null;
let imgs = null;
let svgsImages = null;

export default (props) => {
  const dispatch = useDispatch();
  const localeAssets = useLocaleAsset();
  const store = useSelector((state) => ({
    soundVolume: state.soundVolume,
    infoStart: state.infoStart,
    // lastVisitedMap: state.lastVisitedMap,
  }));
  const match = useRouteMatch();

  const history = useHistory();
  const t = useTranslation();
  const navigation = useNavigation();
  const [hovered, setHovered] = useState(false);
  const [preloader, setPreloader] = useState(true);
  const [preloaderHide, setPreloaderHide] = useState(false);
  const [infoStartState, setInfoStartState] = useState(true);
  const [exitInfoStart, setExitInfoStart] = useState(false);
  const [allImages, setAllImages] = useState(0);
  const [isLoad, setIsLoad] = useState(0);

  const doorSoundArray = {
    pl: doorSound,
    en: doorSound,
    hi: doorSoundHi,
    gu: doorSoundGu,
  };

  window.onpopstate = () => {
    effect.pause();
  };

  useEffect(() => {
    const lang = match.params.lang ?? process.env.REACT_APP_DEFAULT_LANG;
    console.log(doorSoundArray[lang]);
    effect = new Audio(doorSoundArray[lang]);
    effect.loop = true;
    effect.volume = store.soundVolume;

    imgs = [...document.querySelectorAll("img")];
    svgsImages = [...document.querySelectorAll("image")];

    setAllImages(imgs.length + svgsImages.length - 1);

    imgs.map((img) => {
      img.addEventListener("load", handleOnLoad);
    });
    svgsImages.map((svgImage) => {
      svgImage.addEventListener("load", handleOnLoad);
    });

    return () => {
      if (effect) {
        effect.pause();
        effect.currentTime = 0;
      }
    };

    // document.querySelector('.content').click();
  }, []);

  useEffect(() => {
    effect.volume = store.soundVolume;
  }, [store.soundVolume]);

  function start() {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
    effect.pause();

    history.push(navigation(Routes.MAP_DEPORTATION));
  }



  let helperIsLoad = 0;
  const handleOnLoad = () => {
    ++helperIsLoad;
    setIsLoad(helperIsLoad);

    if (imgs.length + svgsImages.length - 1 === helperIsLoad) {
      setHovered(true);
      effect.currentTime = 0;
      effect.play().catch((error) => {
        if (error.name === 'NotAllowedError') {
          dispatch(setVolumeAudio(0.0))
        } else {
          console.error("Error playing the sound:", error);
        }
      });
      setTimeout(() => {
        setPreloaderHide(true);
      }, 2000);
      setTimeout(() => {
        setPreloader(false);
      }, 2300);
    }
  };

  const onBackToMenu = () => {
    effect.pause();
    history.push(navigation(Routes.HOME));
  };


  let exitInfoClassName = "";
  if (exitInfoStart) {
    exitInfoClassName += " -exit";
  }

  return (
   <>

   {preloader && (
     <Preloader
       hide={preloaderHide}
       countAll={allImages}
       countOnLoad={isLoad}
     />
   )}

   <div className="content">
   <div className="comicWrapper__exit closeButton">
        <Button
          img={"img/buttons/close-icon.png"}
          imgHover={"img/buttons/close-icon-hover.png"}
          onClick={onBackToMenu}
        />
         </div>

      <main className="main -black">
        {/*{!!infoStartState && (*/}
        {/*    <div className={`startInfo ${exitInfoClassName}`} onClick={handleCloseInfo}>*/}
        {/*        <img src={localeAssets('img/other/info-start.png')} alt=""/>*/}
        {/*    </div>*/}
        {/*)}*/}
        <div className="container">
          {/*<Navigation/>*/}

          <div className="mainPage">
            <div className={`bumLeft ${hovered ? "-animation" : ""}`}>
              <img src={localeAssets("img/start/bum-left.png")} alt="" />
            </div>
            <div className={`bumRight ${hovered ? "-animation" : ""}`}>
              <img src={localeAssets("img/start/bum-right.png")} alt="" />
            </div>

            <div className="door" onClick={start}>
              <img src={localeAssets("img/start/door_new.png")} alt="" />
            </div>

            {/*<div className="logoFixed">*/}
            {/*    <img src={localeAssets('img/start/logo_start.png')} alt=""/>*/}
            {/*</div>*/}

            <div className="navigationStart -start">
              <button className="primaryButton -start" onClick={start}>
                {t("openDoor")}
              </button>

              {/*<div className="navigationStart__lang">*/}
              {/*    <a href="/">POLSKI</a> / <a href="/en">ENGLISH</a>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>

      </main>
      <MuteButton
        handleOnSoundOn={() => {
          effect.currentTime = 0;
          setHovered(false)
          setTimeout(() => {
            setHovered(true)
            effect.play()
          }, 50)
        }}
      />
    </div>

   </>
  );
};
