export default {
    NAME: 'ComicBieriezniakiObcy',

    COMIC_IMAGES_LIST: [
        {
            img: 'img/comics/bieriezniaki/obcy/syberia_05_Obcy_01.jpg',
        },
        {
            img: 'img/comics/bieriezniaki/obcy/syberia_05_Obcy_02.jpg',
        },
        {
            img: 'img/comics/bieriezniaki/obcy/syberia_05_Obcy_03.jpg',
        },
        {
            img: 'img/comics/bieriezniaki/obcy/syberia_05_Obcy_04.jpg',
        },
        {
            img: 'img/comics/bieriezniaki/obcy/syberia_05_Obcy_05.jpg',
        },
        {
            img: 'img/comics/bieriezniaki/obcy/syberia_05_Obcy_06.jpg',
        },


    ],
    COMIC_SOUNDS_LIST: [
        {
            id: 1,
            file: require('../assets/sounds/comics/bierezniaki/obcy/TBB_Syberia_05_Obcy_AtA_WindBlustery_Loop_01.mp3').default,
            from: 0,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/bierezniaki/obcy/TBB_Syberia_05_Obcy_AtA_WindBlustery_Loop_01.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/bierezniaki/obcy/TBB_Syberia_05_Obcy_AtB_WindBlusteryInside_Loop_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/bierezniaki/obcy/TBB_Syberia_05_Obcy_AtB_WindBlusteryInside_Loop_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/bierezniaki/obcy/TBB_Syberia_05_Obcy_AtB_WinterWindStrong_Loop_01.mp3').default,
            from: 0,
            to: 1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/bierezniaki/obcy/TBB_Syberia_05_Obcy_AtB_WinterWindStrong_Loop_02.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/bierezniaki/obcy/TBB_Syberia_05_Obcy_AtB_WinterWindStrong_Loop_02.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/bierezniaki/obcy/TBB_Syberia_05_Obcy_AtB_WinterWindStrong_Loop_03.mp3').default,
            from: 4,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/bierezniaki/obcy/TBB_Syberia_05_Obcy_FxB_FootstepsSnow_01.mp3').default,
            from: 3,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/bierezniaki/obcy/TBB_Syberia_05_Obcy_Mx_Akcent_01.mp3').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/bierezniaki/obcy/TBB_Syberia_05_Obcy_Mx_Akcent_02.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },






    ],
}
