import React, {useEffect, useState} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import {useLocaleAsset, useNavigation, useTranslation} from "../misc/Hooks";
import Routes from "../utils/Routes";
import {Preloader} from "./misc/_index";


export default (props) => {

    const t = useTranslation();

    const navigation = useNavigation();
    const [preloader, setPreloader] = useState(true);
    const [preloaderHide, setPreloaderHide] = useState(false);
    const localeAsset = useLocaleAsset();

    useEffect(() => {
        setTimeout(() => {
            setPreloaderHide(true)
        }, 300)
        setTimeout(() => {
            setPreloader(false)
        }, 600)

    }, [])

    return (
        <>
            {!!preloader && (
                <Preloader hide={preloaderHide}/>
            )}
            <div className="instructionPage">
                <div className="forTeachers">

                    <a target={"_blank"} href={localeAsset('pdf/scenariusz1.pdf')}>
                        <img src={localeAsset('img/forTeachers/scenariusz1.png')} alt=""/>
                    </a>
                    <a target={"_blank"} href={localeAsset('pdf/scenariusz2.pdf')}>
                        <img src={localeAsset('img/forTeachers/scenariusz2.png')} alt=""/>
                    </a>
                    <a target={"_blank"} href={localeAsset('pdf/scenariusz3.pdf')}>
                        <img src={localeAsset('img/forTeachers/scenariusz3.png')} alt=""/>
                    </a>

                </div>
                <Link to={navigation(Routes.HOME)} className="pagesExit">
                    <img src={require('../assets/img/buttons/exit.png').default} alt=""/>
                    {t("forTeachers")}
                </Link>

            </div>
        </>

    )
}
