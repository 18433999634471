

export default {
    NAME: 'ComicHousingEstateSzkola',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_2_01_Szkola/szlak_2_01_Szkola_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_01_Szkola/szlak_2_01_Szkola_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_01_Szkola/szlak_2_01_Szkola_03.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_01_Szkola/szlak_2_01_Szkola_04.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_01_Szkola/szlak_2_01_Szkola_05.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_01_Szkola/szlak_2_01_Szkola_06.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_01_Szkola/szlak_2_01_Szkola_07.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_01_Szkola/szlak_2_01_Szkola_08.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_01_Szkola/szlak_2_01_Szkola_09.jpg',
        },

    ],
    COMIC_SOUNDS_LIST: [
        {
            id: 1,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_AtA_Barrack_01.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_AtA_Barrack_01.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_AtA_Barrack_01.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_AtA_Barrack_01.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_AtA_Crowd_01.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_AtA_DesertArea_01.mp3').default,
            from: 0,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_AtA_DesertArea_02.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_AtA_PlayingKids_01.mp3').default,
            from: 2,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_AtA_RustleLeaves_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_AtB_DesertDay_01.mp3').default,
            from: 2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_AtB_DesertDay_02.mp3').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_FxA_Flags_01.mp3').default,
            from: 6,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_FxA_Movement_01.mp3').default,
            from:5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_FxA_Noise_01.mp3').default,
            from: 1,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_FxA_Opel_01.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_FxA_PenWriting_01.mp3').default,
            from: 7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_FxA_PhoneSentence_01.mp3').default,
            from: 3,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_FxA_Scouts_01.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 19,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_Mx_01_Sluchawka.mp3').default,
            from: 3,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 20,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_Mx_02_Podreczniki.mp3').default,
            from: 4,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 21,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_Mx_03_AkcentPorazka.mp3').default,
            from: 7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_Mx_04_AkcentZwyciezki.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_VO_Children_01.mp3').default,
            from: 1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 24,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_VO_Tree_01.mp3').default,
            from: 2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 25,
            file: require('../assets/sounds/comics/housing-estate/szkola/TBB_Szlak_02_Szkola_AtA_RustleLeaves_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },

    ],
}
