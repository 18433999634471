import React, {useEffect, useRef, useState} from "react";
import {Preloader} from "../misc/_index";
import BraveBunchMap from "./housing-estate-maps/BraveBunchMap";
import LifeInTheEstateMap from "./housing-estate-maps/LifeInTheEstateMap";
import WonderfulYearsMap from "./housing-estate-maps/WonderfulYearsMap";
import NightMap from "./housing-estate-maps/NightMap";
import {useDispatch, useSelector} from "react-redux";
import MapList from "../../utils/MapList";
import * as reducer from "../../store/reducer";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useLocaleAsset, useNavigation, useTranslation} from "../../misc/Hooks";


let imgs = null;
let svgsImages = null;

export default (props) => {
    const history = useHistory();
    const t = useTranslation();
    const navigation = useNavigation();
    const localePath = useLocaleAsset();

    const [allImages, setAllImages] = useState(0);
    const [isLoad, setIsLoad] = useState(0);

    const daySound = localePath('sounds/maps/TBB_Mapy_05_Osiedle_01.mp3')
    const nightSound = localePath('sounds/maps/TBB_Mapy_05_OsiedleNoc_01.mp3')

    const dispatch = useDispatch();
    const store = useSelector(state=>({
        newestMapIndex: state.newestMapIndex,
        soundVolume: state.soundVolume,

    }))
    const match = useRouteMatch();

    const [preloader, setPreloader] = useState(true);
    const [preloaderHide, setPreloaderHide] = useState(false);


    const [braveBunchMap, setBraveBunchMap] = useState(false);
    const [lifeInTheEstateMap, setLifeInTheEstateMap] = useState(true);
    const [wonderfulYearsMap, setWonderfulYearsMap] = useState(false);
    const [nightMap, setNightMap] = useState(false);
    const [welcomeWasDisplayed, setWelcomeWasDisplayed] = useState(false);

    let helperIsLoad = 0;

    let musicMapDay = useRef(new Audio(daySound));
    let musicMapNight = useRef(new Audio(nightSound));
    
    useEffect(() => {
        musicMapStartDay();

        const idx = MapList.findIndex(i => i.route === match.path);
        if(idx !== 0 && idx > store.newestMapIndex) {
            dispatch(reducer.setNewestMapIndex(idx));
        }

        imgs = [...document.querySelectorAll('img')];
        svgsImages = [...document.querySelectorAll('image')];

        setAllImages(imgs.length + svgsImages.length - 2)
        helperIsLoad = 1;

        imgs.map((img)=> {
            img.addEventListener('load', handleOnLoad )
        })
        svgsImages.map((svgImage)=> {
            svgImage.addEventListener('load', handleOnLoad )
        })
        // dispatch(reducer.setLastVisitedMap(match.url))
        return () => {
            if (musicMapDay) {
                musicMapDay.current.pause();
                musicMapDay.current.currentTime = 0;
            }
            if (musicMapNight) {
                musicMapNight.current.pause();
                musicMapNight.current.currentTime = 0;
            }
          };
    }, [])

    useEffect(()=> {
        musicMapDay.current.volume = store.soundVolume
        musicMapNight.current.volume = store.soundVolume
    }, [store.soundVolume])


    const handleOnLoad = () => {
        ++helperIsLoad;
        setIsLoad(helperIsLoad - 2);
        if((imgs.length + svgsImages.length) === helperIsLoad){
            setTimeout(()=> {
                setPreloaderHide(true)
            },2000)
            setTimeout(()=> {
                setPreloader(false)
            }, 2300)
        }

    }

    const musicMapStartDay = () => {
        musicMapDay.current.volume = 0;
        musicMapDay.current.loop = true;
        musicMapDay.current.play();

        setTimeout(() => {
            if(store.soundVolume >= .1){
                musicMapDay.current.volume = .1;
            }
        }, 200)
        setTimeout(() => {
            if(store.soundVolume >= .3){
                musicMapDay.current.volume = .3;
            }
        }, 400)
        setTimeout(() => {
            if(store.soundVolume >= .5){
                musicMapDay.current.volume = .5;
            }
        }, 600)
        setTimeout(() => {
            if(store.soundVolume >= .8){
                musicMapDay.current.volume = .8;
            }
        }, 800)
        setTimeout(() => {
            if(store.soundVolume >= 1){
                musicMapDay.current.volume = 1;
            }
        }, 1000)
    }
    const musicMapStopDay = () => {
        setTimeout(() => {
            if(store.soundVolume >= .8){
                musicMapDay.current.volume = .8;
            }
        }, 200)
        setTimeout(() => {
            if(store.soundVolume >= .6){
                musicMapDay.current.volume = .6;
            }
        }, 400)
        setTimeout(() => {
            if(store.soundVolume >= .4){
                musicMapDay.current.volume = .4;
            }
        }, 600)
        setTimeout(() => {
            if(store.soundVolume >= .2){
                musicMapDay.current.volume = .2;
            }
        }, 800)
        setTimeout(() => {
            if(store.soundVolume >= .1){
                musicMapDay.current.volume = .1;
            }
            musicMapDay.current.pause();
        }, 1000)
    }
    const musicMapStartNight = () => {
        musicMapNight.current.volume = 0;
        musicMapNight.current.loop = true;
        musicMapNight.current.play();

        setTimeout(() => {
            if(store.soundVolume >= .1){
                musicMapNight.current.volume = .1;
            }
        }, 200)
        setTimeout(() => {
            if(store.soundVolume >= .3){
                musicMapNight.current.volume = .3;
            }
        }, 400)
        setTimeout(() => {
            if(store.soundVolume >= .5){
                musicMapNight.current.volume = .5;
            }
        }, 600)
        setTimeout(() => {
            if(store.soundVolume >= .8){
                musicMapNight.current.volume = .8;
            }
        }, 800)
        setTimeout(() => {
            if(store.soundVolume >= 1){
                musicMapNight.current.volume = 1;
            }
        }, 1000)
    }
    const musicMapStopNight = () => {
        setTimeout(() => {
            if(store.soundVolume >= .8){
                musicMapNight.current.volume = .8;
            }
        }, 200)
        setTimeout(() => {
            if(store.soundVolume >= .6){
                musicMapNight.current.volume = .6;
            }
        }, 400)
        setTimeout(() => {
            if(store.soundVolume >= .4){
                musicMapNight.current.volume = .4;
            }
        }, 600)
        setTimeout(() => {
            if(store.soundVolume >= .2){
                musicMapNight.current.volume = .2;
            }
        }, 800)
        setTimeout(() => {
            if(store.soundVolume >= .1){
                musicMapNight.current.volume = .1;
            }
            musicMapNight.current.pause();
        }, 1000)
    }


    const handleOffAllMaps = () => {
        setBraveBunchMap(false);
        setLifeInTheEstateMap(false)
        setWonderfulYearsMap(false)
        setNightMap(false)
    }

    const handleChangeMap = (map) => {
        document.querySelector('.mapWrapper').classList.remove('-active')
        setWelcomeWasDisplayed(true);
        setTimeout(() => {
            handleOffAllMaps();
            setPreloader(true)
            switch (map) {
                case 1:
                    setBraveBunchMap(true)
                    musicMapStopNight();
                    musicMapStartDay()
                    break;
                case 2:
                    setLifeInTheEstateMap(true)
                    musicMapStopNight();
                    musicMapStartDay()
                    break;
                case 3:
                    setWonderfulYearsMap(true)
                    musicMapStopNight();
                    musicMapStartDay()
                    break;
                case 4:
                    setNightMap(true)
                    musicMapStopDay()
                    musicMapStartNight()
                    break;
                default:
            }

            setTimeout(() => {
                setPreloaderHide(true)
            }, 1000)
            setTimeout(() => {
                setPreloader(false)
                setPreloaderHide(false)
            }, 1300)
        }, 500)
    }





    return (

        <>
            {!!preloader && (
                <Preloader
                    hide={preloaderHide}
                    countAll={allImages}
                    countOnLoad={isLoad}
                />
            )}
            {!!braveBunchMap && (
                <BraveBunchMap
                    onClickNavBraveBrunch={() => handleChangeMap(1)}
                    onClickNavLifeInTheEstate={() => handleChangeMap(2)}
                    onClickWonderfulYears={() => handleChangeMap(3)}
                    onClickNight={() => handleChangeMap(4)}
                    musicMapOff={musicMapStopDay}
                    musicMapOn={musicMapStartDay}
                />
            )}
            {!!lifeInTheEstateMap && (
                <LifeInTheEstateMap
                    onClickNavBraveBrunch={() => handleChangeMap(1)}
                    onClickNavLifeInTheEstate={() => handleChangeMap(2)}
                    onClickWonderfulYears={() => handleChangeMap(3)}
                    onClickNight={() => handleChangeMap(4)}
                    welcomeWasDisplayed={welcomeWasDisplayed}
                    musicMapOff={musicMapStopDay}
                    musicMapOn={musicMapStartDay}

                />
            )}
            {!!wonderfulYearsMap && (
                <WonderfulYearsMap
                    onClickNavBraveBrunch={() => handleChangeMap(1)}
                    onClickNavLifeInTheEstate={() => handleChangeMap(2)}
                    onClickWonderfulYears={() => handleChangeMap(3)}
                    onClickNight={() => handleChangeMap(4)}
                    musicMapOff={musicMapStopDay}
                    musicMapOn={musicMapStartDay}

                />
            )}
            {!!nightMap && (
                <NightMap
                    onClickNavBraveBrunch={() => handleChangeMap(1)}
                    onClickNavLifeInTheEstate={() => handleChangeMap(2)}
                    onClickWonderfulYears={() => handleChangeMap(3)}
                    onClickNight={() => handleChangeMap(4)}
                    musicMapOff={musicMapStopNight}
                    musicMapOn={musicMapStartNight}
                />
            )}



        </>


    )
};
