

export default {
    NAME: 'ComicHousingEstateKapiele',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_03.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_04.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_05.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_06.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_07.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_08.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_09.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_10.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_11.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_12.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_13.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_14.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_15.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_16.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_17.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_18.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_06_Kapiele/szlak_3_06_Kapiele_19.jpg',
        },



    ],
    COMIC_SOUNDS_LIST: [
        {
            id: 1,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_RuralDay_01.mp3').default,
            from: 0,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_RuralDay_01.mp3').default,
            from: 2,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_RuralDay_01.mp3').default,
            from: 4,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_RuralDay_01.mp3').default,
            from:6,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_RuralDay_01.mp3').default,
            from: 8,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_RuralDay_01.mp3').default,
            from: 10,
            to: 12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_RuralDay_01.mp3').default,
            from: 12,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_RuralDay_01.mp3').default,
            from: 14,
            to: 16,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_RuralDay_01.mp3').default,
            from: 16,
            to: 18,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_RuralDay_02.mp3').default,
            from: 18,
            to: 19,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_DesertRuralDay_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_DesertRuralDay_01.mp3').default,
            from: 4,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_Geese_01.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_StraitArea_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_StraitArea_02.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_StraitArea_03.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_StraitArea_04.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_StraitArea_05.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 19,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_StraitArea_06.mp3').default,
            from: 9,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 20,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_StraitArea_07.mp3').default,
            from: 10,
            to:11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 21,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_StraitArea_08.mp3').default,
            from: 11,
            to:12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_StraitArea_08.mp3').default,
            from: 13,
            to:15,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_StraitArea_09.mp3').default,
            from: 12,
            to:13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 24,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_StraitArea_10.mp3').default,
            from: 15,
            to:17,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 25,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_AtA_Waves_01.mp3').default,
            from: 0,
            to:1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 26,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Branch_01.mp3').default,
            from: 3,
            to:5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 27,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Footstep_01.mp3').default,
            from: 16,
            to:17,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 28,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Footsteps_01.mp3').default,
            from: 1,
            to:2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 29,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Goose_01.mp3').default,
            from: 7,
            to:8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 30,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Impact_01.mp3').default,
            from: 16,
            to:17,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 31,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_kapiele_18.mp3').default,
            from: 17,
            to:18,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 32,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Mud_02.mp3').default,
            from: 18,
            to:19,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 33,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Running_02.mp3').default,
            from: 12,
            to:14,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 34,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Slipping_01.mp3').default,
            from: 15,
            to:16,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 35,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Steps_01.mp3').default,
            from: 12,
            to:13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 36,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Swimming_01.mp3').default,
            from: 11,
            to:13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 37,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Swimming_02.mp3').default,
            from: 12,
            to:13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 38,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Swimming_03.mp3').default,
            from: 14,
            to:15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 39,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Swimming_04.mp3').default,
            from: 14,
            to:15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 40,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Swimming_05.mp3').default,
            from: 15,
            to:16,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 41,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Swimming_06.mp3').default,
            from: 13,
            to:15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
      /*  {
            id: 42,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_WaterDrops_01.mp3').default,
            from: 17,
            to:18,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
        {
            id: 43,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_WaterSplash_03.mp3').default,
            from: 3,
            to:4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 44,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_WaterSplash_04.mp3').default,
            from: 5,
            to:6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 45,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_WaterSplash_05.mp3').default,
            from: 11,
            to:12,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 46,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Whoosh_01.mp3').default,
            from: 0,
            to:1,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 47,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxA_Whoosh_02.mp3').default,
            from: 10,
            to:11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 48,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxB_Footsteps_01.mp3').default,
            from: 1,
            to:3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 49,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_FxB_Rope_01.mp3').default,
            from: 15,
            to:16,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 50,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_VO_AAA_01.mp3').default,
            from: 9,
            to:10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 51,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_VO_Breathing_01.mp3').default,
            from: 11,
            to:12,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 52,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_VO_ChildrenLaughing_Loop_01.mp3').default,
            from: 0,
            to:1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 53,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_VO_GivingHelp_02.mp3').default,
            from: 14,
            to:15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 54,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_VO_GivingHelp_03.mp3').default,
            from: 15,
            to:16,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 55,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_VO_GivingHelp_04.mp3').default,
            from: 16,
            to:17,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        /*{
            id: 56,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_VO_Laughing_02.mp3').default,
            from: 17,
            to:18,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
        {
            id: 57,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_VO_Laughing_03.mp3').default,
            from: 18,
            to:19,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 58,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_VO_PlayingBoys_01.mp3').default,
            from: 2,
            to:3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 59,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_VO_TakingAffort_01.mp3').default,
            from: 3,
            to:4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 60,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_Mx_01_HejPlywany.mp3').default,
            from: 1,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 61,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_Mx_02_Marzyciel.mp3').default,
            from: 6,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        /*{
            id: 62,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_Mx_03_GroznyAkcent.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
        {
            id: 63,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_Szlak_03_Kapiele_Mx_04_NaRatunek.mp3').default,
            from: 11,
            to:16,
            loop: false,
            delay: 100,
            volume: 1,
            onEndSound: false
        },
        {
            id: 64,
            file: require('../assets/sounds/comics/housing-estate/kapiele/TBB_szlak_3_06_Kapiele_Akcent_17.mp3').default,
            from: 16,
            to:17,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
    ],

}
