import {Preloader, PrimaryVideo, SvgCircle} from "../misc/_index";
import {
    FullScreenPopup,
    PrimaryPopup,
    WebsiteMapPopup
} from "../popup/_index";
import {useLocaleAsset, useNavigation, useTranslation} from "../../misc/Hooks";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {ComicBase} from "../comics/ComicBase";
import ComicAmnestyCygaro from "../../utils/ComicAmnestyCygaro";
import ComicAmnestyDetdom from "../../utils/ComicAmnestyDetdom";
import ComicAmnestyDetdomHi from "../../utils/ComicAmnestyDetdomHi";
import ComicAmnestyDetdomGu from "../../utils/ComicAmnestyDetdomGu";
import ComicAmnestyOficer from "../../utils/ComicAmnestyOficer";
import ComicAmnestyKasansaj from "../../utils/ComicAmnestyKasansaj";
import ComicAmnestyWagony from "../../utils/ComicAmnestyWagony";
import {useHistory, useRouteMatch} from "react-router-dom";
import Routes from "../../utils/Routes";
import {useDispatch, useSelector} from "react-redux";
import MapList from "../../utils/MapList";
import * as reducer from "../../store/reducer";
import amnestySound from "../../assets/sounds/maps/TBB_Mapy_03_Amnestia_01.mp3"
import plum from "../../assets/sounds/effects/plum.mp3"
import MuteButton from "../misc/MuteButton";

let imgs = null;
let svgsImages = null;

export default (props) => {
    const history = useHistory();
    const t = useTranslation();
    const navigation = useNavigation();
    const localAssets = useLocaleAsset();


    const dispatch = useDispatch();
    const match = useRouteMatch();
    const store = useSelector(state=>({
        newestMapIndex: state.newestMapIndex,
        soundVolume: state.soundVolume,
    }))

    const lang = match.params.lang ?? process.env.REACT_APP_DEFAULT_LANG;
    console.log(lang)

    const [preloader, setPreloader] = useState(false);
    const [preloaderHide, setPreloaderHide] = useState(false);

    const [showVideo, setShowVideo] = useState(true);

    const [allImages, setAllImages] = useState(0);
    const [isLoad, setIsLoad] = useState(0);

    const [showWelcome, setShowWelcome] = useState(false);
    const [showStarOne, setShowStarOne] = useState(false);
    const [showStarTwo, setShowStarTwo] = useState(false);
    const [showStarThree, setShowStarThree] = useState(false);
    const [showStarFour, setShowStarFour] = useState(false);
    const [showStarFive, setShowStarFive] = useState(false);

    const [showMovePopup, setShowMovePopup] = useState(false)

    const [showPopupStarInfoOne, setShowPopupStarInfoOne] = useState(false);
    const [showPopupStarInfoTwo, setShowPopupStarInfoTwo] = useState(false);
    const [showPopupStarInfoThree, setShowPopupStarInfoThree] = useState(false);

    const [comicCygaro, setComicCygaro] = useState(false);
    const [comicDetdom, setComicDetdom] = useState(false);
    const [comicOfficer, setComicOfficer] = useState(false);
    const [comicKasansaj, setComicKasansaj] = useState(false);
    const [comic3Wagons, setComic3Wagons] = useState(false);

    const [showInfo, setShowInfo] = useState(false);
    const [showArchive, setShowArchive] = useState(false);

    const [showWebsiteMap, setShowWebsiteMap] = useState(false);
    const [showMap, setShowMap] = useState(false)

    const soundPlum = useRef(new Audio(plum))
    const soundMap = useRef(new Audio(amnestySound))

    useMemo(() => {
        soundPlum.current.volume = (store.soundVolume/2)
    }, [])

    useEffect(() => {
        const idx = MapList.findIndex(i => i.route === match.path);
        if(idx !== 0 && idx > store.newestMapIndex) {
            dispatch(reducer.setNewestMapIndex(idx));
        }

        return () => {
            if (soundMap.current) {
                soundMap.current.pause();
                soundMap.current.currentTime = 0;
            }
          };
    }, [])

    useEffect(()=> {
        soundMap.current.volume = store.soundVolume
        soundPlum.current.volume = (store.soundVolume / 2)
    }, [store.soundVolume])

    useEffect(() => {
        if (showWelcome) {
            setPreloader(true)
            setPreloaderHide(false)

            imgs = [...document.querySelectorAll('img')];
            svgsImages = [...document.querySelectorAll('image')];
            setAllImages(imgs.length + svgsImages.length - 1)

            imgs.map((img)=> {
                img.addEventListener('load', handleOnLoad )
            })
            svgsImages.map((svgImage)=> {
                svgImage.addEventListener('load', handleOnLoad )
            })

        }
    }, [showWelcome])

    let helperIsLoad = 0;
    const handleOnLoad = () => {
        ++helperIsLoad;
        setIsLoad(helperIsLoad);
        if((imgs.length + svgsImages.length - 1) === helperIsLoad){
            setTimeout(()=> {
                setPreloaderHide(true)
            },2000)
            setTimeout(()=> {
                setPreloader(false)
            }, 2300)
        }

    }


    const handleNextMap = () => {
        setShowMap(false)
        musicMapStop()
        setTimeout(() => {
            history.push(navigation(Routes.MAP_ROAD));
        }, 500)
    }


    const HideWelcome = () => {
        setShowWelcome(false)
        setTimeout(() => {
            setShowStarOne(true)
            soundPlum.current.play()
        }, 500)
        setTimeout(() => {
            setShowStarTwo(true)
            soundPlum.current.play()

        }, 900)
        setTimeout(() => {
            setShowStarThree(true)
            soundPlum.current.play()

        }, 1300)
        setTimeout(() => {
            setShowStarFour(true)
            soundPlum.current.play()

        }, 1700)
        setTimeout(() => {
            setShowStarFive(true)
            soundPlum.current.play()

        }, 2100)
    }

    const musicMapStart = () => {
        soundMap.current.volume = store.soundVolume;
        soundMap.current.play();
        soundMap.current.loop = true;
        setTimeout(() => {
            if(store.soundVolume >= .1){
                soundMap.current.volume = .1;
            }
        }, 200)
        setTimeout(() => {
            if(store.soundVolume >= .3){
                soundMap.current.volume = .3;
            }
        }, 400)
        setTimeout(() => {
            if(store.soundVolume >= .5){
                soundMap.current.volume = .5;
            }
        }, 600)
        setTimeout(() => {
            if(store.soundVolume >= .8){
                soundMap.current.volume = .8;
            }
        }, 800)
        setTimeout(() => {
            if(store.soundVolume >= 1){
                soundMap.current.volume = 1;
            }
        }, 1000)
    }
    const musicMapStop = () => {
        setTimeout(() => {
            if(store.soundVolume >= .8){
                soundMap.current.volume = .8;
            }
        }, 200)
        setTimeout(() => {
            if(store.soundVolume >= .6){
                soundMap.current.volume = .6;
            }
        }, 400)
        setTimeout(() => {
            if(store.soundVolume >= .4){
                soundMap.current.volume = .4;
            }
        }, 600)
        setTimeout(() => {
            if(store.soundVolume >= .2){
                soundMap.current.volume = .2;
            }
        }, 800)
        setTimeout(() => {
            if(store.soundVolume >= .1){
                soundMap.current.volume = .1;
            }
            soundMap.current.pause();
        }, 1000)
    }

    const endVideo = () => {
        setShowVideo(false)
        setShowWelcome(true);
        musicMapStart()
        setTimeout(() => {
            setShowMap(true)
        }, 300)

        }

    const handleStartComicDetdom = () => {
        musicMapStop()
        setTimeout(()=> {
            setComicDetdom(true)
        }, 1000)
    }

    const handleStartComicKasansaj = () => {
        musicMapStop()
        setTimeout(()=> {
            setComicKasansaj(true)
        }, 1000)
    }

    const handleStartComic3Wagons = () => {
        musicMapStop()
        setTimeout(()=> {
            setComic3Wagons(true)
        }, 1000)
    }

    const handleStartComicCygaro = () => {
        musicMapStop()
        setTimeout(()=> {
            setComicCygaro(true)
        }, 1000)
    }

    const handleStartComicOfficer = () => {
        musicMapStop()
        setTimeout(()=> {
            setComicOfficer(true)
        }, 1000)
    }

    let mapClassName = ''
    if (showMap) {
        mapClassName += ' -active'
    }

    return (
        <>
            {!!preloader && (
                <Preloader
                    hide={preloaderHide}
                    countAll={allImages}
                    countOnLoad={isLoad}
                />
            )}
            {!showVideo ? (
                <div className="content">
                    <div className={`mapWrapper ${mapClassName}`}>

                        <div className="mapImg">
                            <img src={localAssets('img/maps/mapa_amnestia.jpg')} alt=""/>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" className="mapSvg">
                            <defs>
                            </defs>
                            <MuteButton
                                elProps={{}}
                                // handleOnSoundOn={musicMapStart}
                            />

                            <SvgCircle
                                elProps={{
                                    width: "66",
                                    height: "66",
                                    transform: "matrix(1 0 0 1 0 0)",
                                }}
                                img={require('../../assets/img/buttons/map_www_on.png').default}
                                imgHover={require('../../assets/img/buttons/map_www_off.png').default}
                                onClick={() => setShowWebsiteMap(true)}
                            />
                            <SvgCircle
                                elProps={{
                                    width: "126",
                                    height: "116",
                                    transform: "matrix(1 0 0 1 20 955)",

                                }}
                                img={require('../../assets/img/buttons/info_on.png').default}
                                imgHover={require('../../assets/img/buttons/info_off.png').default}
                                onClick={() => setShowInfo(true)}
                            />

                            <SvgCircle
                                elProps={{
                                    width: "136",
                                    height: "136",
                                    transform: "matrix(1 0 0 1 1780 0)",

                                }}
                                img={require('../../assets/img/buttons/forward_on.png').default}
                                imgHover={require('../../assets/img/buttons/forward_off.png').default}
                                onClick={() => handleNextMap()}

                            />

                            <SvgCircle
                                elProps={{
                                    width: "142",
                                    height: "116",
                                    transform: "matrix(1 0 0 1 150 955)",
                                }}
                                img={require('../../assets/img/buttons/archiw_on.png').default}
                                imgHover={require('../../assets/img/buttons/archiw_off.png').default}
                                onClick={() => setShowArchive(true)}
                            />


                            <SvgCircle
                                elProps={{
                                    width: "90",
                                    height: "84",
                                    transform: "matrix(1 0 0 1 912 470.0323)",
                                }}
                                img={require('../../assets/img/buttons/star_yellow_info_on.png').default}
                                imgHover={require('../../assets/img/buttons/star_yellow_info_off.png').default}
                                onClick={() => setShowPopupStarInfoOne(true)}
                            />

                            <SvgCircle
                                elProps={{
                                    width: "90",
                                    height: "84",
                                    transform: "matrix(1 0 0 1 1112 508.0323)",
                                }}
                                img={require('../../assets/img/buttons/star_yellow_info_on.png').default}
                                imgHover={require('../../assets/img/buttons/star_yellow_info_off.png').default}
                                onClick={() => setShowPopupStarInfoTwo(true)}
                            />

                            <SvgCircle
                                elProps={{
                                    width: "90",
                                    height: "84",
                                    transform: "matrix(1 0 0 1 500 878.0323)",
                                }}
                                img={require('../../assets/img/buttons/star_yellow_info_on.png').default}
                                imgHover={require('../../assets/img/buttons/star_yellow_info_off.png').default}
                                onClick={() => setShowPopupStarInfoThree(true)}
                            />

                            <SvgCircle
                                elProps={{
                                    width: "152",
                                    height: "134",
                                    className: `amnestyStarOne ${showStarOne ? '-active' : ''}`,
                                }}
                                img={require('../../assets/img/buttons/star_1_off.png').default}
                                imgHover={require('../../assets/img/buttons/star_1_on.png').default}
                                imgVisited={require('../../assets/img/buttons/star_1_visited.png').default}
                                nameComic={'ComicAmnestyOficer'}
                                onClick={handleStartComicOfficer}
                            />

                            <SvgCircle
                                elProps={{
                                    width: "152",
                                    height: "134",
                                    className: `amnestyStarTwo ${showStarTwo ? '-active' : ''}`,
                                }}
                                img={require('../../assets/img/buttons/star_2_off.png').default}
                                imgHover={require('../../assets/img/buttons/star_2_on.png').default}
                                imgVisited={require('../../assets/img/buttons/star_2_visited.png').default}
                                nameComic={'ComicAmnestyWagony'}
                                onClick={handleStartComic3Wagons}

                            />


                            <SvgCircle
                                elProps={{
                                    width: "152",
                                    height: "134",
                                    className: `amnestyStarThree ${showStarThree ? '-active' : ''}`,
                                }}
                                img={require('../../assets/img/buttons/star_3_off.png').default}
                                imgHover={require('../../assets/img/buttons/star_3_on.png').default}
                                imgVisited={require('../../assets/img/buttons/star_3_visited.png').default}
                                nameComic={'ComicAmnestyKasansaj'}
                                onClick={handleStartComicKasansaj}

                            />

                            <SvgCircle
                                elProps={{
                                    width: "152",
                                    height: "134",
                                    className: `amnestyStarFour ${showStarFour ? '-active' : ''}`,
                                }}
                                img={require('../../assets/img/buttons/star_4_off.png').default}
                                imgHover={require('../../assets/img/buttons/star_4_on.png').default}
                                imgVisited={require('../../assets/img/buttons/star_4_visited.png').default}
                                nameComic={'ComicAmnestyCygaro'}
                                onClick={handleStartComicCygaro}

                            />

                            <SvgCircle
                                elProps={{
                                    width: "152",
                                    height: "134",
                                    className: `amnestyStarFive ${showStarFive ? '-active' : ''}`,
                                }}
                                img={require('../../assets/img/buttons/star_5_off.png').default}
                                imgHover={require('../../assets/img/buttons/star_5_on.png').default}
                                imgVisited={require('../../assets/img/buttons/star_5_visited.png').default}
                                nameComic={'ComicAmnestyDetdom'}
                                onClick={handleStartComicDetdom}
                            />

                        </svg>

                        <PrimaryPopup
                            onClick={HideWelcome}
                            visible={showWelcome}
                            className='-amnestyWelcome'
                            img={localAssets('img/popup/amnesty/amnesty_welcome.png')}
                        >
                        </PrimaryPopup>

                        <PrimaryPopup
                            onClick={() => setShowPopupStarInfoOne(false)}
                            visible={showPopupStarInfoOne}
                            className='-amnestyStarInfoOne'
                            img={localAssets('img/popup/amnesty/amnestia_anders.png')}
                        >
                        </PrimaryPopup>


                        <PrimaryPopup
                            onClick={() => setShowPopupStarInfoTwo(false)}
                            visible={showPopupStarInfoTwo}
                            className='-amnestyStarInfoTwo'
                            img={localAssets('img/popup/amnesty/amnestia_zborne.png')}
                        >
                        </PrimaryPopup>

                        <PrimaryPopup
                            onClick={() => setShowPopupStarInfoThree(false)}
                            visible={showPopupStarInfoThree}
                            className='-amnestyStarInfoThree'
                            img={localAssets('img/popup/amnesty/amnestia_aszchabad.png')}
                        >
                        </PrimaryPopup>

                        <PrimaryPopup
                            onClick={() => setShowInfo(false)}
                            visible={showInfo}
                            className='-amnestyInfo'
                            img={localAssets('img/popup/amnesty/amnestia_info.png')}
                        >
                        </PrimaryPopup>

                        {!!comicCygaro && (
                            <ComicBase
                                visible={isVisible => setComicCygaro(isVisible)}
                                file={ComicAmnestyCygaro}
                                musicStart={musicMapStart}
                            />
                        )}

                        {!!comicDetdom && (
                            <ComicBase
                                visible={isVisible => setComicDetdom(isVisible)}
                                file={lang === "hi" ? ComicAmnestyDetdomHi : lang === "gu" ? ComicAmnestyDetdomGu : ComicAmnestyDetdom}
                                musicStart={musicMapStart}
                                functionNextMap={handleNextMap}
                                nextMap="true"
                            />
                        )}


                        {!!comicOfficer && (
                            <ComicBase
                                visible={isVisible => setComicOfficer(isVisible)}
                                file={ComicAmnestyOficer}
                                musicStart={musicMapStart}
                            />
                        )}

                        {!!comicKasansaj && (
                            <ComicBase
                                visible={isVisible => setComicKasansaj(isVisible)}
                                file={ComicAmnestyKasansaj}
                                musicStart={musicMapStart}
                            />
                        )}

                        {!!comic3Wagons && (
                            <ComicBase
                                visible={isVisible => setComic3Wagons(isVisible)}
                                file={ComicAmnestyWagony}
                                musicStart={musicMapStart}
                            />
                        )}

                        {/*<MoveElementsPopup visible={showMovePopup} onExit={() => setShowMovePopup(false)}/>*/}



                        <FullScreenPopup
                            image={localAssets('img/popup/amnesty/archive.png')}
                            onClick={() => setShowArchive(false)}
                            direction="left"
                            visible={showArchive}
                        />

                        <WebsiteMapPopup
                            onClick={() => setShowWebsiteMap(false)}
                            visible={showWebsiteMap}
                            className="-mapWebsite"
                            musicStop={musicMapStop}
                            onShowVideo={() => setShowVideo(true)}
                        />


                    </div>


                </div>
            ) : (

                <PrimaryVideo
                    video={localAssets('video/amnestia.mp4')}
                    onEnded={() => endVideo()}
                    class={showVideo ? '' : ''}
                    onSkip={() => endVideo()}
                />


            )}
        </>
    )
};
