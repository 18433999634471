import React from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useLocaleAsset, useNavigation} from "../../misc/Hooks";
import PrimaryVideo from "../misc/PrimaryVideo";
import Routes from "../../utils/Routes";

export default (props) => {
    const history = useHistory();
    const navigation = useNavigation();
    const localAssets = useLocaleAsset()

    const dispatch = useDispatch();
    const match = useRouteMatch();
    const store = useSelector(state=>({
        newestMapIndex: state.newestMapIndex
    }))


    const endVideo = () => {
        setTimeout(() => {
            history.push(navigation(Routes.MAP_HOUSING_ESTATE));
        }, 500)
    }
    return (


          <>
              <PrimaryVideo
              video={localAssets('video/osiedle.mp4')}
              onEnded={() => endVideo()}
              class=''
              onSkip={() => endVideo()}
              />
          </>


    )
}
