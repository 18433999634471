import React, {useState} from "react";
import {useTranslation} from "../../misc/Hooks";


interface Props {
    onEnded: any,
    video: any,
    onSkip: any,
    class: any,

}


export default (props: Props) => {
    // const [showVideo, setShowVideo] = useState(false);
    const t = useTranslation();

    const onLoadVideo = ()=> {
    }

    return (

        <>

            <div className={`videoPopupWrapper ${props.class}`}>
                <video
                    controls
                    // className={props.class}
                    preload="auto"
                    height="100%"
                    onEnded={props.onEnded}
                >
                    <source
                        src={props.video}
                        type='video/mp4'
                    />
                </video>
                <img src={require('../../assets/img/popup/housing-estate/video-popup-2.jpg').default} alt="" className="frame"/>
                <button className="exitVideoPopup" onClick={props.onSkip}/>

            </div>

        </>

    )
}
