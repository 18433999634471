

const Error404 = (props) => {
    return (
        <div style={{color: 'white', textAlign: 'center'}}>
            <h1>404</h1>
            <h3>Page Not Found</h3>
        </div>
    )
}


export default Error404;
