export default {
    NAME: 'ComicHousingEstateZieloneMalpy',

    COMIC_IMAGES_LIST: [
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_01.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_02.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_03.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_04.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_05.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_06.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_07.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_08.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_09.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_10.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_11.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_12.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_13.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_14.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_15.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_16.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_17.jpg',
        },
        {
            img: 'img/comics/housing-estate/szlak_1_04_Zielone_Malpy/szlak_1_04_Zielone_Malpy_18.jpg',
        },

    ],
    COMIC_SOUNDS_LIST: [


        {
            id: 1,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_Gusty_House.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_Gusty_House.mp3').default,
            from: 4,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_Gusty_House.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_Gusty_House.mp3').default,
            from:6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_Gusty_House.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_Gusty_House.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_Gusty_House.mp3').default,
            from: 9,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_Gusty_House.mp3').default,
            from: 11,
            to: 12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_Gusty_House.mp3').default,
            from: 12,
            to: 13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_DesertRuralDay_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_DesertRuralDay_02.mp3').default,
            from: 10,
            to: 11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_DesertRuralDay_03.mp3').default,
            from: 15,
            to: 17,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_DesertRuralDay_04.mp3').default,
            from: 13,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },

        {
            id: 14,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_DesertRuralDaywithCicadas_01.mp3').default,
            from: 14,
            to: 15,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_DesertWind_01.mp3').default,
            from: 0,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_RainyDay_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtA_RoomTone_01.mp3').default,
            from: 17,
            to: 18,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_AtB_Rain_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 19,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_FxA_CoughMan_01.mp3').default,
            from: 13,
            to: 14,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 20,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_FxA_Plate_01.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 21,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_FxA_Plate_02.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_FxA_Plates_01.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_FxA_TelephoneSentence_01.mp3').default,
            from: 17,
            to: 18,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 24,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_FxB_DoorOpening_01.mp3').default,
            from: 12,
            to: 13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 25,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_FxB_SpinachSplash_01.mp3').default,
            from: 10,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 26,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_FxB_SpinachWhoosh_01.mp3').default,
            from: 13,
            to: 14,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 27,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_FxB_Thunder_01.mp3').default,
            from: 2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 28,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_Mx_01_OddzialBojowy.mp3').default,
            from: 1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 29,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_Mx_02_Akcja.mp3').default,
            from: 4,
            to: 12,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 30,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_Mx_03_GroznyAkcent.mp3').default,
            from: 12,
            to: 13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 31,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_Mx_04_ToByłaDobraAkcja.mp3').default,
            from: 14,
            to: 17,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 32,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_VO_ChildrenCrunching_01.mp3').default,
            from: 16,
            to: 17,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 33,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_VO_ChildrenDisgusted_01.mp3').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 34,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_VO_ChildrenDisgusted_02.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 35,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_VO_ChildrenEatingRumour_Loop_01.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 36,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_VO_ChildrenLaughing_01.mp3').default,
            from: 10,
            to: 12,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 37,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_VO_ChildrenLaughing_04.mp3').default,
            from: 11,
            to: 12,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 38,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_VO_ChildrenLaughing_05.mp3').default,
            from: 11,
            to: 12,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 39,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_VO_ChildrenPsst_01.mp3').default,
            from: 15,
            to: 16,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 41,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_VO_ChildrenLaughing_Loop_01.mp3').default,
            from: 9,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 42,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_VO_Ciiii_01.mp3').default,
            from: 0,
            to: 1,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 43,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_VO_Cutlery_Loop_01.mp3').default,
            from: 3,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 44,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_VO_Cutlery_Loop_02.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 45,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_VO_Names_01.mp3').default,
            from: 1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 46,
            file: require('../assets/sounds/comics/housing-estate/zielone-malpy/TBB_Szlak_01_ZieloneMalpy_VO_YUP_01.mp3').default,
            from: 9,
            to: 10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },









    ],
}
