import React, {useEffect, useState} from "react";
import {BasePopup} from "./_index";
import {useDispatch, useSelector} from "react-redux";
import * as reducer from "../../store/reducer";


interface Props {
    visible: boolean,
    audio: any,
    onClick: any,
    className?: any,
    img: any,
    imgPlayer: any,
    namePopup: any,

}

let audio = null;

export default (props: Props) => {


    const store = useSelector(state => ({
        soundVolume: state.soundVolume,
        visitedComics: state.visitedComics,
    }))
    const dispatch = useDispatch()

    const [isPlay, setIsPlay] = useState(true)
    const [active, setActive] = useState(false)
    const [opacity, setOpacity] = useState(false)

    useEffect(() => {

    }, [])

    useEffect(() => {

        if (audio) {
            audio.volume = store.soundVolume
        }
    }, [store.soundVolume])


    useEffect(() => {


        if (!props.visible) {
            audio?.pause()
            audio = null;
            setIsPlay(false);
        }

        if (props.visible) {
            audio = new Audio(props.audio)
            audio.onended = setIsPlay(false)
            audio.volume = store.soundVolume
            audio.play()

            setActive(true)
            setTimeout(() => {
                setOpacity(true)
            }, 100)
        }

        // handleAudio()

    }, [props.visible])


    if (props.namePopup && props.visible) {
        const arr = [...store.visitedComics]

        const idx = arr.findIndex(i => i === props.namePopup)
        if (idx === -1) {
            arr.push(props.namePopup)
            dispatch(reducer.setVisitedComics(arr))
        }
    }

    // const handleAudio = () => {
    //     if (isPlay) {
    //         setIsPlay(false);
    //         audio.pause();
    //     } else {
    //         setIsPlay(true);
    //         audio.play();
    //     }
    // }
    const closeComic = () => {
        props.onClick();
        setOpacity(false)
        setTimeout(() => {
            setActive(false)
        }, 400)

    }


    return (


        <>
            <div className={`popup fade -active ${opacity ? 'opacity' : ''} ${!!active && 'di-block'}`}>
                <div className={`primaryPopup ${props.className}`} onClick={closeComic}>
                    <div className="primaryPopup__text">
                        <img src={props.img} alt=""/>
                        {props.children}
                    </div>
                </div>
            </div>
        </>

    )


}
