import {Preloader, SvgCircle} from "../../misc/_index";
import React, {useEffect, useState} from "react";
import {WebsiteMapPopup} from "../../popup/_index";
import ComicHousingEstateBoles from "../../../utils/ComicHousingEstateBoles";
import {ComicBase} from "../../comics/ComicBase";
import Routes from "../../../utils/Routes";
import plum from "../../../assets/sounds/effects/plum.mp3";
import {useSelector} from "react-redux";
import {useLocaleAsset, useNavigation} from "../../../misc/Hooks";
import {useHistory} from "react-router-dom";
import MuteButton from "../../misc/MuteButton";
let soundPlum = null;


interface Props {
    onClickNavBraveBrunch: void,
    onClickNavLifeInTheEstate: void,
    onClickNight: void,
    onClickWonderfulYears: void,

    onClickSun: void,
    onClickArchive: void,
    onClickInfo: void,

    musicMapOn: void,
    musicMapOff: void,
}

export default (props: Props) => {

    const localePath = useLocaleAsset();
    const navigation = useNavigation();
    const history = useHistory();

    const store = useSelector(state=>({
        soundVolume: state.soundVolume,
    }))
    const [preloader, setPreloader] = useState(false);
    const [preloaderHide, setPreloaderHide] = useState(false);

    const [showWebsiteMap, setShowWebsiteMap] = useState(false)
    const [showStarOne, setShowStarOne] = useState(false);
    const [comicBoles, setComicBoles] = useState(false);
    const [showMap, setShowMap] = useState(false)

    useEffect(() => {
        // setShowWelcome(true);

        soundPlum = new Audio(plum)
        soundPlum.volume = (store.soundVolume/2)

        setTimeout(() => {
            setShowMap(true)
        }, 500)
        setTimeout(() => {
            setShowStarOne(true)
            soundPlum.play()
        }, 1000)

    }, [])

    useEffect(()=> {
        soundPlum.volume = store.soundVolume;
    }, [store.soundVolume])

    const handleNextMap = () => {
        setShowMap(false)
        props.musicMapOff();
        setTimeout(() => {
            history.push(navigation(Routes.END_WAR));
        }, 500)
    }

    const handleComicStartBoles = () => {
        props.musicMapOff()
        setTimeout(()=> {
            setComicBoles(true)
        }, 1000)
    }

    let mapClassName = ''
    if (showMap) {
        mapClassName += ' -active'
    }


    return (
        <>
            {!!preloader && (
                <Preloader hide={preloaderHide}/>
            )}
            <div className="content">

                <div
                    className={`mapWrapper ${mapClassName}`}
                >

                    <div className="mapImg">
                        <img src={localePath('img/maps/mapa_osiedle_noc.jpg')} alt=""/>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" className="mapSvg">
                        <defs>
                        </defs>
                        <MuteButton
                            elProps={{}}
                        />
                        <SvgCircle
                            elProps={{
                                width: "216",
                                height: "50",
                                transform: "matrix(1 0 0 1 225 827)",
                            }}
                            img= {localePath('img/buttons/housing-estate/camp_night_fajnaferajna_on.png')}
                           imgHover= {localePath('img/buttons/housing-estate/camp_night_fajnaferajna_off.png')}
                            onClick={props.onClickNavBraveBrunch}
                        />

                        <SvgCircle
                            elProps={{
                                width: "216",
                                height: "56",
                                transform: "matrix(1 0 0 1 225 770)",
                            }}
                            img= {localePath('img/buttons/housing-estate/camp_night_zyciewosiedlu_on.png')}
                           imgHover= {localePath('img/buttons/housing-estate/camp_night_zyciewosiedlu_off.png')}
                            onClick={props.onClickNavLifeInTheEstate}
                        />

                        <SvgCircle
                            elProps={{
                                width: "216",
                                height: "50",
                                transform: "matrix(1 0 0 1 225 875)",
                            }}
                            img= {localePath('img/buttons/housing-estate/camp_night_cudownelata_on.png')}
                           imgHover= {localePath('img/buttons/housing-estate/camp_night_cudownelata_off.png')}
                            onClick={props.onClickWonderfulYears}
                        />

                        <SvgCircle
                            elProps={{
                                width: "216",
                                height: "56",
                                transform: "matrix(1 0 0 1 225 920)",
                            }}
                            img= {localePath('img/buttons/housing-estate/camp_night_noc_on.png')}
                           imgHover= {localePath('img/buttons/housing-estate/camp_night_noc_on.png')}
                            onClick={props.onClickNight}
                        />

                        <SvgCircle
                            elProps={{
                                width: "66",
                                height: "66",
                                transform: "matrix(1 0 0 1 0 0)",
                            }}
                            img= {localePath('img/buttons/map_www_on.png')}
                           imgHover= {localePath('img/buttons/map_www_off.png')}
                            onClick={() => setShowWebsiteMap(true)}

                        />

                        <SvgCircle
                            elProps={{
                                width: "52",
                                height: "52",
                                className: `nightStarOne ${showStarOne ? '-active' : ''}`,
                            }}
                            img= {localePath('img/buttons/camp_night_button_on.png')}
                           imgHover= {localePath('img/buttons/camp_night_button_off.png')}
                            onClick={handleComicStartBoles}
                        />

                        <SvgCircle
                            elProps={{
                                width: "200",
                                height: "200",
                                transform: "matrix(1 0 0 1 1700 0)",

                            }}
                            img={require('../../../assets/img/buttons/housing-estate/camp_forward_noc_on.png').default}
                            imgHover={require('../../../assets/img/buttons/housing-estate/camp_forward_noc_off.png').default}
                            onClick={handleNextMap}
                        />

                    </svg>


                    <WebsiteMapPopup
                        onClick={() => setShowWebsiteMap(false)}
                        visible={showWebsiteMap}
                        className="-mapWebsite"
                        musicStop={props.musicMapOff}


                    />

                    {!!comicBoles && (
                        <ComicBase
                            visible={isVisible => setComicBoles(isVisible)}
                            file={ComicHousingEstateBoles}
                            musicStart={props.musicMapOn}
                        />
                    )}

                </div>

            </div>
        </>

    )
}