import React, {useEffect, useRef, useState} from "react";
import {Preloader, PrimaryVideo, SvgCircle} from "../misc/_index";
import list from "../../assets/video/list.mp4";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useLocaleAsset, useNavigation, useTranslation} from "../../misc/Hooks";
import Routes from "../../utils/Routes";
import {useDispatch, useSelector} from "react-redux";
import MapList from "../../utils/MapList";
import * as reducer from "../../store/reducer";
import letterSound from "../../assets/sounds/maps/TBB_Szlak_01_Barak15_Mx_01_VER_02_Longer_01.mp3"
import {WebsiteMapPopup} from "../popup/_index";
import plum from "../../assets/sounds/effects/plum.mp3";
import MuteButton from "../misc/MuteButton";


export default (props) => {
    const history = useHistory();
    const t = useTranslation();
    const navigation = useNavigation();
    const localeAssets = useLocaleAsset()
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const store = useSelector(state=>({
        newestMapIndex: state.newestMapIndex,
        soundVolume: state.soundVolume,
    }))
    const [isLoad, setIsLoad] = useState(0);
    const [preloader, setPreloader] = useState(false);
    const [preloaderHide, setPreloaderHide] = useState(false);
    const [showWebsiteMap, setShowWebsiteMap] = useState(false);

    const [animationLetter, setAnimationLetter] = useState(true)
    const [boardWords, setBoardsWords] = useState(false)
    const [mapShow, setMapShow] = useState(true)

    const soundMap = useRef(new Audio(letterSound))

    
    useEffect(() => {
        soundMap.current.loop = true;

        const idx = MapList.findIndex(i => i.route === match.path);
        if(idx !== 0 && idx > store.newestMapIndex) {
            dispatch(reducer.setNewestMapIndex(idx));
        }
        return () => {
            if (soundMap.current) {
                soundMap.current.pause();
                soundMap.current.currentTime = 0;
            }
          };
        // dispatch(reducer.setLastVisitedMap(match.url))


    }, [])

    useEffect(()=> {
        soundMap.current.volume = store.soundVolume
    }, [store.soundVolume])

    const endVideo = () => {

        setPreloader(true)
        setPreloaderHide(false)
        musicMapStart()

        setAnimationLetter(false)
        setBoardsWords(true)

    }

    const handleNextMap = () => {
        setMapShow(false);
        musicMapStop()
        setTimeout(()=> {
            history.push(navigation(Routes.I_WAS_HERE))
        }, 500)
    }

    let helperIsLoad = 0;
    const hidePreloader = () => {
        ++helperIsLoad;
        setIsLoad(helperIsLoad);
        if((helperIsLoad) === 1){
            setTimeout(()=> {
                setPreloaderHide(true)
            },2000)
            setTimeout(()=> {
                setPreloader(false)
            }, 2300)
        }
    }




    const musicMapStart = () => {
        soundMap.current.volume = store.soundVolume;
        soundMap.current.play();
        soundMap.current.loop = true;
        setTimeout(() => {
            if(store.soundVolume >= .1){
                soundMap.current.volume = .1;
            }
        }, 200)
        setTimeout(() => {
            if(store.soundVolume >= .3){
                soundMap.current.volume = .3;
            }
        }, 400)
        setTimeout(() => {
            if(store.soundVolume >= .5){
                soundMap.current.volume = .5;
            }
        }, 600)
        setTimeout(() => {
            if(store.soundVolume >= .8){
                soundMap.current.volume = .8;
            }
        }, 800)
        setTimeout(() => {
            if(store.soundVolume >= 1){
                soundMap.current.volume = 1;
            }
        }, 1000)
    }
    const musicMapStop = () => {
        setTimeout(() => {
            if(store.soundVolume >= .8){
                soundMap.current.volume = .8;
            }
        }, 200)
        setTimeout(() => {
            if(store.soundVolume >= .6){
                soundMap.current.volume = .6;
            }
        }, 400)
        setTimeout(() => {
            if(store.soundVolume >= .4){
                soundMap.current.volume = .4;
            }
        }, 600)
        setTimeout(() => {
            if(store.soundVolume >= .2){
                soundMap.current.volume = .2;
            }
        }, 800)
        setTimeout(() => {
            if(store.soundVolume >= .1){
                soundMap.current.volume = .1;
            }
            soundMap.current.pause();
        }, 1000)
    }


    return (

        <div className="helperBg">
            {!!preloader && (
                <Preloader
                    hide={preloaderHide}
                    countAll="1"
                    countOnLoad={isLoad}
                />
            )}


            {!!animationLetter && (
                <PrimaryVideo
                    video={localeAssets('video/list.mp4')}
                    onEnded={endVideo}
                    onSkip={endVideo}
                />
            )}
            {!!boardWords && (

                <div className="content">

                    <div className={`mapWrapper ${mapShow ? '-active ' : ''}` } >

                        <div className="mapImg">
                            <img src={localeAssets('img/maps/slowa_wiesia.jpg')} alt="" onLoad={hidePreloader}/>
                            <div className="mapImg__text">
                                {/*<p dangerouslySetInnerHTML={{__html: t('textLetterBoard_1')}}/>*/}
                                {/*<span>{t('textLetterBoard_2')}</span>*/}
                            </div>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" className="mapSvg">
                            <defs>
                            </defs>
                            <MuteButton
                                elProps={{}}
                                // handleOnSoundOn={musicMapStart}
                            />
                            <SvgCircle
                                elProps={{
                                    width: "66",
                                    height: "66",
                                    transform: "matrix(1 0 0 1 0 0)",
                                }}
                                img={require('../../assets/img/buttons/map_www_on.png').default}
                                imgHover={require('../../assets/img/buttons/map_www_off.png').default}
                                onClick={() => setShowWebsiteMap(true)}

                            />

                            <SvgCircle
                                elProps={{
                                    width: "176",
                                    height: "176",
                                    transform: "matrix(1 0 0 1 1720 30)",

                                }}
                                img={require('../../assets/img/buttons/camp_forward_on.png').default}
                                imgHover={require('../../assets/img/buttons/camp_forward_off.png').default}
                                onClick={() => handleNextMap()}
                            />
                        </svg>
                        <WebsiteMapPopup
                            onClick={() => setShowWebsiteMap(false)}
                            visible={showWebsiteMap}
                            className="-mapWebsite"
                            musicStop={musicMapStop}

                        />
                    </div>

                </div>


            )}


        </div>


    )
}
