import React from "react";
import {ComicWrapper} from "./Comic";


interface Props {
    file: any,
    visible: void,
    musicStart: void,
    nextMap?: boolean;
    functionNextMap: void,
    exitButton: void,
    afterEnd: void,

}


export const ComicBase = (props: Props) => {


    return (



            <ComicWrapper visible={props.visible} file={props.file} musicStart={props.musicStart} nextMap={props.nextMap} functionNextMap={props.functionNextMap} exitButton={props.exitButton} afterEnd={props.afterEnd}/>


    )
}
