import {BasePopup} from "./_index";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import * as reducer from "../../store/reducer";


interface Props {
    onClick: any,
    className?: any,
    img: any,
    namePopup: any,
}

export default (props: Props) => {
    const dispatch = useDispatch()
    const store = useSelector(state => ({
        visitedComics: state.visitedComics,
    }))

    if(props.namePopup && props.visible){
        const arr = [...store.visitedComics]

        const idx = arr.findIndex(i => i === props.namePopup)
        if (idx === -1){

            arr.push(props.namePopup)
            dispatch(reducer.setVisitedComics(arr))
        }
    }

    return (

        <BasePopup direction="top" visible={props.visible}>
        <div className={`primaryPopup ${props.className}`} onClick={props.onClick}>
            <div className="primaryPopup__text">
                <img src={props.img} alt=""/>
                {props.children}
            </div>
        </div>
    </BasePopup>


    )

}
