import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useNavigation, useTranslation} from "../misc/Hooks";
import Routes from "../utils/Routes";
import {Preloader} from "./misc/_index";


export default (props) => {

    const t = useTranslation();
    const navigation = useNavigation();
    const [preloader, setPreloader] = useState(true);
    const [preloaderHide, setPreloaderHide] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setPreloaderHide(true)
        }, 300)
        setTimeout(() => {
            setPreloader(false)
        }, 600)

    }, [])

    return (
        <>
            {!!preloader && (
                <Preloader hide={preloaderHide}/>
            )}

            <div className="sponsorsPage">
                <Link to={navigation(Routes.HOME)} className="pagesExit">
                    <img src={require('../assets/img/buttons/exit.png').default} alt=""/>
                    {t("sponsors")}
                </Link>

                <div className="container -small">
                    <div className="sponsorsPage__items">
                        <div className="sponsorsPage__item">
                            <span className="sponsorsPage__rule">{t("sponsorsPatron")}</span>
                            <a href="https://www.fundacjapkobp.pl/" target="_blank" className="sponsorsPage__logoWrapper">
                                <img src={require('../assets/img/start/sponsors/PKO.png').default} alt=""/>
                            </a>
                            <span className="sponsorsPage__name">
                                {t("sponsorsPKO")}
                            </span>
                        </div>

                        <div className="sponsorsPage__item">
                            <span className="sponsorsPage__rule">{t("sponsor")}</span>
                            <a href="https://www.gujarattourism.com/" target="_blank" className="sponsorsPage__logoWrapper">
                                <img src={require('../assets/img/start/sponsors/GUJARAT_TOYRISM.png').default} alt=""/>
                            </a>
                            <span className="sponsorsPage__name">
                               {t('gujarat_tourism')}
                            </span>
                        </div>

                        <div className="sponsorsPage__item">
                            <span className="sponsorsPage__rule">{t("sponsor")}</span>
                            <a href="https://www.orlen.pl/" target="_blank" className="sponsorsPage__logoWrapper">
                                <img src={require('../assets/img/start/sponsors/ORLEN.png').default} alt=""/>
                            </a>
                            <span className="sponsorsPage__name">
                                {t('pkn_orlen')}
                            </span>
                        </div>
                    </div>

                    <span className="sponsorsPage__rule -solo">{t("partners")}</span>

                    <div className="sponsorsPage__items">
                        <div className="sponsorsPage__item">
                            <a href="https://www.facebook.com/PolishEmbassyNewDelhi/" target="_blank" className="sponsorsPage__logoWrapper">
                                <img src={require('../assets/img/start/sponsors/AMBASADA_POLSKI_W_NEW_DELHI.png').default} alt=""/>
                            </a>
                                <span className="sponsorsPage__name" dangerouslySetInnerHTML={{__html: t('polishEmbassy')}} />
                        </div>

                        <div className="sponsorsPage__item">
                            <a href="https://instytutpolski.pl/" target="_blank" className="sponsorsPage__logoWrapper">
                                <img src={require('../assets/img/start/sponsors/INSTYTUT_POLSKI.png').default} alt=""/>
                            </a>
                            <span className="sponsorsPage__name">
                                 {t("polishInstitute")}
                            </span>
                        </div>

                        <div className="sponsorsPage__item">
                            <a href="https://www.indianembassywarsaw.gov.in/" target="_blank" className="sponsorsPage__logoWrapper">
                                <img src={require('../assets/img/start/sponsors/AMBASADA_INDII_W_WARSZAWIE.png').default} alt=""/>
                            </a>
                            <span className="sponsorsPage__name" dangerouslySetInnerHTML={{__html: t('indiaEmbassy')}} />
                        </div>
                    </div>

                    <div className="sponsorsPage__items">

                        <div className="sponsorsPage__item">
                            <span className="sponsorsPage__rule"></span>
                            <div className="sponsorsPage__logoWrapper">
                                <img src={require('../assets/img/start/sponsors/logo_stypendium.jpg').default} alt=""/>
                            </div>
                            <span className="sponsorsPage__name" dangerouslySetInnerHTML={{__html: t('stypendium')}}/>
                        </div>


                        <div className="sponsorsPage__item">
                        <span className="sponsorsPage__rule">{t("sponsor")}</span>
                            <a href="https://historia.tvp.pl/" target="_blank" className="sponsorsPage__logoWrapper">
                                <img src={require('../assets/img/start/sponsors/logo_tvp_historia.jpg').default}
                                     alt=""/>
                            </a>
                            <span className="sponsorsPage__name" dangerouslySetInnerHTML={{__html: t('tvp')}}/>
                        </div>
                    </div>

                </div>
            </div>

        </>

    )
}
