

export default {
    NAME: 'ComicHousingEstateMaharadza',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_03.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_04.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_05.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_06.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_07.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_09.jpg',
        },
        /*{
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_10.jpg',
        },*/
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_11.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_12.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_13.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_14.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_15.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_16.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_17.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_18.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_3_01_Maharadza/szlak_3_01_Maharadza_19.jpg',
        },


    ],
    COMIC_SOUNDS_LIST: [
        {
            id: 1,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtA_ArabKidsPlaying_01.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtA_ArabKidsPlaying_02.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtA_DesertArea_01.mp3').default,
            from: 0,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtA_DesertRuralDay_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtA_DesertRuralDay_02.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtA_DesertRuralDay_03.mp3').default,
            from: 3,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },

        {
            id: 7,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtA_DesertRuralDay_03.mp3').default,
            from: 5,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtA_DesertRuralDay_03.mp3').default,
            from: 9,
            to: 11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtA_DesertRuralDay_03.mp3').default,
            from: 10,
            to: 12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtA_DesertRuralDay_03.mp3').default,
            from: 13,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        /*{
            id: 11,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtA_DesertRuralDay_04.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
        {
            id: 12,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtA_DesertRuralDay_04.mp3').default,
            from: 12,
            to: 13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtA_DesertRuralDay_04-03.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtA_NightintheCity_01.mp3').default,
            from: 14,
            to: 15,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },

        {
            id: 15,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtA_Roomtone_01.mp3').default,
            from: 15,
            to: 17,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },

        {
            id: 16,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_FxA_DogBreathing_01.mp3').default,
            from: 16,
            to: 17,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_FxA_Flag_01.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_FxA_Flag_02.mp3').default,
            from: 9,
            to: 10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        /*{
            id: 19,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_FxA_Flag_03.mp3').default,
            from: 10,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
        {
            id: 20,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_FxA_LeatherandMetal_01.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 21,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_FxA_Razor_01.mp3').default,
            from: 2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_FxA_WorkinginGarden_01.mp3').default,
            from: 1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_Mx_01_GwizdaniePrzyPracy.mp3').default,
            from: 0,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 24,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_Mx_02_Akcent.mp3').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 25,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_Mx_03_Krakowiak.mp3').default,
            from: 10,
            to: 17,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        /*{
            id: 26,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_Mx_04_WStrojuKrakowiaka.mp3').default,
            from: 16,
            to: 18,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
        {
            id: 27,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_VO_Whistling_02.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },

        {
            id: 28,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_FxA_VolvoPV544andHorn_01.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 29,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_FxA_VolvoPV544_01-02.mp3').default,
            from: 7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        /*{
            id: 30,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_FxA_VolvoPV544Engine_01.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 31,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_FxA_Horn_01.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 500,
            volume: 1,
            onEndSound: false
        },*/
        {
            id: 32,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_AtB_KidsTalking_01.mp3').default,
            from: 8,
            to: 11,
            loop: false,
            delay: 200,
            volume: 1,
            onEndSound: false
        },
        {
            id: 33,
            file: require('../assets/sounds/comics/housing-estate/maharadza/TBB_Szlak_03_Maharadza_VO_Whistling_01.mp3').default,
            from: 0,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },



    ],
}
