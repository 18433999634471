import {PopupVideo, Preloader, PrimaryVideo, SvgCircle} from "../../misc/_index";
import React, {useEffect, useState} from "react";
import {HousingEstatePopups, MoveElementsPopup, PrimaryPopup, WebsiteMapPopup} from "../../popup/_index";
import {HousingEstateNav} from "../../nav/_index";
import {Route, useHistory} from "react-router-dom";
import Routes from "../../../utils/Routes";
import archiveVideo from "../../../assets/video/granica.mp4"
import {ComicBase} from "../../comics/ComicBase";
import ComicHousingEstateMaharadza from "../../../utils/ComicHousingEstateMaharadza";
import ComicHousingEstatePrezent from "../../../utils/ComicHousingEstatePrezent";
import ComicHousingEstateKapiele from "../../../utils/ComicHousingEstateKapiele";
import ComicHousingEstateRiki from "../../../utils/ComicHousingEstateRiki";
import ComicHousingEstateCiemnia from "../../../utils/ComicHousingEstateCiemnia";
import ComicHousingEstateWyscig from "../../../utils/ComicHousingEstateWyscig";
import koniecWojny from "../../../assets/video/koniecWojny.mp4";
import plum from "../../../assets/sounds/effects/plum.mp3";
import {useSelector} from "react-redux";
import {useLocaleAsset, useNavigation} from "../../../misc/Hooks";
import MuteButton from "../../misc/MuteButton";
let soundPlum = null;


interface Props {
    onClickNavBraveBrunch: void,
    onClickNavLifeInTheEstate: void,
    onClickNight: void,
    onClickWonderfulYears: void,

    onClickSun: void,
    onClickArchive: void,
    onClickInfo: void,

    musicMapOn: void,
    musicMapOff: void,
}

export default (props: Props) => {

    const history = useHistory()
    const navigation = useNavigation();
    const localePath = useLocaleAsset();

    const store = useSelector(state=>({
        soundVolume: state.soundVolume,
    }))
    const [preloader, setPreloader] = useState(false);
    const [preloaderHide, setPreloaderHide] = useState(false);

    const [showInfo, setShowInfo] = useState(false)
    const [showHideInfo, setShowHideInfo] = useState(false)

    const [showWebsiteMap, setShowWebsiteMap] = useState(false)
    const [showArchive, setShowArchive] = useState(false)


    const [showStarOne, setShowStarOne] = useState(false);
    const [showStarTwo, setShowStarTwo] = useState(false);
    const [showStarThree, setShowStarThree] = useState(false);
    const [showStarFour, setShowStarFour] = useState(false);
    const [showStarFive, setShowStarFive] = useState(false);
    const [showStarSix, setShowStarSix] = useState(false);

    const [comicMaharadza, setComicMaharadza] = useState(false);
    const [comicGift, setComicGift] = useState(false);
    const [comicBaths, setComicBaths] = useState(false);
    const [comicRiki, setComicRiki] = useState(false);
    const [comicCiemnia, setComicCiemnia] = useState(false);
    const [popupCiemnia, setPopupCiemnia] = useState(false);
    const [comicWyscig, setComicWyscig] = useState(false);

    const [showMap, setShowMap] = useState(false)
    const [animationEndWar, setAnimationEndWar] = useState(false)


    useEffect(() => {
        soundPlum = new Audio(plum)
        soundPlum.volume = (store.soundVolume/2)

        // setShowWelcome(true);
        setTimeout(() => {
            setShowMap(true)
        }, 500)

        setTimeout(() => {
            setShowStarOne(true)
            soundPlum.play()
        }, 800)
        setTimeout(() => {
            setShowStarTwo(true)
            soundPlum.play()

        }, 1200)
        setTimeout(() => {
            setShowStarThree(true)
            soundPlum.play()
        }, 1600)
        setTimeout(() => {
            setShowStarFour(true)
            soundPlum.play()
        }, 2000)
        setTimeout(() => {
            setShowStarFive(true)
            soundPlum.play()
        }, 2400)
        setTimeout(() => {
            setShowStarSix(true)
            soundPlum.play()
        }, 2800)


    }, [])

    useEffect(()=> {
        soundPlum.volume = store.soundVolume
    }, [store.soundVolume])

    const handleShowInfo = () => {

        setShowInfo(true)

        setTimeout(() => {
            setShowHideInfo(true)
        }, 100)

    }

    const handleHideInfo = () => {

        setShowHideInfo(false)

        setTimeout(() => {
            setShowInfo(false)
        }, 400)
    }

    const handleStartComicMaharadza = () => {
        props.musicMapOff()
        setTimeout(() => {
            setComicMaharadza(true)
        }, 1000)
    }

    const handleStartComicBaths = () => {
        props.musicMapOff()
        setTimeout(() => {
            setComicBaths(true)
        }, 1000)
    }

    const handleStartComicGift = () => {
        props.musicMapOff()
        setTimeout(() => {
            setComicGift(true)
        }, 1000)
    }
    const handleStartComicRiki = () => {
        props.musicMapOff()
        setTimeout(() => {
            setComicRiki(true)
        }, 1000)
    }
    const handleStartComicCiemnia = () => {
        props.musicMapOff()
        setTimeout(() => {
            setComicCiemnia(true)
        }, 1000)
    }
    const handleStartComicWyscig = () => {
        props.musicMapOff()
        setTimeout(() => {
            setComicWyscig(true)
        }, 1000)
    }

    const handleNextMap = () => {
        setShowMap(false)
        props.musicMapOff();
        setTimeout(()=> {
            history.push(navigation(Routes.END_WAR));
        },500)

    }

    const handleExtraMap = () => {
        setShowMap(false)
        props.musicMapOff();
        setTimeout(() => {
            history.push(navigation(Routes.MAP_BALACHADI));
        }, 500)
    }

    const handleCiemniaEnd = () => {
        alert(5)
    }



    let mapClassName = ''
    if (showMap) {
        mapClassName += ' -active'
    }

    let infoClassName = ''
    if (showHideInfo) {
        infoClassName += ' -active'
    }

    return (
        <>
            {!!preloader && (
                <Preloader hide={preloaderHide}/>
            )}

                <div className="content">

                    <div
                        className={`mapWrapper ${mapClassName}`}
                    >

                        <div className="mapImg">
                            <img src={localePath('img/maps/mapa_osiedle_dzien.jpg')} alt=""/>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" className="mapSvg">
                            <defs>
                            </defs>
                            <MuteButton
                                elProps={{}}
                                black
                            />

                            <SvgCircle
                                elProps={{
                                    width: "216",
                                    height: "50",
                                    transform: "matrix(1 0 0 1 225 827)",
                                }}
                                img={localePath('img/buttons/housing-estate/camp_day_fajnaferajna_off.png')}
                             imgHover={localePath('img/buttons/housing-estate/camp_day_fajnaferajna_on.png')}
                                onClick={props.onClickNavBraveBrunch}
                            />

                            <SvgCircle
                                elProps={{
                                    width: "216",
                                    height: "56",
                                    transform: "matrix(1 0 0 1 225 770)",
                                }}
                                img={localePath('img/buttons/housing-estate/camp_day_zyciewosiedlu_off.png')}
                             imgHover={localePath('img/buttons/housing-estate/camp_day_zyciewosiedlu_on.png')}
                                onClick={props.onClickNavLifeInTheEstate}
                            />

                            <SvgCircle
                                elProps={{
                                    width: "216",
                                    height: "50",
                                    transform: "matrix(1 0 0 1 225 875)",
                                }}
                                img={localePath('img/buttons/housing-estate/camp_day_cudownelata_off.png')}
                             imgHover={localePath('img/buttons/housing-estate/camp_day_cudownelata_off.png')}
                                onClick={props.onClickWonderfulYears}
                            />

                            <SvgCircle
                                elProps={{
                                    width: "216",
                                    height: "56",
                                    transform: "matrix(1 0 0 1 225 920)",
                                }}
                                img={localePath('img/buttons/housing-estate/camp_day_noc_off.png')}
                             imgHover={localePath('img/buttons/housing-estate/camp_day_noc_on.png')}
                                onClick={props.onClickNight}
                            />

                            <HousingEstateNav
                                onClickInfo={handleShowInfo}
                                onClickSun={handleExtraMap}
                                onClickMapWww={() => setShowWebsiteMap(true)}
                                onClickArchive={() => setShowArchive(true)}
                                onClickForward={handleNextMap}

                            />

                            <SvgCircle
                                elProps={{
                                    width: "134",
                                    height: "134",
                                    className: `wonderfulYearsStarOne ${showStarOne ? '-active' : ''}`,
                                }}
                                img={localePath('img/buttons/camp_1_on.png')}
                                imgHover={localePath('img/buttons/camp_1_off.png')}
                                imgVisited={require('../../../assets/img/buttons/camp_1_visited.png').default}
                                nameComic={'ComicHousingEstateMaharadza'}
                                onClick={handleStartComicMaharadza}

                            />


                            <SvgCircle
                                elProps={{
                                    width: "134",
                                    height: "134",
                                    className: `wonderfulYearsStarTwo ${showStarTwo ? '-active' : ''}`,
                                }}
                                img={localePath('img/buttons/camp_2_on.png')}
                                imgHover={localePath('img/buttons/camp_2_off.png')}
                                imgVisited={require('../../../assets/img/buttons/camp_2_visited.png').default}
                                onClick={handleStartComicRiki}
                                nameComic={'ComicHousingEstateRiki'}
                            />
                            <SvgCircle
                                elProps={{
                                    width: "134",
                                    height: "134",
                                    className: `wonderfulYearsStarThree ${showStarThree ? '-active' : ''}`,
                                }}
                                img={localePath('img/buttons/camp_3_on.png')}
                                imgHover={localePath('img/buttons/camp_3_off.png')}
                                imgVisited={require('../../../assets/img/buttons/camp_3_visited.png').default}
                                onClick={handleStartComicGift}
                                nameComic={'ComicHousingEstatePrezent'}

                            />
                            <SvgCircle
                                elProps={{
                                    width: "134",
                                    height: "134",
                                    className: `wonderfulYearsStarFour ${showStarFour ? '-active' : ''}`,
                                }}
                                img={localePath('img/buttons/camp_4_on.png')}
                             imgHover={localePath('img/buttons/camp_4_off.png')}
                                imgVisited={require('../../../assets/img/buttons/camp_4_visited.png').default}
                                onClick={handleStartComicCiemnia}
                                nameComic={'ComicHousingEstateCiemnia'}

                            />
                            <SvgCircle
                                elProps={{
                                    width: "134",
                                    height: "134",
                                    className: `wonderfulYearsStarFive ${showStarFive ? '-active' : ''}`,
                                }}
                                img={localePath('img/buttons/camp_5_on.png')}
                                imgHover={localePath('img/buttons/camp_5_off.png')}
                                imgVisited={require('../../../assets/img/buttons/camp_5_visited.png').default}
                                onClick={handleStartComicWyscig}
                                nameComic={'ComicHousingEstateWyscig'}

                            />



                            <SvgCircle
                                elProps={{
                                    width: "134",
                                    height: "134",
                                    className: `wonderfulYearsStarSix ${showStarSix ? '-active' : ''}`,
                                }}
                                img={localePath('img/buttons/camp_6_on.png')}
                             imgHover={localePath('img/buttons/camp_6_off.png')}
                                imgVisited={require('../../../assets/img/buttons/camp_6_visited.png').default}
                                onClick={handleStartComicBaths}
                                nameComic={'ComicHousingEstateKapiele'}



                            />


                            {!!showInfo && (
                                <SvgCircle
                                    elProps={{
                                        width: "1920",
                                        height: "1080",
                                        className: `housingEstateInfo ${infoClassName}`
                                    }}
                                    img={localePath('img/popup/housing-estate/camp_map_legend.png')}
                                 imgHover={localePath('img/popup/housing-estate/camp_map_legend.png')}
                                    onClick={handleHideInfo}
                                />
                            )}
                        </svg>

                        <WebsiteMapPopup
                            onClick={() => setShowWebsiteMap(false)}
                            visible={showWebsiteMap}
                            className="-mapWebsite"
                            musicStop={props.musicMapOff}


                        />

                        {!!showArchive && (
                            <PopupVideo
                                video={archiveVideo}
                                onEnded={() => setShowArchive(false)}
                                class={"-housingEstateArchiveVideo"}
                                onSkip={() => setShowArchive(false)}
                            />
                        )}

                        {!!comicMaharadza && (
                            <ComicBase
                                visible={isVisible => setComicMaharadza(isVisible)}
                                file={ComicHousingEstateMaharadza}
                                musicStart={props.musicMapOn}

                            />
                        )}
                        {!!comicGift && (
                            <ComicBase
                                visible={isVisible => setComicGift(isVisible)}
                                file={ComicHousingEstatePrezent}
                                musicStart={props.musicMapOn}
                            />
                        )}
                        {!!comicBaths && (
                            <ComicBase
                                visible={isVisible => setComicBaths(isVisible)}
                                file={ComicHousingEstateKapiele}
                                musicStart={props.musicMapOn}
                            />
                        )}
                        {!!comicRiki && (
                            <ComicBase
                                visible={isVisible => setComicRiki(isVisible)}
                                file={ComicHousingEstateRiki}
                                musicStart={props.musicMapOn}
                            />
                        )}
                        {!!comicCiemnia && (
                            <ComicBase
                                visible={isVisible => setComicCiemnia(isVisible)}
                                file={ComicHousingEstateCiemnia}
                                musicStart={props.musicMapOn}
                                afterEnd={()=> setPopupCiemnia(true)}
                            />
                        )}

                      <MoveElementsPopup
                      visible={popupCiemnia}
                      onExit={()=> {setPopupCiemnia(false)}}
                      />
                        {!!comicWyscig && (
                            <ComicBase
                                visible={isVisible => setComicWyscig(isVisible)}
                                file={ComicHousingEstateWyscig}
                                musicStart={props.musicMapOn}
                            />
                        )}



                    </div>

                </div>
        </>
    )
}
