import React, {useEffect, useState} from "react";
import {useLocaleAsset} from "../../misc/Hooks";


interface Props {
    countAll: any,
    countOnLoad: any,
}



export default (props: Props) => {

const [hide, setHide] = useState(false);
const [exist, setExist] = useState(true);


    useEffect(() => {
        if((props.countOnLoad/(Object.keys(props.countAll).length - 1) ) >= 1){

            setTimeout(()=> {
                setHide(true)
            },500)
            setTimeout(()=> {
                setExist(false)
            },800)
        }
    }, [props.countOnLoad, props.countAll]);


    const localAssets = useLocaleAsset();

    return (
        <>
            {!!exist && (
                <div className={`preloader ${hide ? '-hide' : ''}`}>
                    {/*<img src={require('../../assets/img/other/preloader.png').default} alt="" className={`preloader__image`}/>*/}
                    <div className="preloader__wrapper">
                        <img src={localAssets('img/other/preloader.png')} alt="" className={`preloader__image`}/>
                        {!!props.countAll ? (

                            <div className="preloader__loadContainer">
                                <div className="preloader__load" style={{width: (props.countOnLoad/Object.keys(props.countAll).length) * 100 + '%'}}/>
                            </div>
                        ) : ''}

                    </div>
                    {/*<>*/}
                    {/*    <div className='helper-text'><span>Wszystkich: {Object.keys(props.countAll).length}</span></div>*/}
                    {/*    <div className='helper-text'><span>Załadowano: {props.countOnLoad}</span></div>*/}
                    {/*    <div className='helper-text'><span>Procent: {(props.countOnLoad/Object.keys(props.countAll).length) * 100} . </span></div>*/}
                    {/*</>*/}
                </div>
            )}



        </>

    )
}