import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useNavigation, useTranslation} from "../misc/Hooks";
import Routes from "../utils/Routes";
import {Preloader} from "./misc/_index";


export default (props) => {

    const t = useTranslation();
    const navigation = useNavigation();
    const [preloader, setPreloader] = useState(true);
    const [preloaderHide, setPreloaderHide] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setPreloaderHide(true)
        }, 300)
        setTimeout(() => {
            setPreloader(false)
        }, 600)

    }, [])

    return (
        <>
            {!!preloader && (
                <Preloader hide={preloaderHide}/>
            )}
            <div className="contact">

                <Link to={navigation(Routes.HOME)} className="pagesExit">
                    <img src={require('../assets/img/buttons/exit.png').default} alt=""/>
                    {t("contact")}
                </Link>

                <div className="container">

                    <div className="contact__items">
                        <div className="contact__item">
                        <span className="contact__left">
                            {t("producer")}:
                        </span>
                            <span className="contact__right" dangerouslySetInnerHTML={{__html: t("producer_desc")}}/>

                        </div>
                        <div className="contact__item -noMargin">
                        <span className="contact__left">
                            {t("contactForTeachers")}
                        </span>
                            <span className="contact__right">
                             {t("contactForTeachers_desc")}



                        </span>
                        </div>

                        <div className="contact__item">
                        <span className="contact__left">
                            {t("organizationOfMeetings")}:
                        </span>
                            <span className="contact__right" dangerouslySetInnerHTML={{__html: t("organizationOfMeetings_desc")}}/>

                        </div>

                        <div className="contact__item">
                        <span className="contact__left">
                            {t("watchUs")}:
                        </span>
                            <div className="contact__right">

                                <a href="https://www.facebook.com/bravebunchinindia" target="_blank">
                                    <img src={require('../assets/img/start/contact/facebook.png').default} alt="" className="contact__icon"/>
                                </a>

                                <a href="https://www.instagram.com/brave_bunch_in_india/" target="_blank">
                                    <img src={require('../assets/img/start/contact/instagram.png').default} alt="" className="contact__icon"/>
                                </a>
                        </div>
                        </div>
                    </div>


                </div>

            </div>
        </>

    )
}
