

export default {
    NAME: 'ComicHousingEstateBarak',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_1_01_Barak15/szlak_1_01_Barak15_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_01_Barak15/szlak_1_01_Barak15_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_1_01_Barak15/szlak_1_01_Barak15_03.jpg',
        },



    ],
    COMIC_SOUNDS_LIST: [

        {
            id: 1,
            file: require('../assets/sounds/comics/housing-estate/barak15/TBB_Szlak_01_Barak15_AtA_Barrack_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/housing-estate/barak15/TBB_Szlak_01_Barak15_AtA_DesertDay_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/housing-estate/barak15/TBB_Szlak_01_Barak15_AtA_DesertWind_01.mp3').default,
            from: 0,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/housing-estate/barak15/TBB_Szlak_01_Barak15_FxA_FlagFlap_01.mp3').default,
            from: 1,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/housing-estate/barak15/TBB_Szlak_01_Barak15_Mx_01_VER_02_Longer_01.mp3').default,
            from: 0,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },

    ],
}
