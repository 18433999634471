

export default {
    NAME: 'ComicRoadGory',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/road/gory/droga_01_Gory_01.jpg',
        },
        {
            img:  'img/comics/road/gory/droga_01_Gory_02.jpg',
        },
        {
            img:  'img/comics/road/gory/droga_01_Gory_03.jpg',
        },
        {
            img:  'img/comics/road/gory/droga_01_Gory_04.jpg',
        },
        {
            img:  'img/comics/road/gory/droga_01_Gory_05.jpg',
        },
        {
            img:  'img/comics/road/gory/droga_01_Gory_06.jpg',
        },
        {
            img:  'img/comics/road/gory/droga_01_Gory_07.jpg',
        },
        {
            img:  'img/comics/road/gory/droga_01_Gory_08.jpg',
        },
        {
             img:  'img/comics/road/gory/droga_01_Gory_09.jpg',
        },
        {
            img:  'img/comics/road/gory/droga_01_Gory_10.jpg',
        },
    ],
    COMIC_SOUNDS_LIST: [
        {
            id: 1,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_Mx_GrozneGory_02.mp3').default,
            from: 8,
            to: 10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_Mx_GrozneGory_01.mp3').default,
            from: 1,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_06.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
         {
             id: 4,
             file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_FxB_Skid_01.mp3').default,
             from: 2,
             to: 3,
             loop: false,
             delay: 0,
             volume: 1,
             onEndSound: false
         },
        /*{
            id: 5,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_FxA_OpelStop_02.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
        {
            id: 6,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_FxA_OpelStop_01.mp3').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        /*{
            id: 7,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_FxA_OpelStart_02.mp3').default,
            from: 9,
            to: 10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
        {
            id: 8,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_FxA_OpelSlow_01.mp3').default,
            from: 2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_FxA_OpelEngine_01.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_FxA_OpelAmb_02.mp3').default,
            from: 8,
            to: 10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_FxA_OpelAmb_01.mp3').default,
            from: 1,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_FxA_Laugh_01.mp3').default,
            from: 3,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_08.mp3').default,
            from: 7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_Wind_01.mp3').default,
            from: 0,
            to: 1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_Wind_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_Wind_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        /*{
            id: 19,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_Wind_01.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
        {
            id: 20,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_Wind_01.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        /*{
            id: 21,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_Wind_01.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
        {
            id: 22,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_Wind_01.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_Wind_01.mp3').default,
            from: 9,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        /*{
            id: 24,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_OpelStart_01.mp3').default,
            from: 9,
            to: 10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
        // {
        //     id: 25,
        //     file:  require('../assets/sounds/comics/road/gory/xxxxx.mp3').default,
        //     from: 6,
        //     to: 7,
        //     loop: true,
        //     delay: 0,
        //     volume: 1,
        //     onEndSound: false
        // },
        {
            id: 26,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_OpelSlow_02.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        /*{
            id: 27,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_OpelSlow_01.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
        {
            id: 28,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_OpelInt_02.mp3').default,
            from: 4,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 29,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_OpelInt_01.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 30,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_Desert_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 31,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_Caravan_02.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 32,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_01_Gory_AtA_Caravan_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 33,
            file:  require('../assets/sounds/comics/road/gory/TBB_Droga_02_Odjazd_01.mp3').default,
            from: 9,
            to: 10,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
    ],
}
