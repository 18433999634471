
import React from "react";
import {useLocaleAsset} from "../../misc/Hooks";


interface Props {
    visible: boolean,
    onExit: void,
}

export default (props: Props) => {
    let mousePosition;
    let offset = [0,0];
    let isDown = false;
    let zIndex = 1;

    let images = [...document.querySelectorAll('.moveElementsPopup__item')]
    let documentWidth = document.body.offsetWidth
    let documentHeight = document.body.offsetHeight

    const localeAssets = useLocaleAsset()



    window.addEventListener('resize', ()=> {
        documentWidth = document.body.offsetWidth
        documentHeight = document.body.offsetHeight

        images.forEach((image)=>{
            image.style.top = 0;
            image.style.left = 0;
        })



    })


    const mouseDown = (e) => {
        isDown = true;
        ++zIndex;
        e.target.style.zIndex = zIndex;

        offset = [
            e.target.offsetLeft - e.clientX,
            e.target.offsetTop - e.clientY
        ];
    }

    const mouseUp = () => {
        isDown = false;
    }


    const mouseMove = (e) => {
        e.preventDefault();
        if (isDown) {
            mousePosition = {

                x: e.clientX,
                y: e.clientY

            };



            if((mousePosition.x + offset[0]) >= 0 && (mousePosition.x + offset[0]) <= documentWidth - e.target.offsetWidth){
                e.target.style.left = (mousePosition.x + offset[0]) + 'px';
            }
            if((mousePosition.y + offset[1]) >= 0 && (mousePosition.y + offset[1]) <= documentHeight - e.target.offsetHeight){
                e.target.style.top = (mousePosition.y + offset[1]) + 'px';

            }
        }
    };


    return (

            <div className={`moveElementsPopup ${props.visible ? '-active' : ''}`} onMouseOver={mouseUp}>

                <img src={localeAssets('img/popup/moveImages/Layer03.jpg')} className="moveElementsPopup__item" alt="" onMouseDown={mouseDown} onMouseUp={mouseUp} onMouseMove={mouseMove}/>
                <img src={localeAssets('img/popup/moveImages/Layer04.jpg')} className="moveElementsPopup__item" alt="" onMouseDown={mouseDown} onMouseUp={mouseUp} onMouseMove={mouseMove}/>
                <img src={localeAssets('img/popup/moveImages/Layer05.jpg')} className="moveElementsPopup__item" alt="" onMouseDown={mouseDown} onMouseUp={mouseUp} onMouseMove={mouseMove}/>
                <img src={localeAssets('img/popup/moveImages/Layer06.jpg')} className="moveElementsPopup__item" alt="" onMouseDown={mouseDown} onMouseUp={mouseUp} onMouseMove={mouseMove}/>
                <img src={localeAssets('img/popup/moveImages/Layer07.jpg')} className="moveElementsPopup__item" alt="" onMouseDown={mouseDown} onMouseUp={mouseUp} onMouseMove={mouseMove}/>
                <img src={localeAssets('img/popup/moveImages/Layer08.jpg')} className="moveElementsPopup__item" alt="" onMouseDown={mouseDown} onMouseUp={mouseUp} onMouseMove={mouseMove}/>
                <img src={localeAssets('img/popup/moveImages/Layer09.jpg')} className="moveElementsPopup__item" alt="" onMouseDown={mouseDown} onMouseUp={mouseUp} onMouseMove={mouseMove}/>
                <img src={localeAssets('img/popup/moveImages/Layer10.jpg')} className="moveElementsPopup__item" alt="" onMouseDown={mouseDown} onMouseUp={mouseUp} onMouseMove={mouseMove}/>

                <img src={require('../../assets/img/buttons/exit.png').default} alt="" className="moveElementsPopup__exit" onClick={props.onExit}/>
            </div>

    )



}
