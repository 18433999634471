

export default {

    NAME: 'ComicHousingEstateBerek',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/housing-estate/szlak_2_05_Berek/szlak_2_05_Berek_01.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_05_Berek/szlak_2_05_Berek_02.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_05_Berek/szlak_2_05_Berek_03.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_05_Berek/szlak_2_05_Berek_04.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_05_Berek/szlak_2_05_Berek_05.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_05_Berek/szlak_2_05_Berek_06.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_05_Berek/szlak_2_05_Berek_07.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_05_Berek/szlak_2_05_Berek_08.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_05_Berek/szlak_2_05_Berek_09.jpg',
        },
        {
            img:  'img/comics/housing-estate/szlak_2_05_Berek/szlak_2_05_Berek_10.jpg',
        },

    ],
    COMIC_SOUNDS_LIST: [
      {
          id: 1,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtA_GustyDesert_01.mp3').default,
          from: 1,
          to: 2,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 2,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtA_Kids_01.mp3').default,
          from: 2,
          to: 3,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 3,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtA_Kids_02.mp3').default,
          from: 3,
          to: 4,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 4,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtA_Kids_03.mp3').default,
          from: 4,
          to: 5,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 5,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtA_Leaves_01.mp3').default,
          from: 0,
          to: 1,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 6,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtA_RuralDay_01.mp3').default,
          from: 2,
          to: 3,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 7,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtA_RuralDay_01.mp3').default,
          from: 3,
          to: 4,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 8,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtA_RuralDay_01.mp3').default,
          from: 4,
          to: 5,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 9,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtA_RuralDay_01.mp3').default,
          from: 5,
          to: 6,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 10,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtA_RuralDay_01.mp3').default,
          from: 6,
          to: 7,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 11,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtA_RuralDay_01.mp3').default,
          from: 7,
          to: 8,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 12,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtA_RuralDay_01.mp3').default,
          from: 8,
          to: 9,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 13,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtA_RuralDay_01.mp3').default,
          from: 9,
          to: 10,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 14,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_FxA_Leaves_01.mp3').default,
          from: 0,
          to: 1,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 15,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtB_Leaves_01.mp3').default,
          from: 2,
          to: 3,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 16,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtB_Leaves_02.mp3').default,
          from: 3,
          to: 5,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 17,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtB_Leaves_03.mp3').default,
          from: 3,
          to: 6,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 18,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtB_Leaves_04.mp3').default,
          from: 6,
          to: 7,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 19,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtB_Leaves_05.mp3').default,
          from: 7,
          to: 8,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 20,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtB_Leaves_06.mp3').default,
          from: 8,
          to: 9,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 21,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtB_Leaves_06.mp3').default,
          from: 9,
          to: 10,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 22,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtB_WindInPlants_01.mp3').default,
          from: 2,
          to: 3,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 23,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtB_WindInPlants_01.mp3').default,
          from: 3,
          to: 4,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 24,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtB_WindInPlants_01.mp3').default,
          from: 4,
          to: 5,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 25,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtB_WindInPlants_01.mp3').default,
          from: 5,
          to: 6,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 26,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtB_WindInPlants_01.mp3').default,
          from: 6,
          to: 7,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 27,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtB_WindInPlants_01.mp3').default,
          from: 8,
          to: 9,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 28,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_AtB_WindInPlants_01.mp3').default,
          from: 9,
          to: 10,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 29,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_FxA_Bell_01.mp3').default,
          from: 1,
          to: 2,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 30,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_FxA_Cutting_01.mp3').default,
          from: 9,
          to: 10,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 31,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_FxA_Leaves_02.mp3').default,
          from: 3,
          to: 4,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 32,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_FxA_Leaves_03.mp3').default,
          from: 4,
          to: 5,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 33,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_FxA_Leaves_04.mp3').default,
          from: 6,
          to: 7,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 34,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_FxA_Leaves_05.mp3').default,
          from: 7,
          to: 8,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 35,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_FxA_Snake_01.mp3').default,
          from: 8,
          to: 9,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 36,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_FxA_SnakeAndLeaves_01.mp3').default,
          from: 2,
          to: 3,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 37,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_FxA_SnakeAndLeaves_02.mp3').default,
          from: 5,
          to: 6,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 38,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_FxB_Bird_01.mp3').default,
          from: 8,
          to: 9,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 39,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_FxB_Hall_01.mp3').default,
          from: 1,
          to: 2,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 40,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_FxB_Impact_01.mp3').default,
          from: 5,
          to: 6,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 41,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_FxB_Whoosh_01.mp3').default,
          from: 6,
          to: 7,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 42,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_FxB_Whoosh_02.mp3').default,
          from: 7,
          to: 8,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 43,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_Mx_01_Berek_01.mp3').default,
          from: 2,
          to: 5,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 44,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_Mx_02_Berek_Akcent_Waz.mp3').default,
          from: 5,
          to: 6,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 45,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_Mx_03_Berek_02.mp3').default,
          from: 8,
          to: 10,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 46,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_VO_Children_01.mp3').default,
          from: 2,
          to: 3,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 47,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_VO_Children_02.mp3').default,
          from: 3,
          to: 4,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 48,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_VO_Children_03.mp3').default,
          from: 4,
          to: 5,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 49,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_VO_Children_04.mp3').default,
          from: 5,
          to: 6,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 50,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_VO_Children_05.mp3').default,
          from: 6,
          to: 7,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 51,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_VO_Children_06.mp3').default,
          from: 7,
          to: 8,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 52,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_VO_Children_Loop_01.mp3').default,
          from: 2,
          to: 3,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 53,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_VO_Children_Loop_02.mp3').default,
          from: 3,
          to: 4,
          loop: true,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
      {
          id: 54,
          file: require('../assets/sounds/comics/housing-estate/berek/TBB_Szlak2_05_Berek_VO_Children_Loop_03.mp3').default,
          from: 4,
          to: 5,
          loop: false,
          delay: 0,
          volume: 1,
          onEndSound: false
      },
    ],
}
