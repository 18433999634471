

export default {
    NAME: 'ComicRoadZasadzka',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_01.jpg',
        },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_02.jpg',
        },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_03.jpg',
        },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_04.jpg',
        },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_05.jpg',
        },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_06.jpg',
        },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_07.jpg',
        },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_08.jpg',
        },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_09.jpg',
        },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_10.jpg',
        },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_11.jpg',
        },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_12.jpg',
        },
         {
             img:  'img/comics/road/zasadzka/droga_02_Zasadzka_13.jpg',
         },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_14.jpg',
        },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_15.jpg',
        },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_16.jpg',
        },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_17.jpg',
        },
        {
            img:  'img/comics/road/zasadzka/droga_02_Zasadzka_18.jpg',
        },
    ],
    COMIC_SOUNDS_LIST: [

        {
            id: 1,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtA_Crickets_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtA_Crickets_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtA_Crickets_02.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtA_Crickets_02.mp3').default,
            from: 4,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtA_Crickets_02.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtA_Crickets_02.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtA_OddechyDzieciaki_01.mp3').default,
            from: 9,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtA_OddechyDzieciaki_02.mp3').default,
            from: 10,
            to: 11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtA_OpelEngine_01.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtA_OpelEngine_02.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtA_OpelEngine_03.mp3').default,
            from: 7,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtA_OpelEngine_04.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_01.mp3').default,
            from: 9,
            to: 10,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_01.mp3').default,
            from: 10,
            to: 11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_01.mp3').default,
            from: 11,
            to: 12,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 19,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_01.mp3').default,
            from: 12,
            to: 13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 20,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_01.mp3').default,
            from: 13,
            to: 14,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 21,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_01.mp3').default,
            from: 14,
            to: 15,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_01.mp3').default,
            from: 15,
            to: 16,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 24,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_01.mp3').default,
            from: 16,
            to: 17,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 25,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_01.mp3').default,
            from: 17,
            to: 18,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 26,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_01.mp3').default,
            from: 0,
            to: 1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 27,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 28,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_01.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 29,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_02.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 30,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_02.mp3').default,
            from: 4,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 31,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_02.mp3').default,
            from: 5,
            to: 6,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 32,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_02.mp3').default,
            from: 6,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 33,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_02.mp3').default,
            from: 7,
            to: 8,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 34,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_AtB_Wind_02.mp3').default,
            from: 8,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 35,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxA_Ciii_01.mp3').default,
            from: 11,
            to: 12,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 36,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxA_Driver_01.mp3').default,
            from: 16,
            to: 17,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 37,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxA_Gun_01.mp3').default,
            from: 14,
            to: 15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 38,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxA_LoadGun_01.mp3').default,
            from: 12,
            to: 13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 39,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxA_Opel_01.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 40,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxA_Opel_02.mp3').default,
            from: 7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 41,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxA_Pencil_01.mp3').default,
            from: 1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 42,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxA_Pencil_02.mp3').default,
            from: 2,
            to: 3,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 43,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxA_Skid_01.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 44,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxB_Kroki_01.mp3').default,
            from: 3,
            to: 4,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 45,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxB_OddechyDzieciaki_01.mp3').default,
            from: 12,
            to: 13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 46,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxB_OddechyDzieciaki_01.mp3').default,
            from: 15,
            to: 16,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 47,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxB_OddechyDzieciaki_02.mp3').default,
            from: 16,
            to: 17,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 48,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxB_OpelDistant_01.mp3').default,
            from: 17,
            to: 18,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 49,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxB_Skid_01.mp3').default,
            from: 8,
            to: 9,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 50,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_Mx_HappyEnd.mp3').default,
            from: 16,
            to: 18,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 51,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_Mx_Suspens_01.mp3').default,
            from: 9,
            to: 14,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 52,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_Mx_Suspens_02.mp3').default,
            from: 15,
            to: 16,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 53,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzla_FxA_OpelStop_01.mp3').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 54,
            file:  require('../assets/sounds/comics/road/zasadzka/TBB_Droga_02_Zasadzka_FxB_Skid_02.mp3').default,
            from: 15,
            to: 16,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
    ],
}
