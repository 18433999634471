

export default {
    NAME: 'ComicAmnestyKasansaj',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_01.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_02.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_03.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_04.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_05.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_06.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_07.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_08.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_09.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_10.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_11.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_12.jpg',
            fade: true,
            fadeOnlyEnd: true,
        },
        {
            img:  'img/comics/housing-estate/szlak_2_06_Monsun/szlak_2_06_Monsun_09a.png',
            fade: true,
            blockNext: true,
            nextAfterTime: 1000,
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_13.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_14.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_15.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_16.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_17.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_18.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_19.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_20.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_21.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_22.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_23.jpg',
        },
        {
            img:  'img/comics/amnesty/kasansaj/syberia_08_Kasansaj_24.jpg',
        },




    ],
    COMIC_SOUNDS_LIST: [
        {
            id: 1,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_Barn_01.mp3').default,
            from: 1,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_Barn_02.mp3').default,
            from: 7,
            to: 9,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_Barn_02.mp3').default,
            from: 9,
            to: 11,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_Barn_03.mp3').default,
            from: 11,
            to: 13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_City_01.mp3').default,
            from: 4,
            to: 7,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_Desert_01.mp3').default,
            from: 0,
            to: 1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_Desert_02.mp3').default,
            from: 20,
            to: 23,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_Desert_03.mp3').default,
            from: 23,
            to: 25,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
      {
            id: 9,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_Heart_01.mp3').default,
            from: 15,
            to: 19,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        /*{
            id: 10,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_Heart_01.mp3').default,
            from: 15,
            to: 16,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },*/
        {
            id: 11,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_Hospital_01.mp3').default,
            from: 13,
            to: 16,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_Hospital_01.mp3').default,
            from: 16,
            to: 19,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_Hospital_02.mp3').default,
            from: 19,
            to: 20,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 14,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_HumanCrying_LOOP_01.mp3').default,
            from: 0,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 15,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_HumanCrying_LOOP_02.mp3').default,
            from: 2,
            to: 3,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_AtA_HumanCrying_LOOP_03.mp3').default,
            from: 3,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_FxA_Breath_01.mp3').default,
            from: 17,
            to: 18,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: true
        },
        {
            id: 18,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_FxA_Carriage_01.mp3').default,
            from: 5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 19,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_FxA_Crow_01.mp3').default,
            from: 13,
            to: 14,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 20,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_FxA_Crow_02.mp3').default,
            from: 14,
            to: 15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 21,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_FxA_Crows_01.mp3').default,
            from: 20,
            to: 21,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_FxA_HumanCough_01.mp3').default,
            from: 4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_FxA_Mule_01.mp3').default,
            from: 1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 24,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_FxA_Mule_03.mp3').default,
            from: 7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 25,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_FxA_Well_01.mp3').default,
            from: 6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 26,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_Mx_01_Akcent1ZaskoczenieGroza.mp3').default,
            from: 7,
            to: 8,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 27,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_Mx_02_Zebrak.mp3').default,
            from: 11,
            to: 13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 28,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_Mx_03_Mama.mp3').default,
            from: 18,
            to: 19,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: true
        },
        {
            id: 29,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_Mx_04_Rys.mp3').default,
            from: 19,
            to: 20,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 30,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_Mx_05_ZostalemSam.mp3').default,
            from: 21,
            to: 25,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 31,
            file: require('../assets/sounds/comics/amnesty/kasansaj/TBB_Syberia_08_Kasansaj_Mx_09_MuzykaDodatkowa.mp3').default,
            from: 8,
            to: 16,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
    ],
}
