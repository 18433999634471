import {useRouteMatch} from "react-router-dom";
import {useEffect, useState} from "react";


/*
* returns translated slug, depends on current language (route param)
* */
export const useTranslation = () => {
    const match = useRouteMatch();
    const lang = match.params.lang ?? process.env.REACT_APP_DEFAULT_LANG;

    return (name, ...args) => {
        const file = require(`../assets/translations/${lang}.json`);
        let slug = {...file};
        const params = name.toString().split('.');

        params.forEach(s => {
            if (typeof slug !== "undefined")
                slug = slug[s];
            else
                slug = name;
        })

        if (!slug) {
            slug = ``; // translation slug undefined
        }

        args.map(arg => {
            slug = slug.toString().replace('{arg}', arg);
        })
        slug = slug.toString().replaceAll('{arg}', '');


        return slug;
    }
}

export const useActions = () => {
    const match = useRouteMatch();
    const lang = match.params.lang ?? process.env.REACT_APP_DEFAULT_LANG;

    console.log(`/creators_${lang}.json`)
    return {
        getCreators: () => {
            return fetch(`/creators_${lang}.json`)
                .then(r => r.json())
        }
    }
}


/*
* returns browser friendly route with params
* */
export const useNavigation = () => {
    const match = useRouteMatch();

    return (route: string, ...params) => {
        let r = route;
        r = r.replace(/\/:lang\([a-zA-Z0-9\|]+\)\?/, match.params.lang ? `/${match.params.lang}` : '')
        params.map(param => {
            if (r.match(/:[a-zA-Z0-9(\|)?]+\//)) {
                r = r.replace(/:[a-zA-Z0-9(\|)?]+\//, param ? `/${param}` : '')
            } else if (r.match(/:[a-zA-Z0-9(\|)?]+$/)) {
                r = r.replace(/:[a-zA-Z0-9(\|)?]+$/, param ?? '')
            }
        })
        return r;
    };
}


// export const useLocaleAsset = () => {
//     const match = useRouteMatch();
//     const lang = match.params.lang ?? process.env.REACT_APP_DEFAULT_LANG;
//
//
//     return (path) => {
//         return fetch(`/assets/${lang}/${path}`, {method: "HEAD"})
//             .then(res => {
//                 if(res.ok) {
//                     return `/assets/${lang}/${path}`
//                 }
//                 else {
//                     return `/assets/${path}`;
//                 }
//             }, () => {
//                 return `/assets/${path}`;
//             })
//     }
// }

export const useLocaleAsset = () => {
    const match = useRouteMatch();
    const lang = match.params.lang ?? process.env.REACT_APP_DEFAULT_LANG;
    let uri = '';
    return (path) => {
        uri = require(`../assets/${path}`).default
        try {
           uri = require(`../assets/${lang}/${path}`).default;
            return uri;
        } catch (e) {
            // file not exist
        }
        return uri;
    }
}


export const useAudio = (initialAudio) => {
    const [audio, setAudio] = useState(initialAudio);

    useEffect(() => {
        console.log(audio);
    }, [audio]);

    return {
        setAudio,
        changeVolume: volume => {
            if (!audio) return;
            audio.volume = Number(volume);
        },
        pause: () => {
            if (!audio) return;
            audio.pause();
        },
        play: () => {
            if (!audio) return;
            audio.play();
        },
        setLoop: loop => {
            if (!audio) return;
            audio.loop = loop;
        },
        setOnEnd: (boolOnEnd, nextSlide, delayNextSlide = 0) => {
            if (boolOnEnd) {
                audio.onended = nextSlide;
            }
        }
    };
};


