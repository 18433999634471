import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useNavigation, useTranslation} from "../misc/Hooks";
import Routes from "../utils/Routes";
import {Preloader} from "./misc/_index";


export default (props) => {

    const t = useTranslation();
    const navigation = useNavigation();
    const [preloader, setPreloader] = useState(true);
    const [preloaderHide, setPreloaderHide] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setPreloaderHide(true)
        }, 300)
        setTimeout(() => {
            setPreloader(false)
        }, 600)

    }, [])

    return (
        <>
            {!!preloader && (
                <Preloader hide={preloaderHide}/>
            )}


           <div className="research">
               <Link to={navigation(Routes.HOME)} className="pagesExit">
                   <img src={require('../assets/img/buttons/exit.png').default} alt=""/>
                   {t("research")}
               </Link>

               <div className="container">
                   <div className="research__item -first">
                       <div className="research__avatar">
                           <img src={require('../assets/img/start/research/wStypula.png').default} alt=""/>
                       </div>
                       <div className="research__name">
                           <span>Wiesław Stypuła ur. 1931r.</span>
                           <div className="research__tableInfo">
                               <span dangerouslySetInnerHTML={{__html: t('researchName')}}/>
                               <img src={require('../assets/img/start/research/arrow.png').default} alt=""/>
                           </div>
                       </div>
                       <div className="research__pseudonym">
                           <span>Wiesiek</span>
                           <div className="research__tableInfo">
                               <span dangerouslySetInnerHTML={{__html: t('researchPseudonym')}}/>
                               <img src={require('../assets/img/start/research/arrow.png').default} alt=""/>
                           </div>
                       </div>
                       <div className="research__description">
                           <span>{t("researchWiesiekDesc")}</span>
                           <div className="research__tableInfo">
                               <span dangerouslySetInnerHTML={{__html: t('researchDescription')}}/>
                               <img src={require('../assets/img/start/research/arrow.png').default} alt=""/>
                           </div>
                       </div>
                   </div>
                   <div className="research__item -light">
                       <div className="research__avatar">
                           <img src={require('../assets/img/start/research/wStypula.png').default} alt=""/>
                       </div>
                       <div className="research__name">
                           <span>Wiesław Stypuła ur. 1931r.</span>
                       </div>
                       <div className="research__pseudonym">
                           <span>Wiesiek</span>
                       </div>
                       <div className="research__description">
                           <span>{t("researchWiesiekDesc")}</span>
                       </div>
                   </div>
                   <div className="research__item">
                       <div className="research__avatar">
                           <img src={require('../assets/img/start/research/wStypula.png').default} alt=""/>
                       </div>
                       <div className="research__name">
                           <span>Wiesław Stypuła ur. 1931r.</span>
                       </div>
                       <div className="research__pseudonym">
                           <span>Wiesiek</span>
                       </div>
                       <div className="research__description">
                           <span>{t("researchWiesiekDesc")}</span>
                       </div>
                   </div>

               </div>

           </div>
        </>

    )
}
