import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import Routes from "../utils/Routes";
import {DeportationMap} from "../components/maps/DeportationMap";
import BieriezniakiMap from "../components/maps/BieriezniakiMap";
import AmnestyMap from "../components/maps/AmnestyMap";
import RoadMap from "../components/maps/RoadMap";
import {Contact, Creators, ForTeachers, Home, Instruction, Research, Sponsors, Start} from "../components/_index";
import HousingEstateMap from "../components/maps/HousingEstateMap";
import BalachadiMap from "../components/maps/BalachadiMap";
import EndWar from "../components/maps/EndWar";
import Letter from "../components/maps/Letter";
import IWasHereMap from "../components/maps/IWasHereMap";
import {useDispatch, useSelector} from "react-redux";

import click from '../assets/sounds/effects/TBB_UI_Click_02.mp3'
import clickButton from '../assets/sounds/effects/TBB_UI_Click_04.mp3'
import * as reducer from "../store/reducer";
import {Error404} from "../components/misc/_index";
import VideoHousingEstateMap from "../components/maps/VideoHousingEstateMap";
import RotateDevice from "../components/RotateDevice";
import EpilepsyWarning from "../components/EpilepsyWarning";

let clickSound = null;
let clickButtonSound = null;
let eventListener = null
let MOUNTED = false;

export const CustomNavigation = (props) => {

    const dispatch = useDispatch();
    const store = useSelector(state => ({
        soundVolume: state.soundVolume,
        visitedComics: state.visitedComics
    }))

    useEffect(() => {
        MOUNTED = true;
        clickSound = new Audio(click);
        clickSound.volume = store.soundVolume;
        clickButtonSound = new Audio(clickButton);
        clickButtonSound.volume = store.soundVolume;
        eventListener = document.addEventListener('click', clickSoundPlay);

        document.addEventListener('keydown', handleListenKeyDown);

        return () => {
            MOUNTED = false;
            document.removeEventListener('click', clickSoundPlay)
            document.removeEventListener('keydown', handleListenKeyDown);

        }
    })


    const handleListenKeyDown = (e) => {
        switch (e.key) {

            case "ArrowUp": {
                const newValue = parseFloat(store.soundVolume) + parseFloat(0.1)
                if (newValue <= 1) {
                    dispatch(reducer.setVolumeAudio((newValue.toFixed(1))))
                }

                break;
            }
            case "ArrowDown": {
                const newValue = parseFloat(store.soundVolume) - parseFloat(0.1)
                if (newValue >= 0){
                    dispatch(reducer.setVolumeAudio(newValue.toFixed(1)))
                }
                break;
            }

        }

    }

    const clickSoundPlay = (e) => {
        if (MOUNTED) {
            const comic = document.querySelector('.comicWrapper')
            if ((!comic) && (!(e.target.tagName === "image")) && (!(e.target.tagName === "BUTTON"))) {
                clickSound.play()
            }
            if((!comic) && ((e.target.tagName === "image") || (e.target.tagName === "BUTTON"))){
                clickButtonSound.play();
            }

        }
    }



    return (
    <>
        <Router>

            <Switch>

                <Route exact path={Routes.HOME}>
                    <Home/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.START}>
                    <Start/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.INSTRUCTION}>
                    <Instruction/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.CREATORS}>
                    <Creators/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.SPONSORS}>
                    <Sponsors/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.FOR_TEACHERS}>
                    <ForTeachers/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.RESEARCH}>
                    <Research/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.CONTACT}>
                    <Contact/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.MAP_DEPORTATION}>
                    <DeportationMap/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.MAP_BIERIEZNIAKI}>
                    <BieriezniakiMap/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.MAP_AMNESTY}>
                    <AmnestyMap/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.MAP_ROAD}>
                    <RoadMap/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.MAP_HOUSING_ESTATE}>
                    <HousingEstateMap/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.VIDEO_HOUSING_ESTATE}>
                    <VideoHousingEstateMap/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.MAP_BALACHADI}>
                    <BalachadiMap/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.END_WAR}>
                    <EndWar/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.LETTER}>
                    <Letter/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.I_WAS_HERE}>
                    <IWasHereMap/>
                    <RotateDevice/>
                </Route>

                <Route exact path={Routes.EPILEPSY_WARNING}>
                    <EpilepsyWarning/>
                    <RotateDevice/>
                </Route>

                <Route component={Error404}/>
            </Switch>
        </Router>

    </>
    )
}
