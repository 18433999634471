import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useLocaleAsset, useNavigation, useTranslation} from "../misc/Hooks";
import Routes from "../utils/Routes";
import {Preloader} from "./misc/_index";


export default (props) => {

    const t = useTranslation();
    const navigation = useNavigation();
    const localeAsset = useLocaleAsset()
    const [preloader, setPreloader] = useState(true);
    const [preloaderHide, setPreloaderHide] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setPreloaderHide(true)
        }, 300)
        setTimeout(() => {
            setPreloader(false)
        }, 600)

    }, [])

    return (
        <>
            {!!preloader && (
                <Preloader hide={preloaderHide}/>
            )}
            <div className="instructionPage -small">
                <img src={localeAsset('img/start/instruction/instruction.jpg')} alt=""
                     className="instructionPage__image"/>
                <Link to={navigation(Routes.HOME)} className="pagesExit">
                    <img src={require('../assets/img/buttons/exit.png').default} alt=""/>
                    {t("instruction")}
                </Link>
            </div>
        </>

    )
}
