import {BasePopup} from "./_index";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as reducer from "../../store/reducer";


interface Props {
    onClick: any,
    className?: any,
    imgOne: any,
    imgTwo: any,
    namePopup: any,

}

export default (props: Props) => {

    const [page, setPage] = useState(1)


    const dispatch = useDispatch()
    const store = useSelector(state => ({
        visitedComics: state.visitedComics,
    }))

    if(props.namePopup && props.visible){
        const arr = [...store.visitedComics]

        const idx = arr.findIndex(i => i === props.namePopup)
        if (idx === -1){

            arr.push(props.namePopup)
            dispatch(reducer.setVisitedComics(arr))
        }
    }

    return (

        <BasePopup direction="top" visible={props.visible}>
            <div className={`secondPopup ${props.className}`} >
                <div className="secondPopup__text">

                        {page === 1 && (
                            <img src={props.imgOne} alt="" onClick={props.onClick}/>
                        )}
                        {page === 2 && (
                            <img src={props.imgTwo} alt="" onClick={props.onClick}/>
                        )}

                    <div className="popupNavigation">
                        {page === 2 && (
                            <button className="prev" onClick={()=>setPage(1)}><img src={require('../../assets/img/buttons/arrow_left.png').default} alt=""/></button>
                        )}
                        {page === 1 && (
                            <button className="next" onClick={()=>setPage(2)}><img src={require('../../assets/img/buttons/arrow_right.png').default} alt=""/></button>
                        )}

                    </div>
                </div>
            </div>
        </BasePopup>

    )
}
