

export default {
    NAME: 'ComicDeportationTrain',

    COMIC_IMAGES_LIST: [
        {
            img:  'img/comics/deportation/train/Syberia_01_Pociag_01.jpg',
        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_02.jpg',
        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_03.jpg',
        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_04.jpg',
        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_05.jpg',
        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_06.jpg',
            blockNext: true,
        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_07.jpg',
        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_08.jpg',
        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_09.jpg',
        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_10.jpg',
        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_11.jpg',
        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_12.jpg',

        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_13.jpg',

        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_14.jpg',

        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_15.jpg',
            /*blockNext: true,
            delayNextSlide: 3000,*/
        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_16.jpg',
            fade: true,

        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_17.jpg',
            fade: true,
        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_18.jpg',
            fade: true,
        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_19.jpg',
            fade: true,


        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_20.jpg',
            fade: true,


        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_21.jpg',
            fade: true,


        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_22.jpg',
            fade: true,
        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_23.jpg',

        },
        {
            img: 'img/comics/deportation/train/Syberia_01_Pociag_24.jpg',

        },

    ],
    COMIC_SOUNDS_LIST: [


        {
            id: 1,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_AtA_BoxCarInside_Loop_01.mp3').default,
            from: 2,
            to: 4,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 2,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_AtA_TrainDistant_Loop_01.mp3').default,
            from: 0,
            to: 1,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 3,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_AtA_TrainDistant_Loop_01.mp3').default,
            from: 1,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 4,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_AtA_TrainDistant_Loop_01.mp3').default,
            from: 16,
            to: 17,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 5,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_AtA_TrainDistant_Loop_02.mp3').default,
            from: 4,
            to: 5,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 6,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_AtA_TrainIdle_Loop_01.mp3').default,
            from: 10,
            to: 13,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 7,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_AtA_TrainOnTracks_Loop_01.mp3').default,
            from: 17,
            to: 21,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 8,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_AtB_RainDenseGeneral_Loop_01.mp3').default,
            from:22,
            to: 24,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 9,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_AtB_WindInBarnLight_Loop_01.mp3').default,
            from:0,
            to: 2,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 10,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_AtB_WindInBarnLight_Loop_01.mp3').default,
            from:4,
            to: 16,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 11,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_AtB_WindLakeMontana_Loop_01.mp3').default,
            from:4,
            to: 21,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 12,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_AtB_WindLakeMontana_Loop_01.mp3').default,
            from:22,
            to: 24,
            loop: true,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 13,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxA_CartDoorOpen_01.mp3').default,
            from:5,
            to: 6,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: true
        },
        {
            id: 14,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxA_CartDoorOpen_02.mp3').default,
            from:21,
            to: 22,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: true
        },
        {
            id: 15,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxA_MetalDoorClose_01.mp3').default,
            from:23,
            to: 24,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 16,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxA_PoliceWhistle_01.mp3').default,
            from:10,
            to: 11,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 17,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxA_TrainCoupling_01.mp3').default,
            from:13,
            to: 14,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 18,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxA_TrainWhistle_01.mp3').default,
            from:1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 19,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxA_TrainWhistle_02.mp3').default,
            from:4,
            to: 5,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 20,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxA_TrainWhistle_03.mp3').default,
            from:11,
            to: 12,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 21,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxA_TrainWhistle_03.mp3').default,
            from:16,
            to: 17,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 22,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxA_WaterDrinking_01.mp3').default,
            from:6,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 23,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxA_Whistle_VO_Michal_01.mp3').default,
            from:14,
            to: 15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: true,
        },
        {
            id: 24,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxB_GooseFlyBy_01.mp3').default,
            from:1,
            to: 2,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 25,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxB_ThunderStrikeDistantRoll_01.mp3').default,
            from:22,
            to: 24,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 26,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxB_TrainIldeBellAndSteam_01.mp3').default,
            from:5,
            to: 7,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 27,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxB_TrainIldeBreathing_Loop_01.mp3').default,
            from:21,
            to: 24,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 28,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxB_TrainIldeBreathing_Loop_02.mp3').default,
            from:12,
            to: 13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 29,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxB_TrainRunningAccelerating_01.mp3').default,
            from:14,
            to: 16,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 30,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxB_TrainRunningSlow_Loop_01.mp3').default,
            from:13,
            to: 14,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 31,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxB_TrainSteamRelease_01.mp3').default,
            from:11,
            to: 12,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 32,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxB_TrainSteamRelease_01.mp3').default,
            from:21,
            to: 22,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 33,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_FxB_TrainSteamRelease_02.mp3').default,
            from:12,
            to: 14,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 34,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_Mx_01_Run.mp3').default,
            from:7,
            to: 13,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 36,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_VO_Stoi_03.mp3').default,
            from:14,
            to: 15,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },
        {
            id: 37,
            file:require('../assets/sounds/comics/deportation/train/TBB_Syberia_01_Pociag_Mx_02_Akcent.mp3').default,
            from:23,
            to: 24,
            loop: false,
            delay: 0,
            volume: 1,
            onEndSound: false
        },






    ],
}
